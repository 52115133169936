import React, {Component} from 'react';
import {connect} from "react-redux";
import './index.less';
import {Form} from "antd";
import {withRouter} from "react-router-dom";
import ContactUsH5 from "../../components/contact-us-h5";

class FooterH5 extends Component {
  state = {
    isContactOpen: false,
    pathName: ''
  };

  componentDidMount() {
    this.setState({
      pathName: window.location.pathname
    });
  }

  onHomeClick = () => {
    const { history } = this.props;
    history.push('/', null);
  }

  onContactClick = () => {
    this.setState({
      isContactOpen: true
    })
  }

  onContactClose = () => {
    this.setState({
      isContactOpen: false
    })
  }

  onServiceClick = () => {
    window.open('/service')
  }

  onPrivacyClick = () => {
    window.open('/privacy')
  }

  render() {
    const {isContactOpen, pathName} = this.state;

    return <div className="footer-h5-component">
      <div className={`footer-top ${['/', '/home', '/deposit'].includes(pathName) ? 'show' : 'hide'}`}>
        <img className="bg" src={require('../../assets/images/footer-h5/bottom-bg.jpg')} />
        <div className="info-box">
          <div className="title">你，准备好了吗？</div>
          <div className="desc">MixModel 将 AIGC 技术带入时尚行业，打造服装拍摄全新生产力。还在犹豫？随时与我们联系，一起探讨 AI 革命将为您带来的全新机会</div>
          <div className="contact-btn" onClick={this.onContactClick}>联系我们</div>
        </div>
      </div>

      <div className="footer-bottom">
        <span className="item-box">
          <img className="logo" src={require('../../assets/images/common-h5/logo-footer-x4.png')} onClick={this.onHomeClick}/>
          <span className="select-item-box">
            {/*<span className="select-item">关于我们</span>*/}
            {/*<span className="select-item">常见问题</span>*/}
            <span className="select-item" onClick={this.onServiceClick}>服务条款</span>
            <span className="select-item dot"></span>
            <span className="select-item" onClick={this.onPrivacyClick}>隐私政策</span>
          </span>
        </span>
        <div className="divider"></div>
        <div className="company">广州茉米智能科技有限公司版权所有<br />© 2023 | 粤ICP备2023070393号-1</div>
      </div>

      {/*联系我们*/}
      <ContactUsH5 isOpen={isContactOpen} onClose={this.onContactClose}/>
    </div>
  }
};

// 将action注入到页面组件
const matDispatchToProps = {

};

// 将store注入到页面组件
function mapStateToProps(state) {
  return {
  }
}
const RollFormWrap = Form.create({name: 'roll_form'})(FooterH5);
export default withRouter(connect(mapStateToProps, matDispatchToProps)(RollFormWrap));
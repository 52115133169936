export default {
  isMobile: () => {
    const ua = navigator.userAgent

    const isIphone = ua.match(/(iPhone\sOS)\s([\d_]+)/)
    const isAndroid = ua.match(/(Android)\s+([\d.]+)/)

    return isIphone || isAndroid
  },

  isIphone: () => {
    const ua = navigator.userAgent
    return ua.match(/(iPhone\sOS)\s([\d_]+)/)
  },
  isAndroid: () => {
    const ua = navigator.userAgent
    return ua.match(/(Android)\s+([\d.]+)/)
  }
}

// reducers.js
const initialState = {
  // 用户信息
  memberInfo: null,
  // 配置参数
  config: null,
  // toast信息
  toastInfo: null,
};

// 账号信息
export const member = (state = initialState, action) => {
  switch (action.type) {
    case 'MEMBER_INFO':
      return {
        // ...state,
        memberInfo: action.payload,
      };
    default:
      return state;
  }
};

// 公共配置
export const commonConfig = (state = initialState, action) => {
  switch (action.type) {
    case 'COMMON_CONFIG':
      return {
        ...state,
        config: action.payload,
      };
    default:
      return state;
  }
};

// toast
export const toast = (state = initialState, action) => {
  // console.log('reducer>>>>>>>>', state, action);
  switch (action.type) {
    case 'SHOW_TOAST':
      // console.log('reducer>>>>22222222>>>>');
      return {
        toastInfo: action.payload,
      };
    default:
      return state;
  }
};

// 是否正在生成图片
export const imgStatus = (state = initialState, action) => {
  switch (action.type) {
    case 'IS_IMG_MAKING':
      return {
        isMaking: action.payload,
      };
    default:
      return state;
  }
};
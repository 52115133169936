import React, {useState, useEffect} from "react";
import {useSelector,useDispatch} from 'react-redux';
import { useHistory } from 'react-router-dom';
import {withRouter} from "react-router-dom";
import './index.less';
import API from "../../../apis";
import QRCode from 'qrcode.react';
import {getMemberInfo, showToast} from "../../../store/actions/ai";
import {message} from "antd";
import ModalCloseBtn from "../../../components/modal-close-btn";

/**
 * 支付渠道
 */
const Payment = ({isOpen, onClose, payInfo}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // 订单信息
  const [orderInfo, setOrderInfo] = useState(null);
  // 支付二维码
  const [payCode, setPayCode] = useState(null);
  // 支付信息
  const [orderItem, setOrderItem] = useState(null);
  // 支付状态（0待支付，1已完成，-1失败）
  const [orderStatus, setOrderStatus] = useState(0);
  // 获得金额
  const [orderCoin, setOrderCoin] = useState(0);
  // 支付定时器
  const [orderTimer, setOrderTimer] = useState(null);
  // 支付定时器技术
  const [timerCount, setTimerCount] = useState(null);

  useEffect(() => {
    if (payInfo) {
      createOrder();
    }
  },[payInfo])

  // useEffect(() => {
  //   getOrderStatus();
  // },[timerCount])

  // 创建订单
  const createOrder = async () => {
    const params = {
      package_id: payInfo.package_id
    };
    const res = await API.createOrder(params);
    if (res.code === 0) {
      setOrderInfo(res.data)
    }
  }

  // 获取支付二维码
  const getPayCode = async (type) => {
    const params = {
      order_no: orderInfo.order_no,
      type
    };
    const res = await API.getPayCreate(params);
    if (res.code === 0) {
      setPayCode(res.data.qr_code)
    }
  }

  // 获取订单状态
  const getOrderStatus = async () => {
    const params = {
      order_no: orderInfo.order_no
    };
    const res = await API.getOrderStatus(params);
    if (res.code === 0) {
      setOrderStatus(res.data.status);
      setOrderCoin(res.data.coin);
      // console.log('orderTimer.....1111...', orderTimer);
      if (res.data.status === 1) {
        dispatch(getMemberInfo());
        if (orderTimer) {
          clearInterval(orderTimer);
          setOrderTimer(null);
        }
      } else {

      }
    }
  }

  // 选择支付渠道
  const onPayChannelClick = (item) => {
    setOrderItem(item);
    if (item.sub_type === 0) {
      // 二维码扫码
      if (item.type === 1) {
        // 微信支付
        getPayCode(1);
      } else if (item.type === 2) {
        // 支付宝支付
        getPayCode(2);
      }
    } else if (item.sub_type === 1) {
      // 打开新支付页面
      window.open(item.url);
    }

    // 2秒获取一次订单状态
    const interval = setInterval(() => {
      getOrderStatus();
      // console.log('orderTimer........', timerCount);
      // setTimerCount(timerCount + 1)
    }, 2000)
    setOrderTimer(interval);
  }

  const onCloseClick = (isCancelPay) => {
    setOrderInfo(null);
    setPayCode(null);
    setOrderItem(null);
    setOrderStatus(0);
    if (orderTimer) {
      clearInterval(orderTimer);
      setOrderTimer(null);
    }

    if (isCancelPay) {
      dispatch(showToast({type: 'warn', msg: '订单已取消'}));
    }

    onClose()
  };

  const onGoCenterBtnClick = () => {
    history.push({pathname: '/center', state: 1});
    onClose()
  }

  return (<div className={`payment-component ${isOpen ? "show" : "hide"}`}>
    {/*选择支付渠道*/}
    {
      (!orderItem || (orderItem.sub_type===1 && orderStatus===0 && !orderTimer)) && <div className={`payment-container`}>
        <ModalCloseBtn className="close-btn" onClose={onCloseClick} />
        <div className="payment-body">
          <div className="title">选择您的付款方式</div>
          {
            orderInfo && <div className="payment-item-box">
              {
                (orderInfo.pay_type || []).map(item => <div key={item.type} className="payment-item" onClick={() => onPayChannelClick(item)}>
                  {
                    // 微信
                    item.type === 1 && <img className="payment-icon" src={require('../../../assets/images/common/icon-weixin.svg')} />
                  }
                  {
                    // 支付宝
                    item.type === 2 && <img className="payment-icon" src={require('../../../assets/images/common/icon-ali.svg')} />
                  }
                  {item.name}
                </div>)
              }
            </div>
          }
        </div>
      </div>
    }

    {/*支付二维码*/}
    {
      orderItem && orderItem.sub_type===0 && orderStatus===0 && <div className="pay-code-container">
        <ModalCloseBtn className="close-btn" onClose={() => onCloseClick(true)} />
        <div className="money-box"><span className="money-value">{payInfo.coin}</span> M豆</div>
        <div className="code-img-box">
          {
            payCode && <QRCode
              id="qrCode"
              value={payCode}
              size={194} // 二维码的大小
              fgColor="#000000" // 二维码的颜色
              style={{ margin: 'auto' }}
              // imageSettings={{ // 二维码中间的logo图片
              //   src: "https://zengwu.com.cn/images/avatar.png",
              //   height: 100,
              //   width: 100,
              //   excavate: true, // 中间图片所在的位置是否镂空
              // }}
            />
          }
        </div>
        <div className="desc-box">
          支付金额：
          <span className="price-box"><span className="unit">￥</span>{payInfo.money}</span>
        </div>
        <div className="pay-type-box">
          {
            // 微信
            orderItem.type === 1 && <span><img className="payment-icon" src={require('../../../assets/images/common/icon-weixin.svg')} />微信扫码支付</span>
          }
          {
            // 支付宝
            orderItem.type === 2 && <span><img className="payment-icon" src={require('../../../assets/images/common/icon-ali.svg')} />支付宝扫码支付</span>
          }
        </div>
      </div>
    }

    {/*支付中*/}
    {
      orderItem && orderItem.sub_type===1 && orderTimer && orderStatus === 0 && <div className="pay-loading-container">
        <ModalCloseBtn className="close-btn" onClose={() => onCloseClick(true)} />
        <img className="loading-icon" src={require('../../../assets/images/deposit/pay-loading.png')}/>
        <div className="loading-txt">订单支付中...</div>
      </div>
    }

    {/*支付成功*/}
    {
      orderStatus === 1 && <div className="pay-status-container">
        <ModalCloseBtn className="close-btn" onClose={() => onCloseClick(false)} />
        <img className="success-icon" src={require('../../../assets/images/common/icon-success.svg')}/>
        <div className="status-txt">支付成功</div>
        <div className="money-box">成功购买 <span className="money-value">{orderCoin} M豆</span></div>
        <div className="finish-btn" onClick={() => onCloseClick(false)}>完成</div>
        <div className="go-center-btn" onClick={() => onGoCenterBtnClick()}>
          查看我的M豆<span className="arrow-right-icon" />
        </div>
      </div>
    }
    {/*支付失败*/}
    {
      orderStatus === -1 && <div className="pay-status-container">
        <ModalCloseBtn className="close-btn" onClose={() => onCloseClick(false)} />
        <img className="success-icon" src={require('../../../assets/images/common/icon-fail.svg')}/>
        <div className="status-txt">支付失败</div>
        <div className="money-box">订单已失效，请重新下单</div>
        <div className="finish-btn" onClick={() => onCloseClick(false)}>我知道了</div>
      </div>
    }
  </div>)
};

export default withRouter(Payment);

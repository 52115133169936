import React, {useState, useEffect, use} from "react";
import {Upload} from "antd";
import {withRouter} from "react-router-dom";
import configs from '../../configs.js';
import API from "../../apis";
import LoginRegister from "../../components/login-register";
import ConfirmModal from "../../components/confirm-modal";
import {useSelector,useDispatch} from 'react-redux';
import {getMemberInfo, showToast} from "../../store/actions/ai";
import BuildTypeModel from "./build-type-model";
import {getQuery, sleep} from "../../utils/helpers";
import { useHistory } from 'react-router-dom';

import './index.less';

const Product = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const MAX_SIZE = 10;
  const MIN_WIDTH = 256;
  const MIN_HEIGHT = 256;
  // 默认图
  const DEFAULT_PIC = require('../../assets/images/default-pic.png');

  // 步骤列表
  const stepList = [
      {
        step: 1,
        name: '选择图片'
      },
      {
        step: 2,
        name: '图片检测'
      },
      {
        step: 3,
        name: '图片设定'
      },
      {
        step: 4,
        name: '图片生成'
      },
    ];

  // 图片检测异常
  const errorMap = {
    // 其他默认
    '-1': {
      title: '光线不均匀',
      infos: ['光线不均匀：建议更换拍摄位置，面朝光源最佳，背景尽量简洁不杂乱']
    },
    '1003': {
      title: '未识别到头部',
      infos: ['未识别到头部：建议拍照时在取景框中保留较大的头部显示']
    },
    '1005': {
      title: '人台数量超过1个',
      infos: ['单张图片不超过 1 名假人模特']
    },
    '1006': {
      title: '未识别到服装',
      infos: [
        '请避免假人颜色与衣服颜色过于接近',
        '建议更换拍摄位置，面朝光源最佳'
      ]
    }
  };

  // 背景颜色
  const BACKGROUND_OPTION_MAP = {
    // 纯色 4
    // 401: { name: '浅灰', id: 401, color: '#DBDBDB' },
    401: { name: '浅灰', id: 401, color: 'linear-gradient(135deg, rgba(236, 236, 236, 1) 0%, rgba(236, 236, 236, 1) 50%, rgba(229, 230, 227, 1) 100%)' },
    402: { name: '深灰', id: 402, color: '#8E8E8E' },
    400: { name: '纯白', id: 400, color: '#FFFFFF' },
    403: { name: '浅蓝', id: 403, color: '#D3EFFF' },
    405: { name: '黑色', id: 405, color: '#2D2D2D' },
    406: { name: '粉色', id: 406, color: '#F5E5E9' },
    407: { name: '卡其', id: 407, color: '#C3B091' },
    408: { name: '浅绿', id: 408, color: '#D1EDD7' },

    // 室内 2
    301: { name: '咖啡厅', id: 301, img: require('../../assets/images/bg-301.png') },
    303: { name: '办公室', id: 303, img: require('../../assets/images/bg-303.png') },
    305: { name: '客厅', id: 305, img: require('../../assets/images/bg-305.png') },
    306: { name: '卧室', id: 306, img: require('../../assets/images/bg-306.png') },
    300: { name: '健身房', id: 300, img: require('../../assets/images/bg-300.png') },
    302: { name: '图书馆', id: 302, img: require('../../assets/images/bg-302.png') },
    304: { name: '教室', id: 304, img: require('../../assets/images/bg-304.png') },

    // 室外 3
    200: { name: '海边', id: 200, img: require('../../assets/images/bg-200.png') },
    203: { name: '街头', id: 203, img: require('../../assets/images/bg-203.png') },
    201: { name: '森林', id: 201, img: require('../../assets/images/bg-201.png') },
    202: { name: '公园', id: 202, img: require('../../assets/images/bg-202.png') },
    204: { name: '城市', id: 204, img: require('../../assets/images/bg-204.png') },
    205: { name: '游乐园', id: 205, img: require('../../assets/images/bg-205.png') },

    // 渐变色 1
    101: { name: '浅灰', id: 101, color: 'linear-gradient(135deg, rgba(170, 170, 170, 1) 0%, rgba(170, 170, 170, 1) 50%, rgba(112, 112, 112, 1) 100%)' },
    102: { name: '深灰', id: 102, color: 'linear-gradient(135deg, rgba(142, 142, 142, 1) 0%, rgba(142, 142, 142, 1) 50%, rgba(101, 101, 101, 1) 100%)' },
    100: { name: '亮白', id: 100, color: 'linear-gradient(135deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(220, 220, 220, 1) 100%)' },
    103: { name: '浅蓝', id: 103, color: 'linear-gradient(135deg, rgba(188, 239, 246, 1) 0%, rgba(188, 239, 246, 1) 50%, rgba(130, 211, 222, 1) 100%)' },
    105: { name: '黑色', id: 105, color: 'linear-gradient(135deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(111, 111, 111, 1) 100%)' },
    106: { name: '粉色', id: 106, color: 'linear-gradient(135deg, rgba(255, 217, 239, 1) 0%, rgba(255, 217, 239, 1) 50%, rgba(241, 171, 211, 1) 100%)' },
    107: { name: '卡其', id: 107, color: 'linear-gradient(135deg, rgba(239, 203, 177, 1) 0%, rgba(239, 203, 177, 1) 50%, rgba(217, 175, 144, 1) 100%)' },
    108: { name: '浅绿', id: 108, color: 'linear-gradient(135deg, rgba(187, 236, 197, 1) 0%, rgba(187, 236, 197, 1) 50%, rgba(123, 198, 138, 1) 100%)' },
};

  const PROGRESS_ROTATE_TIME = 0.01;

  // 0初始值，1真人图，2人台图
  const [imageType, setImageType] = useState(0);
  // 当前第几步
  const [step, setStep] = useState(1);
  // 第一步预选图片列表
  const [preImageList, setPreImageList] = useState([]);
  // 第二步图片状态 0：未开始；1：检测中；2：未通过；3：通过
  const [checkStatus, setCheckStatus] = useState(0);
  // 检测失败code
  const [checkErrorCode, setCheckErrorCode] = useState(0);
  // 任务详情
  const [taskInfo, setTaskInfo] = useState(null);
  // 图片是否生成完毕
  const [finish, setFinish] = useState(false);
  // 性别
  const [gender, setGender] = useState(2);
  //
  const [genderSelectId, setGenderSelectId] = useState(200);
  // 模特鼠标移入数据
  const [genderMouseOverItem, setGenderMouseOverItem] = useState(null);
  // 选项配置
  const [buildOptions, setBuildOptions] = useState(null)
  // tab类型
  const [tab, setTab] = useState(4);
  // 背景id
  const [bgSelectId, setBgSelectId] = useState(401);
  // 当前上传图片信息 image_id，image
  const [uploadImgInfo, setUploadImgInfo] = useState(null);
  // 当前上传图片task_id
  const [taskId, setTaskId] = useState(null);
  // 上传的照片路径
  const [imgUrl, setImgUrl] = useState(null);
  // 图片类型 1：宽<高；2：宽>高；3：宽=高
  const [imgSizeType, setImgSizeType] = useState(1);
  // ai生成过程计时器
  // const [processTimer, setProcessTimer] = useState(null);
  // ai生成过程图片base64
  const [processImgBase64, setProcessImgBase64] = useState(null);
  // ai生成进度
  const [progressValue, setProgressValue] = useState(0);
  // ai生成状态 1排队中或预约中，2处理中，3已完成，-1失败
  const [progressStatus, setProgressStatus] = useState();
  // 进度圆左边旋转角度
  const [progressOldRotateLeft, setProgressOldRotateLeft] = useState(0);
  // 进度圆右边旋转角度
  const [progressOldRotateRight, setProgressOldRotateRight] = useState(0);
  // 进度修改次数
  const [progressUpdateTime, setProgressUpdateTime] = useState(0);
  // ai生成完毕图片列表
  const [finishImgList, setFinishImgList] = useState(null);
  // 选中要下载的图片url
  const [downloadImgInfo, setDownloadImgInfo] = useState(null);
  // 是否重新生成
  const [isRebuild, setIsRebuild] = useState(false);
  // 第四步选择新图片upload是否弹出对话框
  const [openFileDialogOnClick, setOpenFileDialogOnClick] = useState(false);
  // 生成图片条件
  const [config, setConfig] = useState(null);
  // 生成图片条件计时器
  const [configTimer, setConfigTimer] = useState(null);
  //
  const [configTimerCount, setConfigTimerCount] = useState(0);
  // 生成图片消耗M豆提示
  const [showConfirm, setShowConfirm] = useState(true);
  // confirm 信息
  const [confirmInfo, setConfirmInfo] = useState({show: false});
  // 显示登录窗
  const [isLoginRegisterOpen, setIsLoginRegisterOpen] = useState(false);
  // 计时器
  const [processTimer, setProcessTimer] = useState(null);
  // 计时器数量（用于处理setInterval获取state数据不更新问题）
  const [processTimerCount, setProcessTimerCount] = useState(0);
  // 显示生成方式
  const [showBuildTypeModal, setShowBuildTypeModal] = useState(false);
  // 显示tips
  const [showBuildTips, setShowBuildTips] = useState(false);
  // 用户信息
  const [memberInfo, setMemberInfo] = useState(null);
  // 用户信息
  const { member } = useSelector(state => {
    return {
      memberInfo: state.getIn(['member', 'memberInfo']),
    }
  });

  // 模特tips出现计时器
  let genderTipsTimer = null;

  useEffect(() => {
    const query = getQuery();
    setImageType(query.type ? parseInt(query.type) : 1);
    if (query.task_id) {
      setStep(4);
      setTaskId(query.task_id);
      startAIBuild(query.task_id);
    }

    const showConfirm = window.localStorage.getItem('show_build_confirm');
    setShowConfirm((showConfirm===null || showConfirm==='true') ? true : false)

    getBuildConfig();
    createConfigTimer();
    requestMemberInfo();

    // 禁止右键
    // document.oncontextmenu = (e) => {
    //   e = e || window.event;
    //   return false;
    // }

    // test();
  }, [])

  useEffect(()=>{
    return () => {
      removeEvent();
    }
  },[])

  useEffect(() => {
    if (imageType !== 0 && step === 1) {
      getPreImages();
    }
    if (step === 4 && [3, -1].includes(progressStatus)) {
      stopProcessTimer();
    }
    if (step === 4) {
      // 链接添加参数
      window.history.replaceState(null, null, `/aimodel?type=${imageType}&task_id=${taskId}`)
    } else {
      removeUrlParams();
    }
  }, [step, progressStatus, imageType])

  useEffect(() => {
    // setMemberInfo(member);
    requestMemberInfo();
  }, [member])

  useEffect(() => {
    if (configTimerCount !== 0) {
      getBuildConfig();
    }
  }, [configTimerCount]);

  useEffect(() => {
    if (processTimerCount !== 0) {
      getTaskProgress();
    }
  }, [processTimerCount])

  const test = () => {
    setStep(3);
    setFinishImgList([
      {
        image_id: 1,
        image: require('../../assets/images/108.png'),
      },
      {
        image_id: 1,
        image: require('../../assets/images/108.png'),
      },
      {
        image_id: 1,
        image: require('../../assets/images/108.png'),
      },
      {
        image_id: 1,
        image: require('../../assets/images/108.png'),
      },
    ]);
    setImgUrl(require('../../assets/images/108.png'));
    setImgSizeType(1);
    setFinish(false);
    setProgressStatus(1);
    setProgressValue(50);

    // setTimeout(() => {
    //   handleProgressAnim(30);
    //
    //   setTimeout(() => {
    //     handleProgressAnim(70);
    //   }, 3000)
    // }, 1000)
  }

  const removeEvent = () => {
    if (configTimer) {
      clearTimeout(configTimer);
      setConfigTimer(null);
    }
  }

  const removeUrlParams = () => {
    // 链接去掉参数
    window.history.replaceState(null, null, `/aimodel?type=${imageType}`)
  }

  // 5秒同步一次数据
  const createConfigTimer = () => {
    const timer = setInterval(() => {
      // 更新processTimerCount是为了处理interval中state数据不变问题
      setConfigTimerCount((configTimerCount) => {
        return configTimerCount + 1;
      })
    }, 5000)
    // processTimer = timer;
    setConfigTimer(timer);
  }

  const beforeUpload = (file) => {
    // upload选择对话框是否弹出设为false
    setOpenFileDialogOnClick(false);
    // 判断文件大小是否大于10M
    const isMore = file.size / 1024 / 1024 > MAX_SIZE;
    if (isMore) {
      setConfirmInfo({
        show: true,
        type: 2,
        title: '出错啦',
        msg: `当前图片大小超过${MAX_SIZE}M，请处理后重新选择`,
        okBtnTxt: '知道了'
      });

      return false;
    }

    return getFileWidthAndHeight(file, (img) => {
      setImgUrl(img.src);
      setImgSizeType(getImageSizeType(img));
      return true;
    });
  }

  // 获取图片尺寸类型
  const getImageSizeType = (imgData) => {
    const imgType = Math.abs(imgData.width - imgData.height) < 2 ? 3 : (imgData.width < imgData.height ? 1 : 2);
    return imgType;
  }

  // 获取预选图片列表
  const getPreImages = async () => {
    const params = {
      image_type: imageType
    };
    const res = await API.getPresetImages(params);
    if (res.code === 0) {
      setPreImageList(res.data);
    }
  }

  // 生成图片条件
  const getBuildConfig = async () => {
    const params = {
      image_type: imageType
    };
    const res = await API.getBuildConfig(params);
    if (res.code === 0) {
      setConfig(res.data);
      setBuildOptions(res.data.build_options);
      // setBuildOptions(updateBuildOptions(res.data?.build_options));
    }
  }

  // 生成图片选项后端配置数据与前端配置数据合并
  const updateBuildOptions = (buildOptions) => {
    if (!buildOptions) {
      return;
    }
    const newBackgrounds = buildOptions.background.data.map(backgroundItem => {
      backgroundItem.data.map(item => {
        const newItem = {...BACKGROUND_OPTION_MAP[item.value], ...item};
        Object.assign(item, newItem);
        return item;
      })
      return backgroundItem;
    });

    buildOptions.background.data = newBackgrounds;

    return buildOptions;
  }

  // 获取指定类型背景数据
  const getBackgroundList = (tab) => {
    const data = (buildOptions?.background.data || []).find(item => {
      return (item.value === tab);
    })
    return data || null;
  }

  // 获取背景信息
  const getBackgroundData = (type, id) => {
    const data = getBackgroundList(type).data.find(item => {
      return item.id === id;
    })
    return data;
  }

  // 用户信息
  // const getMemberInfo = async () => {
  //   const token = window.localStorage.getItem('token');
  //   if (!token) {
  //     return;
  //   }
  //   const params = {};
  //   const res = await API.getMemberInfo(params);
  //   if (res.code === 0) {
  //     setMemberInfo(res.data);
  //   }
  // }

  // 预先图点击
  const onPreImageItemClick = (data) => {
    setUploadImgInfo({
      "image_id": data.image_id,
      "image" :  data.image
    });
    setStep(2);
    setImgUrl(data.image);
    setCheckStatus(1);
    setImgSizeType(getImageSizeType(data));
    setTimeout(() => {
      // 直接检测成功
      imageCheckSuccess();
    }, 1000);
  }

  const onPreImageItemOver = (item) => {
    // 浏览器高度
    const clientHeight = document.body.clientHeight;

    // 与选择模特共用动效
    const elm = document.getElementById(`pre_image_item_${item.image_id}`);
    const detailElm = document.getElementById(`pre_image_detail_box_${item.image_id}`);
    detailElm.style.setProperty('--detailBoxAnimName', `showDetailBoxAnim`);
    detailElm.style.setProperty('--detailBoxIn', `${elm.offsetLeft - detailElm.clientWidth - 8}px`);
    detailElm.style.setProperty('--detailBoxStop', `${elm.offsetLeft - detailElm.clientWidth - 10}px`);
    detailElm.style.setProperty('--detailBoxInBottom', `0px`);

    // 详情蒙版y坐标
    let detailElmY = detailElm.getBoundingClientRect().y;
    let bottomY = 0;
    // 判断与浏览器底部间距
    const bottom = clientHeight - (detailElmY + detailElm.clientHeight + 20);
    if ( bottom < 0) {
      // 与底部间距太小，需要上移
      bottomY = Math.abs(bottom);
    }

    if ((detailElmY - bottomY) < 84 ) {
      // 与顶部间距太小，需要下移
      bottomY = 0 - (84 - detailElmY);
    } else {
      bottomY = 0  + bottomY;
    }
    detailElm.style.setProperty('--detailBoxInBottom', `${bottomY}px`);
  }

  const onPreImageItemOut = (item) => {
    const elm = document.getElementById(`pre_image_item_${item.image_id}`);
    const detailElm = document.getElementById(`pre_image_detail_box_${item.image_id}`);
    detailElm.style.setProperty('--detailBoxAnimName', `hideDetailBoxAnim`);
    detailElm.style.setProperty('--detailBoxStop', `${elm.offsetLeft - detailElm.clientWidth - 10}px`);
    detailElm.style.setProperty('--detailBoxOut', `${elm.offsetLeft - detailElm.clientWidth - 20}px`);
  }

  // 下载图片前请求接口
  const download = async (callback) => {
    const params = {
      image_id: downloadImgInfo.image_id
    };
    const res = await API.download(params);
    if (res.code === 0) {
      if (callback) {
        callback(res.data);
      }
    } else if (res.code === 1202) {
      // 未充值
      setConfirmInfo({
        show: true,
        type: 1,
        title: '购买M豆即可下载',
        msg: res.msg,
        showCancelBtn: true,
        cancelBtnTxt: '取消',
        okBtnTxt: '立即购买',
        okFunc: () => {
          // this.props.history.push({ pathname: '/deposit', state: {} })
          window.open("/deposit");
        }
      })
    }
  }

  // 获取图片宽高
  const getFileWidthAndHeight = (file, callbackFunc) => {
    return new Promise((resolve, reject) => {
      let _URL = window.URL || window.webkitURL;
      let img = new Image();
      img.src = _URL.createObjectURL(file);
      img.onload = () => {
        if (img.width < MIN_WIDTH || img.height < MIN_HEIGHT) {
          reject();

          setConfirmInfo({
            show: true,
            type: 2,
            title: '出错啦',
            msg: `当前图片宽高像素小于${MIN_WIDTH}px，请处理后重新选择`,
            showCancelBtn: false,
            okBtnTxt: '知道了',
            okFunc: null
          });
        } else if ((img.width/img.height)>2 || (img.height/img.width)>2) {
          reject();

          setConfirmInfo({
            show: true,
            type: 2,
            title: '出错啦',
            msg: `当前图片比例大于 18:9 ，请处理后重新选择`,
            showCancelBtn: false,
            okBtnTxt: '知道了',
            okFunc: null
          });
        } else {
          callbackFunc(img);
          resolve();
        }
      };
    });
  };

  const handleChange = (data) => {
    if (data.file.status === undefined || data.file.status === 'error') {
      return;
    }

    resetData();

    if (data.file.status !== "done") {
      // 检测中
      setStep(2);
      setCheckStatus(1);
      return;
    }

    const res = data.file.response;
    if (res.code === 0) {
      setUploadImgInfo(res.data);
      imageCheckSuccess();
    } else {
      // 检测失败
      setCheckStatus(2);
      setCheckErrorCode(errorMap[res.code+''] ? res.code : -1);
    }
  }

  // 第二步检测成功
  const imageCheckSuccess = () => {
    // 检测成功
    setCheckStatus(3);
    // setTaskId(res.data.task_id);

    setIsRebuild(false);
    // 1秒后再切换到第三步
    setTimeout(() => {
      setStep(3);
    }, 1000)
  }

  // 第四步选择新图片
  const chooseNewImgClick = () => {
    if (openFileDialogOnClick) {
      return;
    }

    // 先弹出确认框
    // setConfirmInfo({
    //   show: true,
    //   type: 1,
    //   title: '选择新图片',
    //   msg: '已生成图片会丢失，是否选择新图片？',
    //   showCancelBtn: true,
    //   cancelBtnTxt: '取消',
    //   okBtnTxt: '确定',
    //   okFunc: () => {
    //     // 返回第三步
    //     setOpenFileDialogOnClick(true);
    //     // 需要延时处理，不然打不开对话框
    //     setTimeout(()=>{
    //       // 点击按钮，打开选择文件的对话框
    //       document.getElementById('new_img_btn').click();
    //     },50)
    //   }
    // });

    // 返回第三步
    setOpenFileDialogOnClick(true);
    // 需要延时处理，不然打不开对话框
    setTimeout(()=>{
      // 点击按钮，打开选择文件的对话框
      document.getElementById('new_img_btn').click();
    },50)
  }

  const getGenderTypeData = (type) => {
    const data = config.build_options.gender.data.find(item => item.value===type);
    return data;
  }

  const onGenderChange = (tab) => {
    document.getElementById('gender_item_box').scrollTo(0, 0);

    setGender(tab);
    setGenderSelectId(getGenderTypeData(tab).data[0].value);
  }

  const onGenderIdChange = (id) => {
    setGenderSelectId(id);
  }

  const onGenderMouseIn = (item) => {
    clearGenderTipsTimer();
    // 等待0.3秒后执行，防止误触或太灵敏
    genderTipsTimer = setTimeout(() => {
      clearGenderTipsTimer();
      onGenderMouseOver(item);
    }, 300)
  }

  const clearGenderTipsTimer = () => {
    if (genderTipsTimer) {
      clearTimeout(genderTipsTimer);
      genderTipsTimer = null;
    }
  }

  const onGenderMouseOver = (item) => {
    // 浏览器高度
    const clientHeight = document.body.clientHeight;

    const elm = document.getElementById(`gender_item_${item.value}`);
    const detailElm = document.getElementById(`gender_detail_box_${item.value}`);
    detailElm.style.setProperty('--detailBoxAnimName', `showDetailBoxAnim`);
    detailElm.style.setProperty('--detailBoxIn', `${elm.offsetLeft - 265 - 5}px`);
    detailElm.style.setProperty('--detailBoxStop', `${elm.offsetLeft - 265 - 5}px`);
    detailElm.style.setProperty('--detailBoxInBottom', `10px`);
    detailElm.style.setProperty('--detailBoxDelay', `0s`);

    // 详情蒙版y坐标
    let detailElmY = detailElm.getBoundingClientRect().y;
    let bottomY = 0;
    // 判断与浏览器底部间距
    const bottom = clientHeight - (detailElmY + detailElm.clientHeight + 20);
    if ( bottom < 0) {
      // 与底部间距太小，需要上移
      bottomY = Math.abs(bottom);
    }

    if ((detailElmY - bottomY) < 84 ) {
      // 与顶部间距太小，需要下移
      bottomY = 10 - (84 - detailElmY);
    } else {
      bottomY = 10  + bottomY;
    }
    detailElm.style.setProperty('--detailBoxInBottom', `${bottomY}px`);
  }

  const onGenderMouseOut = (item) => {
    // 如果计时器没清除，不往后处理，防止初始状态下鼠标移出会显示tips
    if (genderTipsTimer) {
      clearGenderTipsTimer();
      return;
    }

    const elm = document.getElementById(`gender_item_${item.value}`);
    const detailElm = document.getElementById(`gender_detail_box_${item.value}`);
    detailElm.style.setProperty('--detailBoxAnimName', `hideDetailBoxAnim`);
    detailElm.style.setProperty('--detailBoxStop', `${elm.offsetLeft - 265 - 5}px`);
    detailElm.style.setProperty('--detailBoxOut', `${elm.offsetLeft - 265 - 5}px`);
    detailElm.style.setProperty('--detailBoxDelay', `0s`);
  }

  const onTabChange = (tab) => {
    document.getElementById('background_item_box').scrollTo(0, 0);

    setTab(tab);
    setBgSelectId(getBackgroundList(tab)?.data[0].value);
  }

  const onBgIndexChange = (id) => {
    setBgSelectId(id);
  }

  const onAIBtnClick = (buildType) => {
    // 快速生成禁用状态点击不处理
    if (buildType === 1 && config?.quick_build.status === 0) {
      return;
    }

    requestMemberInfo((member) => {
      checkMoney(() => {
        if (isRebuild) {
          taskRebuild(buildType);
        } else {
          taskConfig(buildType);
        }
      }, buildType, isRebuild, member);
    }, true);
  }

  // 获取账号信息
  const requestMemberInfo = async (callFunc, isAIBtnClick) => {
    const params = {};
    const res = await API.getMemberInfo(params);
    if (res.code === 0) {
      setMemberInfo(res.data);
      if (callFunc) {
        callFunc(res.data);
      }
    } else if (res.code === 1101 && isAIBtnClick) {
      // 未登录
      setIsLoginRegisterOpen(true);
    }
  };

  const checkMoney = (callFunc, buildType, isRebuild, member) => {
    const mInfo = member || memberInfo;
    // 未登录
    if (!mInfo) {
      setIsLoginRegisterOpen(true);
      return;
    }

    // 余额不足
    const buildConfig = buildType === 1 ? config.quick_build : config.order_build;
    if (mInfo.coin < buildConfig.cost) {
      setConfirmInfo({
        show: true,
        type: 1,
        title: 'M豆余额不足',
        msg: `您当前的账户内可用的 M豆不足，请购买后再重新尝试`,
        showCancelBtn: true,
        cancelBtnTxt: '取消',
        okBtnTxt: '立即购买',
        okFunc: () => {
          window.open("/deposit");
        }
      })
      return;
    }

    // if (isRebuild) {
    //   // 重新生成
    //   setConfirmInfo({
    //     show: true,
    //     type: 1,
    //     title: '重新生成',
    //     msg: `是否消耗 ${buildConfig.cost} M豆重新生成？`,
    //     showCheckBox: false,
    //     showCancelBtn: true,
    //     cancelBtnTxt: '再想想',
    //     okBtnTxt: '确定',
    //     okFunc: (check) => {
    //       if (callFunc) {
    //         callFunc();
    //       }
    //       window.localStorage.setItem('show_build_confirm', !check);
    //       setShowConfirm(!check)
    //     }
    //   });
    // } else
    if (showConfirm) {
      // 确认提示
      setConfirmInfo({
        show: true,
        type: 1,
        title: '提示',
        msg: `是否消耗 ${buildConfig.cost} M豆生成 AI 模特图？`,
        showCheckBox: true,
        checkBoxTxt: '不再提示',
        showCancelBtn: true,
        cancelBtnTxt: '再想想',
        okBtnTxt: '确定',
        okFunc: (check) => {
          if (callFunc) {
            callFunc();
          }
          window.localStorage.setItem('show_build_confirm', !check);
          setShowConfirm(!check)
        }
      });
    } else {
      if (callFunc) {
        callFunc();
      }
    }
  }

  const taskConfig = async (buildType=1) => {
    const params = {
      image_id: uploadImgInfo.image_id,
      gender: gender,
      model: genderSelectId,
      bg_style: tab,
      bg_option: bgSelectId,
      build_type: buildType,
      image_type: imageType
    };
    const res = await API.taskBuild(params);
    if (res.code === 0) {
      setStep(4);
      setTaskId(res.data.task_id);
      setFinish(false);
      resetData();
      startAIBuild(res.data.task_id);
      dispatch(getMemberInfo())
    } else if (res.code === 1401) {
      dispatch(showToast({type: 'warn', msg: '暂不可用，AI服务器已全部满载'}));
      getBuildConfig();
    }
  }

  // AI开始生成图片
  const startAIBuild = (task_id) => {
    getTaskProgress(task_id);
    stopProcessTimer();
    const timer = setInterval(() => {
      // 更新processTimerCount是为了处理interval中state数据不变问题
      setProcessTimerCount((processTimerCount) => {
        return processTimerCount - 1;
      })
    }, 2000)
    // processTimer = timer;
    setProcessTimer(timer);
  }

  // 停止计时器
  const stopProcessTimer = () => {
    if (processTimer) {
      clearInterval(processTimer);
      // processTimer = null;
      setProcessTimer(null);
    }
  }

  // 任务详情
  const getTaskProgress = async (task_id) => {
    const params = {
      task_id: task_id || taskId
    };
    const res = await API.getTaskInfo(params);
    if (res.code === 0) {
      // 生成过程中如果进度小于上一个进度，则不处理，防止出现前面接口比较后面接口返回慢问题
      if (res.data.status === 2 && parseInt(res.data.progress * 100) <= progressValue) {
        return;
      }
      // 是否查看详情
      const isDetail = !taskInfo;

      setProgressStatus(res.data.status);
      setTaskInfo(res.data);
      setGender(res.data.gender);
      setTab(res.data.background_style);
      setBgSelectId(res.data.background_option);
      setGenderSelectId(res.data.model);
      setImageType(res.data.image_type || 1);
      setUploadImgInfo({
        "image_id": res.data.image_id,
        "image" :  res.data.image
      })

      if (!imgUrl) {
        setImgUrl(res.data.image);
      }
      if (res.data.status === 2) {
        // 处理中
        const progress = progressValue>0 && res.data.progress===0 ? 100 : parseInt(res.data.progress * 100);
        setStep(4);
        setFinish(false);
        setProcessImgBase64(res.data.preview_base64 ? res.data.preview_base64 : processImgBase64);
        setProgressValue(progress);
        // setProgressStatus(res.data.status)
        handleProgressAnim(progress);
      } else if (res.data.status === 3) {
        // 处理完成
        setStep(4);
        setFinishImgList(res.data.outputs);
        setDownloadImgInfo(res.data.outputs[0]);
        // setProgressStatus(res.data.status);
        setProcessTimerCount(0);
        setFinish(true);
        dispatch(getMemberInfo())
      } else if (res.data.status === -1) {
        // 失败
        stopProcessTimer();
        setProcessTimerCount(0);
        dispatch(getMemberInfo());

        if (!isDetail) {
          setConfirmInfo({
            show: true,
            type: 2,
            title: '生成失败',
            msg: '生成图片失败，请稍后重试（消耗的M豆已退回至您的账户）',
            showCancelBtn: false,
            okBtnTxt: '稍后再试',
            okFunc: () => {
              // 返回第三步
              setStep(3);
              setIsRebuild(true);
            }
          });
        }
      }
    }
  }

  // 进度动画
  const handleProgressAnim = (progressValue) => {
    const leftProgress = document.getElementById('left_progress_circle');
    const rightProgress = document.getElementById('right_progress_circle');

    const leftRotate = circleBarRotate(progressValue, true);
    const rightRotate = circleBarRotate(progressValue, false);

    const leftTime = (leftRotate - progressOldRotateLeft) * PROGRESS_ROTATE_TIME;
    const rightTime = (rightRotate - progressOldRotateRight) * PROGRESS_ROTATE_TIME;

    // 进度没变化则不往下执行
    if (leftTime === 0 && rightTime === 0) {
      return;
    }

    // 给css变量赋值
    rightProgress.style.setProperty('--rightProgressAnimName', `rightProgressAnim${progressUpdateTime%2}`);
    rightProgress.style.setProperty('--rightProgressTime', `${rightTime}s`);
    rightProgress.style.setProperty('--rightProgressOldRotate', `${progressOldRotateRight}deg`);
    rightProgress.style.setProperty('--rightProgressRotate', `${rightRotate}deg`);
    if (progressOldRotateRight < 180 && rightRotate === 180) {
      rightProgress.style.setProperty('--rightProgressOpacity', '0');
    }
    if (progressOldRotateRight > 20) {
      leftProgress.setAttribute('style','background-color: unset');
    }

    if (leftTime > 0) {
      leftProgress.style.setProperty('--leftProgressAnimName', `leftProgressAnim${progressUpdateTime%2}`);
      leftProgress.style.setProperty('--leftProgressTime', `${leftTime}s`);
      leftProgress.style.setProperty('--leftProgressDelay', `${rightTime}s`);
      leftProgress.style.setProperty('--leftProgressOldRotate', `${progressOldRotateLeft}deg`);
      leftProgress.style.setProperty('--leftProgressRotate', `${leftRotate}deg`);
    }

    setProgressOldRotateLeft(leftRotate);
    setProgressOldRotateRight(rightRotate);
    setProgressUpdateTime(progressUpdateTime + 1)
  }

  // 重新生成按钮点击
  const onRebuildClick = async (buildType) => {
    onBuildTypeModelOpen();
    // requestMemberInfo((member) => {
    //   checkMoney(() => {taskRebuild(buildType)}, buildType, true, member);
    // }, true);
  }

  // 重新生成
  const taskRebuild = async (buildType) => {
    const params = {
      task_id: taskId,
      gender: gender,
      bg_style: tab,
      bg_option: bgSelectId,
      model: genderSelectId,
      build_type: buildType,
      image_type: imageType
    };
    const res = await API.taskRebuild(params);
    if (res.code === 0) {
      setStep(4);
      setTaskId(res.data.task_id);
      resetData();
      startAIBuild(res.data.task_id);
    } else if (res.code === 1401) {
      dispatch(showToast({type: 'warn', msg: '暂不可用，AI服务器已全部满载'}));
      getBuildConfig();
    }
  }

  // 重置数据
  const resetData = () => {
    setFinish(false);
    setProgressValue(0);
    setProgressStatus(0);
    setProcessTimerCount(0);
    setProgressOldRotateLeft(0);
    setProgressOldRotateRight(0);
    setProcessImgBase64(null);
    setFinishImgList(null);
    setDownloadImgInfo(null);
    setTaskInfo(null);
    stopProcessTimer();
  }

  // 生成结束后图片选择
  const onFinishImgClick = (imgInfo) => {
    setDownloadImgInfo(imgInfo)
  }

  // 下载按钮点击
  const onDownloadClick = (imgInfo) => {
    download((resData) => downFiles(resData));
  }

  // 下载图片
  const downFiles = (imgInfo) => {
    const x = new XMLHttpRequest();
    const resourceUrl = imgInfo.image;
    x.open('GET', resourceUrl, true);
    x.responseType = 'blob';
    x.onload = () => {
      const urls = window.URL.createObjectURL(x.response);
      const a = document.createElement('a');
      a.href = urls;
      a.download = 'mix_model_img.jpg';
      a.click();
    };
    x.send();
  }

  // 返回上一步
  const upPage = () => {
    // setConfirmInfo({
    //   show: true,
    //   type: 1,
    //   title: '返回上一步',
    //   msg: '已生成图片会丢失，是否返回上一步？',
    //   showCancelBtn: true,
    //   cancelBtnTxt: '取消',
    //   okBtnTxt: '确定',
    //   okFunc: () => {
    //     setStep(3);
    //     setIsRebuild(true);
    //   }
    // });

    setStep(3);
    setIsRebuild(false);
  }

  const onBuildNextImg = () => {
    resetData();
    setStep(1);
  }

  // 弹窗确认按钮点击
  const onConfirmOkClick = () => {
    if (confirmInfo.okFunc) {
      confirmInfo.okFunc();
    }

    setConfirmInfo({
      ...confirmInfo,
      ...{
        show: false,
      }
    })
  }

  // 弹窗取消按钮点击
  const onConfirmCancelClick = () => {
    setConfirmInfo({
      ...confirmInfo,
      ...{
        show: false,
      }
    })
  }

  // 获取进度条旋转角度
  const circleBarRotate = (per, isLeft) => {
    if (per > 100) {
      return 180;
    }
    if (isLeft && per > 50) {
      return 0 + (per - 50) * (180 / 50)
    }
    if (!isLeft && per > 0) {
      if (per >= 50) {
        return 180
      } else if (per < 50) {
        return 0 + per * (180 / 50)
      }
    }
    // 默认-180deg
    if (isLeft) {
      return 0;
    } else {
      return 0;
    }
  }

  const onLoginRegisterClose = () => {
    setIsLoginRegisterOpen(false);
  }

  // 关闭选择生成方式弹窗
  const onBuildTypeModelOpen = () => {
    setShowBuildTypeModal(true);
  }

  // 关闭选择生成方式弹窗
  const onBuildTypeModelClose = (buildType) => {
    setShowBuildTypeModal(false);
    if (buildType) {
      requestMemberInfo((member) => {
        checkMoney(() => {taskRebuild(buildType)}, buildType, true, member);
      }, true);
    }
  }

  // 查看图片生成记录
  const goImgRecord = () => {
    window.location.href = `/center?tab=2`;
  }

  // 模特库
  const goModelStash = (modelId) => {
    if (modelId === 200 || modelId === 300) {
      return;
    }
    window.open(`/modeldetail?id=${modelId}`);
  }

  return (<div className={`product-component ${(imgSizeType === 1 && step === 4) && "height-h"}`}>
      <div className="main-container">
        <div className="ai-title">
          {/*<img className="ai-icon" src={require('../../assets/images/icon-ai.svg')} />*/}
          <span className="title">
            { imageType === 1 ? '真人图美化' : '人台变真人' }
          </span>
        </div>

        <div className="step-box">
          {
            (stepList || []).map((item, index) => <>
              {
                index > 0 && <div key={item.step} className={`step-divider ${step>=item.step && "finish"}`}></div>
              }
              <span className={`step-item ${step===item.step && "current"} ${step>item.step && "finish"}`}>
                <span className="step-num">{item.step}</span>
                <span className="step-name">{item.name}</span>
              </span>
            </>)
          }
        </div>

        <div className={`info-box info-box-${step}`}>
          <img className="step-info-bg-left" src={require('../../assets/images/step-info-bg-left.png')} />
          <img className="step-info-bg-right" src={require('../../assets/images/step-info-bg-right.png')} />

          {
            // 第一步 选择图片
            <div className={`step-info-1 ${step === 1 ? 'show' : 'hide'}`}>
              <div className="top-container">
                <span className="left-box">
                  <div className="condition-title">
                    { imageType === 1 ? '真人图要求' : '人台图要求' }
                  </div>
                  {
                    imageType === 1 ? <div className="condition-box">
                      <img className="condition-line" src={require('../../assets/images/condition-line.svg')} />
                      <div className="condition-info">
                        <div>1. 图片大小不超过 {MAX_SIZE} M</div>
                        <div>2. 图片比例不大于 18:9 </div>
                        <div>3. 图片宽高像素不低于 {MIN_WIDTH} px</div>
                        <div>4. 光线均匀，避免图片过曝或过暗</div>
                        <div>5. 图片必须包含头部，半身照、近照 图片效果更佳</div>
                        <div>6. 减少对服装的遮挡，背景尽量简洁不杂乱</div>
                      </div>
                    </div> : <div className="condition-box">
                      <img className="condition-line" src={require('../../assets/images/condition-line2.svg')} />
                      <div className="condition-info">
                        <div>1. 图片大小不超过 {MAX_SIZE} M</div>
                        <div>2. 图片比例不大于 18:9 </div>
                        <div>3. 图片宽高像素不低于 {MIN_WIDTH} px</div>
                        <div>4. 光线均匀，避免图片过曝或过暗</div>
                        <div>5. 假人模特需包含头部、手指关节，有五官更佳</div>
                        <div>6. 减少对服装的遮挡，背景尽量简洁不杂乱</div>
                        <div>7. 单张图片不超过一个假人模特</div>
                      </div>
                    </div>
                  }

                </span>
                <span className="right-box">
                  {/*<img className="step-img-1" src={require('../../assets/images/step-1.png')} />*/}
                  <span className="right-body">
                    <div className="right-container">
                      <span className="txt">CASE</span>
                      <span className={`step-img step-img-${imageType}-1`}>
                        <img className="status-icon" src={require('../../assets/images/icon-success.png')} />
                      </span>
                      <div className="divider"></div>
                      <span className={`step-img step-img-${imageType}-2`}>
                        <img className="status-icon" src={require('../../assets/images/icon-error.png')} />
                      </span>
                      <span className={`step-img step-img-${imageType}-3`}>
                        <img className="status-icon" src={require('../../assets/images/icon-error.png')} />
                      </span>
                    </div>
                  </span>
                </span>
              </div>

              <Upload
                name="image"
                listType="text"
                className="img-uploader"
                showUploadList={false}
                accept="image/png, image/jpeg, image/jpg"
                action={configs.baseUrl + "/api/tool/upload"}
                headers={{
                  token: localStorage.getItem('token') || null,
                  channel: localStorage.getItem('channel') || null
                }}
                data={{image_type: imageType}}
                beforeUpload={beforeUpload}
                onChange={handleChange}
              >
                <div className="choose-btn">
                  <img className="" src={require('../../assets/images/icon-upload.svg')} />
                  <span className="choose-btn-name">选择图片</span>
                </div>
              </Upload>

              {/* 预选图片 */}
              <div className="pre-img-box">
                {
                  (preImageList || []).map(item => <span
                    id={`pre_image_item_${item.image_id}`}
                    className="pre-img-item"
                    onClick={() => onPreImageItemClick(item)}
                    onMouseEnter={() => onPreImageItemOver(item)}
                    onMouseLeave={() => onPreImageItemOut(item)}
                  >
                    <img className="pre-img" src={item.thumbnail} />
                  </span>)
                }

                {
                  preImageList.map(item => <div
                    id={`pre_image_detail_box_${item.image_id}`}
                    className={`pre-image-detail-box image-size-type-${getImageSizeType(item)}`}
                  >
                    <img className="detail-img" src={item.image} />
                  </div>)
                }
              </div>
              <div className="pre-img-text">先试试以上图片看看效果</div>
            </div>
          }

          {
            // 第二步 图片检测
            step === 2 && <div  className="step-info-2">
              <span className="current-img-box">
                <img className={`${imgSizeType===1 && "left-img-h"} ${imgSizeType===2 && "left-img-v"} ${imgSizeType===3 && "left-img"}`} src={imgUrl} />
                <div className="mask">
                  {
                    // 检测中
                    checkStatus === 1 && <img className="checking-img" src={require('../../assets/images/checking.svg')} />
                  }
                  {
                    // 检测未通过
                    checkStatus === 2 && <img className="status-img" src={require('../../assets/images/icon-error.svg')} />
                  }
                  {
                    // 检测通过
                    checkStatus === 3 && <img className="status-img" src={require('../../assets/images/icon-success.svg')} />
                  }
                </div>
              </span>

              {
                // 检测未通过明细
                <span className={`error-info-box ${checkStatus === 2 ? "show" : "hide"}`}>
                  <div className="error-type-box">
                    {
                      errorMap[checkErrorCode+''] && <span className="error-type">
                        <img className="error-icon" src={require('../../assets/images/icon-error-small.svg')} />
                        { errorMap[checkErrorCode+''].title }
                      </span>
                    }
                  </div>

                  <div className="error-msg-box">
                    {
                      errorMap[checkErrorCode+'']?.infos.map(info => <div className="error-mag-item">
                        { info }
                      </div>)
                    }
                  </div>

                  <div className="btn-box">
                    <Upload
                      name="image"
                      listType="text"
                      className="img-uploader"
                      showUploadList={false}
                      accept="image/png, image/jpeg, image/jpg"
                      action={configs.baseUrl + "/api/tool/upload"}
                      headers={{
                        token: localStorage.getItem('token') || null,
                        channel: localStorage.getItem('channel') || null
                      }}
                      data={{image_type: imageType}}
                      beforeUpload={beforeUpload}
                      onChange={handleChange}
                    >
                      <span className="change-img-btn">
                        <img className="upload-icon" src={require('../../assets/images/icon-upload.svg')} />
                        更换图片
                      </span>
                    </Upload>
                  </div>
                </span>
              }
            </div>
          }

          {
            // 第三步 图片设定
            <div className={`step-info-3 ${step === 3 ? "show" : "hide"}`}>
              <span className="current-img-box">
                <img className={`${imgSizeType===1 && "current-img-h"} ${imgSizeType===2 && "current-img-v"} ${imgSizeType===3 && "current-img"}`} src={imgUrl} />
                <div className="change-img-btn-bg">
                  <Upload
                    name="image"
                    listType="text"
                    className="img-uploader"
                    showUploadList={false}
                    accept="image/png, image/jpeg, image/jpg"
                    action={configs.baseUrl + "/api/tool/upload"}
                    headers={{
                      token: localStorage.getItem('token') || null,
                      channel: localStorage.getItem('channel') || null
                    }}
                    data={{image_type: imageType}}
                    beforeUpload={beforeUpload}
                    onChange={handleChange}
                  >
                    <span className="change-img-btn">更换图片</span>
                  </Upload>
                </div>
              </span>
              <span className="condition-info-box">
                <div className="gender-box">
                  <div className="title">选择模特性别</div>
                  <div className="gender-info">
                    <div className="tab-box">
                      <span className={`tab-item ${gender === 2 && "selected"}`} onClick={() => onGenderChange(2)}>女模特</span>
                      <span className={`tab-item ${gender === 1 && "selected"}`} onClick={() => onGenderChange(1)}>男模特</span>
                    </div>
                    <div className="gender-item-container">
                      <div id="gender_item_box" className={`gender-item-box`}>
                        {
                          config && getGenderTypeData(gender).data.map((item) =>
                            <span
                              id={`gender_item_${item.value}`}
                              className={`gender-item ${genderSelectId===item.value && "selected"}`}
                              onClick={() => onGenderIdChange(item.value)}
                              onMouseEnter={() => onGenderMouseIn(item)}
                              onMouseLeave={() => onGenderMouseOut(item)}
                            >
                              <span className="img-box">
                                <img className="img" src={item.image} />
                              </span>
                              <span className="name">{item.name}</span>

                              {/*tips*/}
                              <div
                                id={`gender_detail_box_${item.value}`}
                                className={'gender-detail-box'}
                                onClick={() => goModelStash(item.value)}
                              >
                                <img className="detail-img" src={item.image} />
                                <div className="detail-info-box">
                                  <div className="detail-name">
                                    <span>{item.name}</span>
                                    {
                                      item.value!==200 && item.value!==300
                                      && <span className={`more-btn show-hover`} onClick={() => goModelStash(item.value)}>more</span>
                                    }
                                  </div>
                                  <div className="detail-tag-box">
                                    {
                                      (item.tags || []).map(tag => <span className="detail-tag-item">#{tag}</span>)
                                    }
                                  </div>
                                </div>
                              </div>
                          </span>)
                        }
                      </div>
                    </div>
                  </div>
                </div>

                <div className="background-box">
                  <div className="title">选择背景风格</div>
                  <div className="background-info">
                    <div className="tab-box">
                      {
                        buildOptions?.background.data.map(item => <span
                          className={`tab-item ${tab === item.value && "selected"}`}
                          onClick={() => onTabChange(item.value)}>
                          { item?.name }
                        </span>)
                      }
                    </div>
                    <div className="background-item-container">
                      <div id="background_item_box" className={`background-item-box`}>
                        {
                          getBackgroundList(tab)?.data.map((item, index) => <span className={`background-item ${[1, 4].includes(tab) && "background-item-pure-color"} ${tab===2 && "background-item-indoor"}  ${tab===3 && "background-item-outdoor"}`} onClick={() => onBgIndexChange(item.value)}>
                            {
                              // 纯色背景 渐变色背景
                              [1, 4].includes(tab) && <span className={`item-pure-color ${bgSelectId===item.value && "selected"}`}>
                                <span className={`color-box ${bgSelectId===item.value && "selected"}`} style={{background: item.color}}></span>
                                {item.name}
                              </span>
                            }
                            {
                              // 室内
                              tab === 2 && <span className={`item-room ${bgSelectId===item.value && "selected"}`}>
                                <span className="img-box">
                                  <img className="img" src={item.image} />
                                </span>
                                <span className="name">{item.name}</span>
                              </span>
                            }
                            {
                              // 室外
                              tab === 3 && <span className={`item-room ${bgSelectId===item.value && "selected"}`}>
                                <span className="img-box">
                                  <img className="img" src={item.image} />
                                </span>
                                <span className="name">{item.name}</span>
                              </span>
                            }
                          </span>)
                        }
                      </div>
                    </div>
                    <div className="btn-box">
                      {/*快速生成*/}
                      <span className={`ai-btn quick-btn ${config?.quick_build.status === 0 && "disabled"}`} onClick={() => onAIBtnClick(1)}>
                        <div className="ai-btn-name-box">
                          <span className="ai-icon quick-icon"></span>
                          快速生成
                        </div>
                        <div className="ai-btn-time-box">{config?.quick_build.wait_desc}</div>

                        <span className="money-tag">
                          <img className="money-icon" src={require('../../assets/images/common/icon-money-gold.svg')} />
                          {config?.quick_build.cost}
                        </span>
                      </span>

                      {/*预约生成*/}
                      <span className="ai-btn time-btn" onClick={() => onAIBtnClick(2)}>
                        <div className="ai-btn-name-box">
                          <span className="ai-icon time-icon"></span>
                          预约生成
                        </div>
                        <div className="ai-btn-time-box">{config?.order_build.wait_desc}</div>

                        <span className="money-tag">
                          <img className="money-icon" src={require('../../assets/images/common/icon-money-gold.svg')} />
                          {config?.order_build.cost}
                        </span>

                        <span className="discount-box">
                          {config?.order_build.discount}折
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          }

          {
            // 第四步 生成过程 生成结果
            <div className={`step-info-4 ${step === 4 ? "show" : "hide"}`}>
              {
                (finish || taskInfo?.status===-1) && <div className="return-btn" onClick={() => upPage()}>
                  <span className="return-icon"></span> 上一步
                </div>
              }

              <span className="left-box">
                <div className="left-img-box">
                  <img className={`${imgSizeType===1 && "left-img-h"} ${imgSizeType===2 && "left-img-v"} ${imgSizeType===3 && "left-img"}`} src={imgUrl || taskInfo?.image || DEFAULT_PIC} />
                  <div className="mask">
                    {/*横向虚线*/}
                    <div className="dashed-box dashed-box-h">
                      <div className="dashed-line"></div>
                      <div className="dashed-line"></div>
                    </div>
                    {/*纵向虚线*/}
                    <div className="dashed-box dashed-box-v">
                      <div className="dashed-line"></div>
                      <div className="dashed-line"></div>
                    </div>

                    <img className="mark-1-img" src={require('../../assets/images/mark-1.svg')} />
                    <img className="mark-2-img" src={require('../../assets/images/mark-2.svg')} />
                  </div>
                </div>

                <div className="setting-box">
                  <span className="setting-left-box">
                    {
                      // 纯色、过度色
                      taskInfo && buildOptions && [1, 4].includes(taskInfo.background_style) && <span className="setting-bg-color" style={{background: taskInfo?.background_color}}></span>
                    }
                    {
                      // 非纯色、非过渡色
                      taskInfo && buildOptions && ![1, 4].includes(taskInfo.background_style) && <img className="setting-bg-img" src={taskInfo?.background_image} />
                    }
                    {
                      taskInfo && buildOptions && <img className="setting-head-img" src={taskInfo?.model_image} />
                    }
                  </span>
                  <span className="setting-right-box">
                    <div className="setting-right-item">{ taskInfo?.model_name }</div>
                    <div className="setting-right-item">{ taskInfo?.background_option_name }</div>
                  </span>
                </div>

                {/*选择新图片按钮*/}
                {
                  <div className={`new-img-btn-box ${(finish || taskInfo?.status===-1) ? "show" : "hide"}`}>
                    <Upload
                      name="image"
                      listType="text"
                      className="img-uploader"
                      showUploadList={false}
                      accept="image/png, image/jpeg, image/jpg"
                      action={configs.baseUrl + "/api/tool/upload"}
                      headers={{
                        token: localStorage.getItem('token') || null,
                        channel: localStorage.getItem('channel') || null
                      }}
                      data={{image_type: imageType}}
                      beforeUpload={beforeUpload}
                      onChange={handleChange}
                      openFileDialogOnClick={openFileDialogOnClick}
                    >
                      <div id="new_img_btn" className="new-img-btn" onClick={chooseNewImgClick}>选择新图片</div>
                    </Upload>
                  </div>
                }
              </span>
              <span className="right-process-box">
                <span className="right-process-top">
                {/*过程展示*/}
                {
                  !finish && <div className="process-container">
                    <div className="process-row-box">
                      <span className="process-box">
                        <img
                          className={`${imgSizeType===1 && "right-img-h"} ${imgSizeType===2 && "right-img-v"} ${imgSizeType===3 && "right-img"} ${[1].includes(progressStatus) && "hide"}`}
                          src={processImgBase64 ? `data:image/png;base64,${processImgBase64}` : (imgUrl || DEFAULT_PIC)}
                        />
                        {
                          [1].includes(progressStatus) && <img className="empty-icon" src={require('../../assets/images/icon-img-empty.svg')} />
                        }
                      </span>
                      <span className="process-box">
                        <img
                          className={`${imgSizeType===1 && "right-img-h"} ${imgSizeType===2 && "right-img-v"} ${imgSizeType===3 && "right-img"} ${[1].includes(progressStatus) && "hide"}`}
                          src={processImgBase64 ? `data:image/png;base64,${processImgBase64}` : (imgUrl || DEFAULT_PIC)}
                        />
                        {
                          [1].includes(progressStatus) && <img className="empty-icon" src={require('../../assets/images/icon-img-empty.svg')} />
                        }
                      </span>
                    </div>

                    <div className="process-row-box">
                      <span className="process-box">
                        <img
                          className={`${imgSizeType===1 && "right-img-h"} ${imgSizeType===2 && "right-img-v"} ${imgSizeType===3 && "right-img"} ${[1].includes(progressStatus) && "hide"}`}
                          src={processImgBase64 ? `data:image/png;base64,${processImgBase64}` : (imgUrl || DEFAULT_PIC)}
                        />
                        {
                          [1].includes(progressStatus) && <img className="empty-icon" src={require('../../assets/images/icon-img-empty.svg')} />
                        }
                      </span>
                      <span className="process-box">
                        <img
                          className={`${imgSizeType===1 && "right-img-h"} ${imgSizeType===2 && "right-img-v"} ${imgSizeType===3 && "right-img"} ${[1].includes(progressStatus) && "hide"}`}
                          src={processImgBase64 ? `data:image/png;base64,${processImgBase64}` : (imgUrl || DEFAULT_PIC)}
                        />
                        {
                          [1].includes(progressStatus) && <img className="empty-icon" src={require('../../assets/images/icon-img-empty.svg')} />
                        }
                      </span>
                    </div>

                    <div className={`process-circle ${progressStatus === -1 && "error"}`}>
                      {
                        progressStatus === -1
                          ? <img className="arrow-icon-error" src={require('../../assets/images/icon-arrow-error.svg')} />
                          : <img className="arrow-icon" src={require('../../assets/images/icon-arrow.svg')} />
                      }

                      <div className={`process-white-circle ${[2, 3].includes(progressStatus) && "hide-circle"}`}>
                        {/*进度条*/}
                        {
                          [2, 3].includes(progressStatus) && <div className="circle-progress">
                            <div className="left-circle">
                              <div id="left_progress_circle" className="left-progress-circle"></div>
                            </div>

                            <div id="right_progress_circle"  className="right-progress-circle"></div>
                          </div>
                        }

                        <div className={`process-center-black-circle ${progressStatus === -1 && "error"}`}>
                          <span className="process-center-txt-box">
                            {
                              [1].includes(progressStatus) && <span className={`process-queue-box ${taskInfo?.build_type===1 && "waiting"}`}>
                                {/*排队中、预约中*/}
                                {taskInfo?.status_name}
                                <div className="queue-time-box">
                                  {taskInfo?.wait_desc}
                                  <span className="tips-icon-box">
                                    <span
                                      className="tips-icon"
                                      onMouseOver={() => setShowBuildTips(true)}
                                      onMouseOut={() => setShowBuildTips(false)}
                                    ></span>
                                    {
                                      showBuildTips && <span className="tips">
                                        <img className="warn-icon" src={require('../../assets/images/common/icon-toast-warn.svg')} />
                                        {
                                          taskInfo?.build_type === 1 && <span className="tip-txt">该时间供您参考，实际生成时间受任务数量和AI 服务器资源消耗情况等综合影响</span>
                                        }
                                        {
                                          taskInfo?.build_type === 2 && <span className="tip-txt">此图片将在 AI 服务器资源充裕时进行生成，保证在24小时内完成</span>
                                        }
                                      </span>
                                    }
                                  </span>
                                </div>
                              </span>
                            }

                            {
                              [2, 3].includes(progressStatus) && <span className={`process-value-box`}>
                                生成中
                                <div className="process-value">
                                  {progressValue}<span className="process-per">%</span>
                                </div>
                              </span>
                            }

                            {
                              progressStatus === -1 && <span className={`process-error-box`}>
                                生成失败
                                <div className="desc-box">消耗的M豆已退回至您的账户</div>
                              </span>
                            }

                          </span>
                        </div>
                      </div>

                      {
                        taskInfo?.build_type===1 && progressStatus===1 && <span className="waiting-anim-box">
                          <span className="anim-light-box">
                            <img className="anim-light" src={require('../../assets/images/waiting-anim-light.svg')} />
                          </span>
                        </span>
                      }

                      <div className={`build-next-img-btn-box ${[1, 2, -1].includes(progressStatus) ? "show" : "hide"}`}>
                        <div className="build-next-img-btn" onClick={onBuildNextImg}>
                          <img className="ai-icon" src={require('../../assets/images/icon-ai-white.svg')} />
                          继续生成下一张
                        </div>
                      </div>
                    </div>
                  </div>
                }

                {/*结果展示*/}
                {
                  finish && <div className="finish-container">
                    {
                      // 纵向图 正方形图
                      [1, 3].includes(imgSizeType) && <div className="img-container">
                        <div className="select-img-box">
                          <img className={`${imgSizeType===1 && "select-img-h"} ${imgSizeType===2 && "select-img-v"} ${imgSizeType===3 && "select-img"}`} src={downloadImgInfo?.image} />
                          <div className="download-btn" onClick={() => onDownloadClick(downloadImgInfo)}>
                            <img className="download-icon" src={require('../../assets/images/icon-download.svg')} />
                            下载
                          </div>
                        </div>
                        <div className="img-item-box">
                          {
                            (finishImgList || []).map(imgItem => (
                              <img
                                className={`${imgSizeType===1 && "item-img-h"} ${imgSizeType===2 && "item-img-v"} ${imgSizeType===3 && "item-img"} ${downloadImgInfo.image_id===imgItem.image_id && "selected"}`}
                                src={imgItem.image} onClick={() => onFinishImgClick(imgItem)}
                              />
                            ))
                          }
                          <div className="rebuild-btn" onClick={() => onRebuildClick()}>重新生成</div>
                        </div>
                      </div>
                    }

                    {
                      // 横向图
                      imgSizeType === 2 && <div className="img-container-v">
                        <div className="select-img-box">
                          <img className={`select-img-v ${imgSizeType===3 && "select-img"}`} src={downloadImgInfo.image} />
                        </div>
                        <div className="img-item-box">
                          {
                            (finishImgList || []).map(imgItem => (
                              <img
                                className={`item-img-v ${downloadImgInfo.image_id===imgItem.image_id && "selected"}`}
                                src={imgItem.image} onClick={() => onFinishImgClick(imgItem)}
                              />
                            ))
                          }
                        </div>
                        <div className="btn-box">
                          <div className="download-btn" onClick={() => onDownloadClick(downloadImgInfo)}>
                            <img className="download-icon" src={require('../../assets/images/icon-download.svg')} />
                            下载
                          </div>
                          <div className="rebuild-btn" onClick={onRebuildClick}>重新生成</div>
                        </div>
                      </div>
                    }
                  </div>
                }
                </span>
                {/*图片生成记录按钮*/}
                <div className="record-btn" onClick={goImgRecord}>
                  图片生成记录
                  <span className="arrow-right-icon" />
                </div>
              </span>
            </div>
          }

        </div>
      </div>

      {
        confirmInfo.show && <ConfirmModal data={confirmInfo}/>
      }

      {
        isLoginRegisterOpen && <LoginRegister type={"login"} isOpen={isLoginRegisterOpen} onClose={onLoginRegisterClose}/>
      }

      {
        showBuildTypeModal && <BuildTypeModel isOpen={showBuildTypeModal} imageType={imageType} onClose={onBuildTypeModelClose}/>
      }
    </div>)

};


export default withRouter(Product);
import React, {useState, useEffect} from "react";
import {useSelector, useDispatch} from 'react-redux';
import {withRouter} from "react-router-dom";
import ContactUsH5 from '../../components/contact-us-h5'
import TryProductH5 from "../../components/try-product-h5";

import './index.less';

const HeaderH5 = ({}) => {
  const dispatch = useDispatch();
  const [isContactOpen, setIsContactOpen] = useState(null);
  const [isTryOpen, setIsTryOpen] = useState(null);

  useEffect(() => {
    // const token = window.localStorage.getItem('token');
    // if (token) {
    //   dispatch(getMemberInfo())
    // }

  }, [])

  const onHomeClick = () => {
    // history.push('/');
    window.open("/");
  }

  const onProductClick = () => {
    onTryClick();
    // window.open("/aimodel");
  }

  const onContactClick = () => {
    setIsContactOpen(true);
  }

  const onContactClose = () => {
    setIsContactOpen(false);
  }

  const onTryClick = () => {
    setIsTryOpen(true);
  }

  const onTryClose = () => {
    setIsTryOpen(false);
  }

  return (<div className="header-component-h5">
    <div className="main-container">
      <span className="left-box">
        <img className="logo" src={require('../../assets/images/common-h5/logo-x4.png')} onClick={onHomeClick}/>
      </span>
      <span className="right-box">
        {/*<span className="product-btn" onClick={onProductClick}>开始尝试</span>*/}
        <img className="contact-us" src={require('../../assets/images/head-h5/icon-phone.png')} onClick={onContactClick}/>
      </span>
      {/*联系我们*/}
      <ContactUsH5 isOpen={isContactOpen} onClose={onContactClose}/>
      <TryProductH5 isOpen={isTryOpen} onClose={onTryClose}/>
    </div>
  </div>)
};

export default withRouter(HeaderH5);
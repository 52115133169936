import React, {useState, useEffect} from "react";
import {useSelector,useDispatch} from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {withRouter} from "react-router-dom";
import API from "../../apis";
import LoginRegister from "../../components/login-register";
import moment from 'moment';
import {getMemberInfo, logout} from "../../store/actions/ai";
import {getQuery} from "../../utils/helpers";

import UserCenterRecord from "./record";
import UserCenterMessage from "./message";

import './index.less';

/**
 * 用户中心
 */
const UserCenter = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  // 左侧菜单配置
  const MENU_LIST = [
    {
      id: 1,
      name: '我的M豆',
      icon: require('../../assets/images/common/icon-money-20x4.png'),
      selectedIcon: require('../../assets/images/common/icon-money-gray-20x4.png'),
      title: '我的M豆 - MixModel.cn | AI模特，服装拍摄全新生产力',
    },
    {
      id: 2,
      name: '图片生成记录',
      icon: require('../../assets/images/user-center/icon-record.svg'),
      selectedIcon: require('../../assets/images/user-center/icon-record-gray.svg'),
      title: '图片生成记录 - MixModel.cn | AI模特，服装拍摄全新生产力',
    },
    {
      id: 3,
      name: '账号设置',
      icon: require('../../assets/images/user-center/icon-user.svg'),
      selectedIcon: require('../../assets/images/user-center/icon-user-gray.svg'),
      title: '账号设置 - MixModel.cn | AI模特，服装拍摄全新生产力',
    },
    {
      id: 4,
      name: '消息中心',
      icon: require('../../assets/images/user-center/icon-message.svg'),
      selectedIcon: require('../../assets/images/user-center/icon-message-gray.svg'),
      title: '消息中心 - MixModel.cn | AI模特，服装拍摄全新生产力',
    }
  ];

  // 左侧选中的菜单
  const [menuId, setMenuId] = useState(1);
  // 套餐信息
  const [coinInfo, setCoinInfo] = useState(null);
  // 显示重置密码窗口
  const [showReset, setShowReset] = useState(false);
  // 用户信息
  const [memberInfo, setMemberInfo] = useState(null);
  // 用户信息
  const { member } = useSelector(state => {
    return {
      member: state.getIn(['member', 'memberInfo']),
    }
  });


  useEffect(() => {
    const query = getQuery();
    if (query.tab) {
      setMenuId(parseInt(query.tab));
    }
  }, [])

  useEffect(() => {
    getMemberInfo();
  },[member])

  useEffect(() => {
    if (menuId === 1) {
      getCoinList();
    } else if (menuId === 3) {
      dispatch(getMemberInfo())
    }

    const menuData = getMenuData(menuId);
    if (menuData) {
      document.title = menuData.title;
    }
    // 链接添加参数
    window.history.replaceState(null, null, `/center?tab=${menuId || 1}`)
  },[menuId])

  const getMemberInfo = async () => {
    const params = {};
    const res = await API.getMemberInfo(params);
    if (res.code === 0) {
      setMemberInfo(res.data);
      if (!res.data) {
        history.push('/');
      }
    } else {
      setMemberInfo(null);
      if (!res.data) {
        history.push('/');
      }
    }
  };

  // 获取套餐列表
  const getCoinList = async () => {
    const params = {};
    const res = await API.getCoinList(params);
    if (res.code === 0) {
      setCoinInfo(res.data);
    }
  }

  // 获取菜单数据
  const getMenuData = (menuId) => {
    const data = MENU_LIST.find(item => {
      return item.id === menuId;
    })
    return data;
  }

  // 修改密码按钮点击
  const onUpdateBtnClick = () => {
    setShowReset(true);
  }

  // 退出登录按钮点击
  const onLogoutBtnClick = () => {
    dispatch(logout());
  }

  // 获取百分比
  const getUsePercent = (data) => {
    if (data.status === 0) {
      return '0%';
    }
    return ((data.package_coin - data.used_coin) / data.package_coin) * 100 + '%'
  }

  // 购买
  const onBuyClick = () => {
    window.open('/deposit');
  }

  const onMenuChange = (id) => {
    // window.location.href= `/center?tab=${id}`;
    setMenuId(id)
  }

  return (<div className="user-center-page">
    <div className={`user-center-body`}>
      <span className="left-menu-box">
        {
          MENU_LIST.map(item => <div key={item.id} className={`menu-item ${menuId === item.id ? 'selected' : ''}`} onClick={() => onMenuChange(item.id)}>
            {
              menuId === item.id
                ? <img className="item-icon" src={item.icon}/>
                : <img className="item-icon" src={item.selectedIcon}/>
            }
            {item.name}
          </div>)
        }

        <div className="logout-btn" onClick={onLogoutBtnClick}>退出登录</div>
      </span>
      <span className="right-content-box">
        {/*我的M豆*/}
        <div className={`money-container ${menuId === 1 ? 'show' : 'hidden'}`}>
          <div className="top-bar">
            <span className="left-box">M豆详情</span>
            <span className="right-box">
              当前可用：
              <img className="money-icon" src={require('../../assets/images/common/icon-money-20x4.png')}/>
              <span className="money-value">{coinInfo && coinInfo.coin}</span>
              <span className="buy-btn" onClick={onBuyClick}>购买M豆</span>
            </span>
          </div>

          <div className="main-box">
            {/*空数据*/}
            {
              coinInfo && coinInfo.list.length===0 && <div className="empty-box">
                <img className="empty-icon" src={require('../../assets/images/common/icon-empty.svg')}/>
                <span>暂无可用M豆</span>
                <div className="buy-now-btn" onClick={onBuyClick}>立即购买</div>
              </div>
            }

            <div className="list-box">
              {
                coinInfo && (coinInfo.list || []).map(item => <div key={item.id} className={`item-box ${item.status !== 1 && "invalid"}`}>
                  <div className="top-box">
                    <span className="money-box">
                      {
                        item.status === 1 && <img className="money-icon" src={require('../../assets/images/common/icon-money-20x4.png')}/>
                      }
                      {
                        item.status !== 1 && <img className="money-icon" src={require('../../assets/images/common/icon-money-gray-20x4.png')}/>
                      }
                      <span className="money-value">{item.coin}</span>
                    </span>
                      <span className="deadline-box">有效期至 {moment(item.expire_time * 1000).format('YYYY.MM.DD')}</span>
                  </div>
                  <div className="progress-bar-box">
                    <div className="progress-bar" style={{width: getUsePercent(item)}}></div>
                  </div>
                  <div className="value-box">
                    <span className="remain-box">
                      剩余 <span className="current-value">{item.package_coin - item.used_coin}</span> <span className="max-value">/ {item.package_coin}</span>
                    </span>
                    <span>已用 {item.used_coin}</span>
                  </div>
                </div>)
              }
            </div>
          </div>
        </div>

        {/*生成记录*/}
        {
          menuId === 2 && <UserCenterRecord />
        }

        {/*账号设置*/}
        <div className={`account-container ${menuId === 3 ? 'show' : 'hidden'}`}>
          <div className="title-box"><span className="title">账号设置</span></div>
          <div className="account-item first">
            <span className="left-box">
              <span className="name">头像</span>
              <img className="head-icon" src={require('../../assets/images/common/icon-head.svg')}/>
            </span>
          </div>
          <div className="account-item">
            <span className="left-box">
              <span className="name">ID</span>
              <span className="value">{memberInfo?.id}</span>
            </span>
          </div>
          <div className="account-item">
            <span className="left-box">
              <span className="name">手机号</span>
              <span className="value">{memberInfo?.mobile}</span>
            </span>
          </div>
          <div className="account-item">
            <span className="left-box">
              <span className="name">密码</span>
              <span className="value">已设置</span>
            </span>
            <span className="right-box">
              <span className="update-btn" onClick={onUpdateBtnClick}>修改</span>
            </span>
          </div>
        </div>

        {/*消息中心*/}
        {
          menuId === 4 && <UserCenterMessage />
        }
      </span>
    </div>
    {/*重置密码*/}
    {
      showReset && <LoginRegister type={"reset"} isOpen={showReset} memberInfo={memberInfo} onClose={() => setShowReset(false)}/>
    }
  </div>)
};

export default withRouter(UserCenter);

import React, {Component} from 'react';
import {connect} from "react-redux";
import {Form} from "antd";
import {withRouter} from "react-router-dom";

import './index.less';
import {set} from "immutable";
import {getQuery, sleep} from "../../utils/helpers";
import API from "../../apis";

class Home extends Component {

  state = {
    CONFIG_BG_LIST: [
      { bgId: 101, name: '城市', bgImg: require('../../assets/images/home/bg-101.png') },
      { bgId: 102, name: '灰色', bgImg: require('../../assets/images/home/bg-102.png') },
      { bgId: 103, name: '客厅', bgImg: require('../../assets/images/home/bg-103.png') },
      { bgId: 104, name: '教室', bgImg: require('../../assets/images/home/bg-104.png') },
      { bgId: 105, name: '游乐园', bgImg: require('../../assets/images/home/bg-105.png') },
      { bgId: 106, name: '海边', bgImg: require('../../assets/images/home/bg-106.png') },
      { bgId: 107, name: '咖啡厅', bgImg: require('../../assets/images/home/bg-107.png') },
      { bgId: 108, name: '公园', bgImg: require('../../assets/images/home/bg-108.png') },
    ],

    CONFIG_MAP: [
      {
        iconImg: require('../../assets/images/home/config-head-1.png'),
        list: [
          { bgId: 101, img: require('../../assets/images/home/config-img-1-1.png') },
          { bgId: 102, img: require('../../assets/images/home/config-img-1-2.png') },
          { bgId: 103, img: require('../../assets/images/home/config-img-1-3.png') },
          { bgId: 104, img: require('../../assets/images/home/config-img-1-4.png') },
          { bgId: 105, img: require('../../assets/images/home/config-img-1-5.png') },
          { bgId: 106, img: require('../../assets/images/home/config-img-1-6.png') },
          { bgId: 107, img: require('../../assets/images/home/config-img-1-7.png') },
          { bgId: 108, img: require('../../assets/images/home/config-img-1-8.png') },
        ]
      },
      {
        iconImg: require('../../assets/images/home/config-head-2.png'),
        list: [
          { bgId: 101, img: require('../../assets/images/home/config-img-2-1.png') },
          { bgId: 102, img: require('../../assets/images/home/config-img-2-2.png') },
          { bgId: 103, img: require('../../assets/images/home/config-img-2-3.png') },
          { bgId: 104, img: require('../../assets/images/home/config-img-2-4.png') },
          { bgId: 105, img: require('../../assets/images/home/config-img-2-5.png') },
          { bgId: 106, img: require('../../assets/images/home/config-img-2-6.png') },
          { bgId: 107, img: require('../../assets/images/home/config-img-2-7.png') },
          { bgId: 108, img: require('../../assets/images/home/config-img-2-8.png') },
        ]
      },
      {
        iconImg: require('../../assets/images/home/config-head-3.png'),
        list: [
          { bgId: 101, img: require('../../assets/images/home/config-img-3-1.png') },
          { bgId: 102, img: require('../../assets/images/home/config-img-3-2.png') },
          { bgId: 103, img: require('../../assets/images/home/config-img-3-3.png') },
          { bgId: 104, img: require('../../assets/images/home/config-img-3-4.png') },
          { bgId: 105, img: require('../../assets/images/home/config-img-3-5.png') },
          { bgId: 106, img: require('../../assets/images/home/config-img-3-6.png') },
          { bgId: 107, img: require('../../assets/images/home/config-img-3-7.png') },
          { bgId: 108, img: require('../../assets/images/home/config-img-3-8.png') },
        ]
      }
    ],

    STEP_LIST: [
      {
        step: 1,
        stepEn: 'ONE',
        name: '选择图片',
        enName: 'SELECT IMAGE',
        videoTime: 4300,
        video: require('../../assets/video/video-config-1.mp4'),
        icon: require('../../assets/images/home/icon-step-1.svg'),
        iconSelected: require('../../assets/images/home/icon-step-1-selected.svg')
      },
      {
        step: 2,
        stepEn: 'TWO',
        name: '图片检测',
        enName: 'IMAGE DETECTION',
        videoTime: 3000,
        video: require('../../assets/video/video-config-2.mp4'),
        icon: require('../../assets/images/home/icon-step-2.svg'),
        iconSelected: require('../../assets/images/home/icon-step-2-selected.svg')
      },
      {
        step: 3,
        stepEn: 'THREE',
        name: '图片设定',
        enName: 'IMAGE SETTING',
        videoTime: 2500,
        video: require('../../assets/video/video-config-3.mp4'),
        icon: require('../../assets/images/home/icon-step-3.svg'),
        iconSelected: require('../../assets/images/home/icon-step-3-selected.svg')
      },
      {
        step: 4,
        stepEn: 'FOUR',
        name: '图片生成',
        enName: 'AI GENERATION',
        videoTime: 9500,
        video: require('../../assets/video/video-config-4.mp4'),
        icon: require('../../assets/images/home/icon-step-4.svg'),
        iconSelected: require('../../assets/images/home/icon-step-4-selected.svg')
      },
    ],

    ADVANTAGE_LIST: [
      {
        id: 1,
        name: '写实模特效果图',
        img: require('../../assets/images/home/advantage-img-1.png'),
        desc: '通过 AI 算法和计算机视觉技术，MixModel 根据您的服装设计与场景需求，生成超写实的模特展示图'
      },
      {
        id: 2,
        name: '海量 AI 模特可选',
        img: require('../../assets/images/home/advantage-img-2.png'),
        desc: '在 MixModel 的模特库中，您可以选择不同性别、不同形象、不同身材、不同年龄的各类 AI 模特'
      },
      {
        id: 3,
        name: '定制化场景设置',
        img: require('../../assets/images/home/advantage-img-3.png'),
        desc: '从街头潮流到正式晚礼服，从室内拍摄到户外风景，MixModel 为您创造适应不同场景和风格的模特图'
      },
      {
        id: 4,
        name: '高效率高精度出图',
        img: require('../../assets/images/home/advantage-img-4.png'),
        desc: '高效算法可在几秒内生成高品质模特图，大大降低制作时间和成本，生成的图片可与真人模特媲美'
      }
    ],

    configImgIndex: 0,
    configHeadInit: false,
    configBgId: 101,
    currentStepItem: {},
    isStepAutoChange: true,
    advantageTitleInit: false,
    advantageBodyInit: false,
    advantageItemList: [],
    currentAdvantage: {},
    stepTitleInit: false,
    stepBodyInit: false,
    stepAutoTimer: null,
    dataInterval: null,
    configImgUpdateTime: 0,
    configAutoTimer: null,
    productImageType: 1,
    productImageTypeTemp: 1,

    dataList: [
      {max: 75, current: 0},
      {max: 5, current: 0},
      {max: 80, current: 0},
      {max: 20, current: 0},
    ]
  };

  componentDidMount() {
    document.title = 'MixModel.cn | AI模特，服装拍摄全新生产力';

    const query = getQuery();
    // 跳转到生成模块
    if (query.model) {
      setTimeout(() => {
        this.onTryBtnClick();
      }, 400)
    }

    this.initData();
    this.initEvent();
    this.getAccess();
  }

  componentWillUnmount() {
    this.removeEvent();

    if (this.state.stepAutoTimer) {
      clearTimeout(this.state.stepAutoTimer);
      this.setState({
        stepAutoTimer: null
      })
    }
    if (this.state.dataInterval) {
      clearInterval(this.state.dataInterval)
      this.setState({
        dataInterval: null
      })
    }
    if (this.state.configAutoTimer) {
      clearTimeout(this.state.configAutoTimer);
      this.setState({
        configAutoTimer: null
      })
    }
  }

  initData = () => {
    // this.onStepItemClick(this.state.STEP_LIST[0]);
    this.setState({
      advantageItemList: this.state.ADVANTAGE_LIST,
      currentAdvantage: this.state.ADVANTAGE_LIST[0]
    })
  }

  initEvent = () => {
    this.checkScrollTop();
    // 滚动显示动画事件
    window.addEventListener('scroll', this.onScrollEvent, true);
  }

  removeEvent = () => {
    // 滚动显示动画事件
    window.removeEventListener('scroll', this.onScrollEvent, true);
  }

  onScrollEvent = () => {
    this.checkScrollTop();
  }

  // 首次访问接口
  getAccess = async () => {
    const res = await API.access(null);
    if (res.code === 0) {

    }
  }

  // 滚动条位置
  checkScrollTop = () => {
    // 浏览器高度
    const clientHeight = document.body.clientHeight;
    // 滚动条高度
    const scrollTop = document.scrollingElement.scrollTop;
    const posY = clientHeight + scrollTop;
    // console.log('clientHeight......', clientHeight, scrollTop, clientHeight + scrollTop);

    // 选择配置标题动效
    if (posY > 870) {
      const elm1 = document.getElementById('config_title_txt');
      const elm2 = document.getElementById('config_desc_txt');
      elm1.style.setProperty('--titleAnimName', `titleAnim`);
      elm2.style.setProperty('--titleAnimName', `titleAnim`);
    }

    // 数字标题动效
    if (posY > 1770) {
      const elm1 = document.getElementById('data_title_txt');
      const elm2 = document.getElementById('data_desc_txt');
      elm1.style.setProperty('--titleAnimName', `titleAnim`);
      elm2.style.setProperty('--titleAnimName', `titleAnim`);
    }

    // 图片生成类型
    if (posY > 2170) {
      const elm1 = document.getElementById('product_type_body');
      elm1.style.setProperty('--titleAnimName', `titleAnim`);

      this.handleProductImageHoverAnim(true);
    }

    // 步骤标题动效
    if (!this.state.stepTitleInit && posY > 2760) {
      this.setState({
        stepTitleInit: true
      });
      const elm = document.getElementById('step_title_txt');
      elm.style.setProperty('--titleAnimName', `titleAnim`);
    }

    // 步骤内容动效
    if (!this.state.stepBodyInit && posY > 2910) {
      this.setState({
        stepBodyInit: true
      });
      const bodyElm = document.getElementById('step_body');
      bodyElm.style.setProperty('--stepBodyAnimName', `stepBodyAnim`);
      this.onStepItemClick(this.state.STEP_LIST[0]);
    }

    // 优势标题动效
    if (!this.state.advantageTitleInit && posY > 3480) {
      this.setState({
        advantageTitleInit: true
      })
      const elm = document.getElementById('advantage_title_txt');
      elm.style.setProperty('--titleAnimName', `titleAnim`);
    }

    // 优势内容动效
    if (!this.state.advantageBodyInit && posY > 3630) {
      this.setState({
        advantageBodyInit: true
      })
      const elm1 = document.getElementById('advantage_body');
      elm1.style.setProperty('--titleAnimName', `titleAnim`);
      setTimeout(() => {
        const elm2 = document.getElementById('advantage_desc_title_line');
        elm2.style.setProperty('--advantageDescTitleLineAnimName', `advantageDescTitleLineAnim1`);
      }, 400)

      this.state.ADVANTAGE_LIST.map((item, index) => {
        setTimeout(() => {
          const elm = document.getElementById(`advantage_item_${item.id}`);
          elm.style.setProperty('--advantageAnimName', `advantageItemFlyAnim${item.id}`);
        }, 100 * index + 1000);
      });
    }

    // 选择配置动效
    if (posY > 1000) {
      if (!this.state.configHeadInit) {
        this.setState({
          configHeadInit: true
        })
        this.onConfigHeadChange(0, true);
        this.onConfigBgChange(this.state.CONFIG_BG_LIST[0].bgId);
        this.configFlyAnim();
        this.configBgItemFlyAnim();
      }
    }

    // 数字滚动动效
    if (posY > 1860) {
      this.dataRoll();
    }
  }

  getConfigData = (imgIndex, bgId) => {
    const data = this.state.CONFIG_MAP[imgIndex].list.find(item => {
      return item.bgId === bgId;
    })
    return data;
  }

  getConfigBgData = (bgId) => {
    const data = this.state.CONFIG_BG_LIST.find(item => {
      return item.bgId === bgId;
    })
    return data.bgImg;
  }

  // 数字滚动
  dataRoll = () => {
    const elm1 = document.getElementById('data_item_value_box_1');
    const elm2 = document.getElementById('data_item_value_box_2');
    const elm3 = document.getElementById('data_item_value_box_3');
    const elm4 = document.getElementById('data_item_value_box_4');
    elm1.style.setProperty('--dataItemValueBoxAnimName', `dataItemShowAnim`);
    elm1.style.setProperty('--dataItemValueBoxAnimDelay', '0.2s');
    elm2.style.setProperty('--dataItemValueBoxAnimName', `dataItemShowAnim`);
    elm2.style.setProperty('--dataItemValueBoxAnimDelay', '0.6s');
    elm3.style.setProperty('--dataItemValueBoxAnimName', `dataItemShowAnim`);
    elm3.style.setProperty('--dataItemValueBoxAnimDelay', '1s');
    elm4.style.setProperty('--dataItemValueBoxAnimName', `dataItemShowAnim`);
    elm4.style.setProperty('--dataItemValueBoxAnimDelay', '1.4s');

    const arrowElm1 = document.getElementById('arrow_icon_1');
    const arrowElm2 = document.getElementById('arrow_icon_2');
    const arrowElm3 = document.getElementById('arrow_icon_3');
    const arrowElm4 = document.getElementById('arrow_icon_4');
    arrowElm1.style.setProperty('--arrowIconAnimName', `arrowIconWebAnimDown`);
    arrowElm1.style.setProperty('--arrowIconAnimDelay', '0.2s');
    arrowElm2.style.setProperty('--arrowIconAnimName', `arrowIconWebAnimUp`);
    arrowElm2.style.setProperty('--arrowIconAnimDelay', '0.6s');
    arrowElm3.style.setProperty('--arrowIconAnimName', `arrowIconWebAnimUp`);
    arrowElm3.style.setProperty('--arrowIconAnimDelay', '1s');
    arrowElm4.style.setProperty('--arrowIconAnimName', `arrowIconWebAnimUp`);
    arrowElm4.style.setProperty('--arrowIconAnimDelay', '1.4s');

    if (this.state.dataInterval) {
      return;
    }
    let list = [...this.state.dataList];
    // 计时器
    let time = 0;
    let dataInterval = setInterval(() => {
      // 删除计时器
      if (list[0].current>=list[0].max
        && list[1].current>=list[1].max
        && list[2].current>=list[2].max
        && list[3].current>=list[3].max) {
        clearInterval(this.state.dataInterval)
        // this.setState({
        //   dataInterval: null
        // })
      }

      // 延时200毫秒
      if (time > 200 && list[0].current < list[0].max) {
        list[0].current += 6;
        if (list[0].current > list[0].max) {
          list[0].current = list[0].max
        }
      }
      // 延时600毫秒
      if (time > 600 && list[1].current < list[1].max) {
        list[1].current += 1;
        if (list[1].current > list[1].max) {
          list[1].current = list[1].max
        }
      }
      // 延时1000毫秒
      if (time > 1000 && list[2].current < list[2].max) {
        list[2].current += 6;
        if (list[2].current > list[2].max) {
          list[2].current = list[2].max
        }
      }
      // 延时1400毫秒
      if (time > 1400 && list[3].current < list[3].max) {
        list[3].current += 2;
        if (list[3].current > list[3].max) {
          list[3].current = list[3].max
        }
      }
      this.setState({
        dataList: list,
      })

      time += 80;
    }, 80);

    this.setState({
      dataInterval: dataInterval
    })
  }

  // 配置栏自动轮换
  configAutoChange = (isAuto) => {
    const timer = setTimeout(() => {
      if (this.state.configBgId === this.state.CONFIG_BG_LIST.slice(-1)[0].bgId) {
        // 当前角色背景已经轮播完，播放下一个角色第一张背景图
        const configIndex = this.state.configImgIndex + 1 >= this.state.CONFIG_MAP.length ? 0 : this.state.configImgIndex + 1;
        this.onConfigHeadChange(configIndex);
        this.onConfigBgChange(this.state.CONFIG_BG_LIST[0].bgId, true);
      } else {
        // 当前角色背景未轮播完，播放下一张背景图
        let i = 0;
        this.state.CONFIG_BG_LIST.map((item, index) => {
          if (item.bgId === this.state.configBgId) {
            i = index + 1;
          }
        });
        this.onConfigBgChange(this.state.CONFIG_BG_LIST[i].bgId, true);
      }
    }, isAuto ? 3000 : 4000);
    this.setState({
      configAutoTimer: timer
    });
  }

  // 重新开始配置栏自动轮换
  restartConfigAutoChange = (isAuto) => {
    if (this.state.configAutoTimer) {
      clearTimeout(this.state.configAutoTimer);
      this.setState({
        configAutoTimer: null
      }, () => {
        // 开始新的计时器
        this.configAutoChange(isAuto);
      })
    } else {
      // 开始新的计时器
      this.configAutoChange(isAuto);
    }
  }

  onConfigHeadChange = (index, isInit) => {
    const oldIndex = this.state.configImgIndex;
    if (!isInit && oldIndex===index) {
      return;
    }

    this.setState({
      configImgIndex: index
    })

    // 切换角色后背景图保持不变
    this.onConfigBgChange(this.state.configBgId);

    // 老的绿色圆环隐藏
    const oldElm = document.getElementById(`head_img_item_selected_img_${oldIndex}`);
    oldElm.style.setProperty('--headSelectedAnimName', `headSelectedAnimationOut`);
    // 打钩图标
    const oldTickElm = document.getElementById(`head_img_item_tick_${oldIndex}`);
    oldTickElm.style.setProperty('--headSelectedIconAnimName', `headSelectedIconAnimOut`);

    setTimeout(() => {
      // 新的绿色圆环出现
      const newElm = document.getElementById(`head_img_item_selected_img_${index}`);
      newElm.style.setProperty('--headSelectedAnimName', `headSelectedAnimationIn`);
      // 打钩图标
      const tickElm = document.getElementById(`head_img_item_tick_${index}`);
      tickElm.style.setProperty('--headSelectedIconAnimName', `headSelectedIconAnimIn`);
    }, 500)
  }

  onConfigBgChange = (id, isAuto) => {
    this.setState({
      configBgId: id
    })

    this.restartConfigAutoChange(isAuto);
    this.configImgAnim();
  }

  configImgAnim = () => {
    const imgBoxElm = document.getElementById('config_img_box');
    const imgElm = document.getElementById('config_img');
    imgBoxElm.style.setProperty('--configImgBoxAnimName', `configImgBoxAnim${this.state.configImgUpdateTime%2}`);
    imgElm.style.setProperty('--configImgAnimName', `configImgAnim${this.state.configImgUpdateTime%2}`);

    this.setState({
      configImgUpdateTime: this.state.configImgUpdateTime + 1
    })
  }

  onStepItemClick = (itemData) => {
    if (itemData.step === this.state.currentStepItem.step) {
      return;
    }

    const stepEnElm = document.getElementById('step_en');
    const stepNumElm = document.getElementById('step_num');
    stepEnElm.style.setProperty('--stepEnAnimName', 'stepEnOut');
    stepNumElm.style.setProperty('--stepNumAnimName', 'stepNumOut');

    setTimeout(() => {
      this.setState({
        currentStepItem: itemData
      }, () => {
        this.stepAutoChange();
        const stepVideoElm = document.getElementById('step_video');
        stepVideoElm.load();
      })
      stepEnElm.style.setProperty('--stepEnAnimName', 'stepEnIn');
      stepNumElm.style.setProperty('--stepNumAnimName', 'stepNumIn');
    }, 500)
  }

  onStepItemOver = () => {
    this.setState({
      isStepAutoChange: false
    })
  }

  onStepItemOut = () => {
    this.setState({
      isStepAutoChange: true
    })
  }

  onProductImageEnter = async (type) => {
    this.setState({
      productImageTypeTemp: type
    });

    // 等待0.3秒后再执行，防止滚动误触发
    await sleep(200);

    this.handleProductImageHoverAnim();
  }

  onProductImageLeave = (type) => {
    this.setState({
      productImageTypeTemp: this.state.productImageType
    });
  }

  handleProductImageHoverAnim = (isInit = false) => {
    let type = this.state.productImageType;
    if (!isInit) {
      type = this.state.productImageTypeTemp;
      if (this.state.productImageType === type) {
        return;
      }
    }
    // const type = this.state.productImageTypeTemp;
    // if (this.state.productImageType === type) {
    //   return;
    // }

    this.setState({
      productImageType: type
    });

    const otherType = type === 2 ? 1 : 2;
    const elm1 = document.getElementById(`big_info_box_${type}`);
    const elm2 = document.getElementById(`big_info_title_${type}`);
    const elm3 = document.getElementById(`big_info_desc_${type}`);
    const elm4 = document.getElementById(`big_info_btn_${type}`);
    const elm5 = document.getElementById(`small_info_box_${type}`);
    const elm6 = document.getElementById(`small_info_top_box_${type}`);
    // const elm7 = document.getElementById(`small_info_btn_${type}`);
    const elm8 = document.getElementById(`small_info_top_line_${type}`);
    const elm9 = document.getElementById(`big_info_top_line_${type}`);

    elm1.style.setProperty('--productBigInfoBoxAnimName', `productBigInfoBoxShowAnim`);
    elm2.style.setProperty('--productBigInfoTitleAnimName', `productBigInfoTitleShowAnim`);
    elm3.style.setProperty('--productBigInfoDescAnimName', `productBigInfoDescShowAnim`);
    elm4.style.setProperty('--productBigInfoBtnAnimName', `productBigInfoBtnShowAnim`);
    elm6.style.setProperty('--productSmallInfoTopBoxAnimName', `productSmallInfoTopBoxHideAnim`);
    // elm7.style.setProperty('--productSmallInfoBtnBoxAnimName', `productSmallInfoBtnBoxHideAnim`);
    elm8.style.setProperty('--productSmallInfoTitleLineAnimName', `productSmallInfoTitleLineHideAnim`);
    elm9.style.setProperty('--productBigInfoTitleLineAnimName', `productBigInfoTitleLineShowAnim`);

    const otherElm1 = document.getElementById(`big_info_box_${otherType}`);
    const otherElm2 = document.getElementById(`big_info_title_${otherType}`);
    const otherElm3 = document.getElementById(`big_info_desc_${otherType}`);
    const otherElm4 = document.getElementById(`big_info_btn_${otherType}`);
    const otherElm5 = document.getElementById(`small_info_box_${otherType}`);
    const otherElm6 = document.getElementById(`small_info_top_box_${otherType}`);
    // const otherElm7 = document.getElementById(`small_info_btn_${otherType}`);
    const otherElm8 = document.getElementById(`small_info_top_line_${otherType}`);
    const otherElm9 = document.getElementById(`big_info_top_line_${otherType}`);

    otherElm1.style.setProperty('--productBigInfoBoxAnimName', `productBigInfoBoxHideAnim`);
    otherElm2.style.setProperty('--productBigInfoTitleAnimName', `productBigInfoTitleHideAnim`);
    otherElm3.style.setProperty('--productBigInfoDescAnimName', `productBigInfoDescHideAnim`);
    otherElm4.style.setProperty('--productBigInfoBtnAnimName', `productBigInfoBtnHideAnim`);
    otherElm6.style.setProperty('--productSmallInfoTopBoxAnimName', `productSmallInfoTopBoxShowAnim`);
    // otherElm7.style.setProperty('--productSmallInfoBtnBoxAnimName', `productSmallInfoBtnBoxShowAnim`);
    otherElm8.style.setProperty('--productSmallInfoTitleLineAnimName', `productSmallInfoTitleLineShowAnim`);
    otherElm9.style.setProperty('--productBigInfoTitleLineAnimName', `productBigInfoTitleLineHideAnim`);

  }

  configFlyAnim = () => {
    for (let index=0; index<4; index++) {
      setTimeout(() => {
        const headImgItemElm = document.getElementById(`head_img_item_box_${index}`);
        if (headImgItemElm) {
          headImgItemElm.style.setProperty('--headImgFlyAnimName', `headImgFly`);
        }
      }, 100 * index)
    }
  }

  configBgItemFlyAnim = () => {
    for (let index=0; index<4; index++) {
      setTimeout(() => {
        const itemElm1 = document.getElementById(`config_bg_item_${index}`);
        const itemElm2 = document.getElementById(`config_bg_item_${index+4}`);
        if (itemElm1 && itemElm2) {
          itemElm1.style.setProperty('--configBgItemFlyAnimName', `configBgItemFly`);
          itemElm2.style.setProperty('--configBgItemFlyAnimName', `configBgItemFly`);
        }
      }, 100 * index)
    }
  }

  stepAutoChange = () => {
    if (this.state.stepAutoTimer) {
      clearTimeout(this.state.stepAutoTimer);
      this.setState({
        stepAutoTimer: null
      })
    }

    const stepData = this.state.currentStepItem;
    const timer = setTimeout(() => {
      if (!this.state.isStepAutoChange) {
        return;
      }

      let step = stepData.step + 1;
      if (step > this.state.STEP_LIST.length) {
        step = 1
      }

      this.onStepItemClick(this.state.STEP_LIST[step - 1]);
    }, stepData.videoTime);

    this.setState({
      stepAutoTimer: timer
    })
  }

  // 优势栏目左右切换
  advantagePageChange = (isLeft) => {
    let list = [...this.state.advantageItemList];

    let currentItem = null;
    list.map((item, index) => {
      const itemElm = document.getElementById(`advantage_item_${item.id}`);
      const imgElm = document.getElementById(`advantage_img_${item.id}`);
      const nameElm = document.getElementById(`advantage_name_${item.id}`);

      const type = isLeft ? '' : 'Right';
      itemElm.style.setProperty('--advantageAnimName', `advantageAnim${index+1}${type}`);
      if (isLeft && [1, 2].includes(index + 1)) {
        imgElm.style.setProperty('--advantageImgAnimName', `advantageImgAnim${index+1}`);
        nameElm.style.setProperty('--advantageNameAnimName', `advantageNameAnim${index+1}`);

        if (index === 1) {
          currentItem = item;
        }
      }
      if (!isLeft && [1, 4].includes(index + 1)) {
        imgElm.style.setProperty('--advantageImgAnimName', `advantageImgAnim${index+1}${type}`);
        nameElm.style.setProperty('--advantageNameAnimName', `advantageNameAnim${index+1}${type}`);

        if (index === 3) {
          currentItem = item;
        }
      }
    })

    const descElm = document.getElementById('advantage_selected_desc');
    const descElm2 = document.getElementById('advantage_desc_title_line');
    descElm.style.setProperty('--advantageDescAnimName', `advantageDescAnim${currentItem.id%2}`);
    descElm2.style.setProperty('--advantageDescTitleLineAnimName', `advantageDescTitleLineAnim${currentItem.id%2}`);


    if (isLeft) {
      const tempItem = list[0];
      list.splice(0, 1);
      list.push(tempItem);
    } else {
      const tempItem = list[list.length - 1];
      list.pop();
      list.unshift(tempItem);
    }

    setTimeout(() => {
      this.setState({
        advantageItemList: list,
        currentAdvantage: currentItem
      })
    }, 200)

  }

  onTryBtnClick = () => {
    // 滚动到选择生成图片类型位置
    const targetY = 2080;
    const goProductInterval = setInterval(() => {
      const scrollTop = document.scrollingElement.scrollTop;
      if (scrollTop >= targetY) {
        clearInterval(goProductInterval);
        document.documentElement.scrollTop = targetY;
        return;
      }
      document.documentElement.scrollTop = scrollTop + 80;
    }, 10)

    // this.handleScroll(2080);
  }

  handleScroll = (pos) => {
    setTimeout(() => {
      document.documentElement.scrollTop = pos;
    }, 50)
  }

  onProductClick = (type) => {
    const { history } = this.props;
    history.push(`/aimodel?type=${type}`, null);
  }

  render() {
    const {
      CONFIG_MAP,
      configImgIndex,
      configBgId,
      CONFIG_BG_LIST,
      STEP_LIST,
      ADVANTAGE_LIST,
      dataList,
      currentStepItem,
      currentAdvantage,
      productImageType
    } = this.state;

    return <div className="home-page">
      <div className="main-container">
        {/*banner*/}
        <div className="banner-container">
          <img className="banner-img" src={require('../../assets/images/home/banner-img.jpg')} />
          <div className="banner-body">
            <div className="banner-lines">
              <span className="line-1"></span>
              <span className="line-2"></span>
              <span className="line-3"></span>
            </div>
            <img className="logo" src={require('../../assets/images/home/logo-x4.png')} />
            <div className="title-box">
              <span className="title-ai">AI模特 <span className="cursor"></span></span>
              <span>服装拍摄全新生产力</span>
            </div>
            <div className='desc-box'>
              <div>MixModel 使用全新的 AIGC 技术，为服装品牌和零售商家赋能，</div>
              <div>无需高成本拍摄即可轻松制作高品质模特展示图</div>
            </div>
            <div className="btn-box">
              <span className="try-btn" onClick={this.onTryBtnClick}>
                开始尝试<img className="right-icon" src={require('../../assets/images/home/icon-right.svg')} />
              </span>
            </div>
          </div>
        </div>

        {/*配置*/}
        <div className="config-container">
          <img className="config-bg-left" src={require('../../assets/images/home/config-bg-left.png')} />
          <img className="config-bg-right" src={require('../../assets/images/home/config-bg-right.png')} />

          <div id="config_main_box" className="config-main-box">
            <div className="config-title"><span id="config_title_txt" className="config-title-txt">来，我们先看看效果</span></div>
            <div className="config-desc"><span id="config_desc_txt" className="config-desc-txt">上传服饰图片，MixModel立即为您生成不同模特、多种场景下的展示效果图</span></div>
            <div className="config-body">
              <span className="head-img-list-box">
                {
                  (CONFIG_MAP || []).map((item, index) => (
                    <span key={index} id={`head_img_item_box_${index}`} className={`head-img-item-box ${index===configImgIndex && "head-img-item-selected-box"}`} onClick={() => this.onConfigHeadChange(index)}>
                      <img id={`head_img_${index}`} className="head-img" src={item.iconImg} />
                      <span id={`head_img_item_selected_img_${index}`} className={`head-img-item-selected-img`}></span>
                      <img id={`head_img_item_tick_${index}`} className="tick-icon" src={require('../../assets/images/home/config-head-selected-icon.svg')} />
                    </span>
                  ))
                }
              </span>
              <span id="config_img_box"  className="config-img-box">
                <img id="config_img" className="config-img" src={this.getConfigData(configImgIndex, configBgId).img} />
              </span>

              <span className="config-bg-box">
                <span className="config-bg-row config-bg-row-left">
                  {
                    [...CONFIG_BG_LIST].slice(0, 4).map((item, index) => <div
                      className={`config-bg-item ${configBgId === item.bgId && "config-bg-item-selected"}`}
                      onClick={() => this.onConfigBgChange(item.bgId)}
                    >
                      <span id={`config_bg_item_${index}`} className="config-bg-item-in-box">
                        <img className="img" src={this.getConfigBgData(item.bgId)} />
                        <span className="name">{item.name}</span>
                      </span>
                    </div>)
                  }
                </span>
                <span className="config-bg-row config-bg-row-right">
                  {
                    [...CONFIG_BG_LIST].slice(4, 8).map((item, index) => <div
                      className={`config-bg-item ${configBgId === item.bgId && "config-bg-item-selected"}`}
                      onClick={() => this.onConfigBgChange(item.bgId)}
                    >
                      <span id={`config_bg_item_${index+4}`} className="config-bg-item-in-box">
                        <img className="img" src={this.getConfigBgData(item.bgId)} />
                        <span className="name">{item.name}</span>
                      </span>
                    </div>)
                  }
                </span>
              </span>
            </div>
          </div>
        </div>

        {/*数据*/}
        <div className="data-container">
          <span className="data-bg-left">
            <img className="data-bg-light data-bg-light-1" src={require('../../assets/images/home/data-bg-light.svg')} />
            <img className="data-bg-light data-bg-light-2" src={require('../../assets/images/home/data-bg-light.svg')} />
          </span>
          <div className="data-body">
            <div className="data-bg-right">
              <img className="data-bg-light data-bg-light-1" src={require('../../assets/images/home/data-bg-light.svg')} />
              <img className="data-bg-light data-bg-light-2" src={require('../../assets/images/home/data-bg-light.svg')} />
              <img className="data-bg-light data-bg-light-3" src={require('../../assets/images/home/data-bg-light.svg')} />
              <img className="data-bg-light data-bg-light-4" src={require('../../assets/images/home/data-bg-light.svg')} />
              <img className="data-bg-light data-bg-light-5" src={require('../../assets/images/home/data-bg-light.svg')} />
              <img className="data-bg-light data-bg-light-6" src={require('../../assets/images/home/data-bg-light.svg')} />
              <img className="data-bg-light data-bg-light-7" src={require('../../assets/images/home/data-bg-light.svg')} />
              <img className="data-bg-light data-bg-light-8" src={require('../../assets/images/home/data-bg-light.svg')} />
              <img className="data-bg-light data-bg-light-9" src={require('../../assets/images/home/data-bg-light.svg')} />
              <img className="data-bg-light data-bg-light-10" src={require('../../assets/images/home/data-bg-light.svg')} />
              <img className="data-bg-light data-bg-light-11" src={require('../../assets/images/home/data-bg-light.svg')} />
            </div>
            <div className="data-title"><span id="data_title_txt" className="data-title-txt">服装拍摄全新生产力</span></div>
            <div className="data-desc"><span id="data_desc_txt" className="data-desc-txt">从摄影师、模特、后期人工修图，进化为 AI 一键生成</span></div>
            <div className="data-box">
              <span id="data_item_value_box_1" className="data-item">
                <div className="data-item-value-box">
                  <div className="data-item-value">
                    {dataList[0].current}<span className="unit">%</span>
                  </div>
                  <img id="arrow_icon_1" className="data-arrow-icon" src={require('../../assets/images/home/icon-data-arrow-down.svg')} />
                </div>
                <div className="data-item-desc">降低拍摄成本</div>
              </span>
              <span id="data_item_value_box_2" className="data-item">
                <div className="data-item-value-box">
                  <div className="data-item-value">{dataList[1].current}<span className="unit">倍</span></div>
                  <img id="arrow_icon_2" className="data-arrow-icon" src={require('../../assets/images/home/icon-data-arrow-up.svg')} />
                </div>
                <div className="data-item-desc">提升出图效率</div>
              </span>
              <span id="data_item_value_box_3" className="data-item">
                <div className="data-item-value-box">
                  <div className="data-item-value">{dataList[2].current}<span className="unit">%</span></div>
                  <img id="arrow_icon_3" className="data-arrow-icon" src={require('../../assets/images/home/icon-data-arrow-up.svg')} />
                </div>
                <div className="data-item-desc">提升图片质量</div>
              </span>
              <span id="data_item_value_box_4" className="data-item">
                <div className="data-item-value-box">
                  <div className="data-item-value">{dataList[3].current}<span className="unit">%</span></div>
                  <img id="arrow_icon_4" className="data-arrow-icon" src={require('../../assets/images/home/icon-data-arrow-up.svg')} />
                </div>
                <div className="data-item-desc">提升产品浏览</div>
              </span>
            </div>
          </div>
        </div>

        {/*图片生成类型*/}
        <div className="product-type-container">
          <div id="product_type_body" className="product-type-body">
            <span
              className={`type-box left-box ${productImageType === 1 ? "big" : "small"}`}
              onMouseEnter={() => this.onProductImageEnter(1)}
              onMouseLeave={() => this.onProductImageLeave(1)}
            >
              {/*背景图*/}
              <img className="left-item type-item-big-bg" src={require('../../assets/images/home/product-type-1-big-bg.png')} />
              <img className="left-item type-item-small-bg" src={require('../../assets/images/home/product-type-1-small-bg.png')} />

              {/*模特图*/}
              <img className="init-modal" src={require('../../assets/images/home/product-type-1-init-modal.png')} />
              <span className="gray-mask"></span>
              <img className="new-modal" src={require('../../assets/images/home/product-type-1-new-modal.png')} />
              <span className="marker-line-box marker-line-box-1">
                <img className="marker-line" src={require('../../assets/images/home/product-type-line-1.svg')} />
              </span>

              <span id="small_info_box_1" className="small-info-box">
                <div id="small_info_top_box_1" className="small-info-top-box">
                  <div className="title">
                    真人图美化
                  </div>
                  <span id="small_info_top_line_1" className="title-line"></span>
                </div>
                {/*<span id="small_info_btn_1" className="go-product-btn" onClick={() => this.onProductClick(1)}>*/}
                {/*  <span className="go-product-btn-txt">去生成</span>*/}
                {/*  <img className="right-icon" src={require('../../assets/images/home/icon-right.svg')} />*/}
                {/*</span>*/}
              </span>

              <span id="big_info_box_1" className="big-info-box">
                <div className="big-info-top-box">
                  <div id="big_info_title_1" className="title">
                    真人图美化
                    <span id="big_info_top_line_1" className="title-line"></span>
                  </div>
                  <div id="big_info_desc_1" className="desc">不再受职业模特的时间档期、⾼昂费⽤约束 , 有了 MixModel，大众脸也能试⾐, ⼀键切换模特类型和拍摄场景</div>
                </div>
                <div className="big-info-bottom-box">
                  <span id="big_info_btn_1" className="go-product-btn" onClick={() => this.onProductClick(1)}>去生成<img className="right-icon" src={require('../../assets/images/home/icon-right.svg')} /></span>
                </div>
              </span>
            </span>
            <span
              className={`type-box right-box ${productImageType === 2 ? "big" : "small"}`}
              onMouseEnter={() => this.onProductImageEnter(2)}
              onMouseLeave={() => this.onProductImageLeave(2)}
            >
              {/*背景图*/}
              <img className="right-item type-item-big-bg" src={require('../../assets/images/home/product-type-2-big-bg.png')} />
              <img className="right-item type-item-small-bg" src={require('../../assets/images/home/product-type-2-small-bg.png')} />

              {/*模特图*/}
              <img className="init-modal" src={require('../../assets/images/home/product-type-2-init-modal.png')} />
              <span className="gray-mask"></span>
              <img className="new-modal" src={require('../../assets/images/home/product-type-2-new-modal.png')} />
              <span className="marker-line-box marker-line-box-2">
                <img className="marker-line" src={require('../../assets/images/home/product-type-line-2.svg')} />
              </span>

              <span id="small_info_box_2"  className="small-info-box">
                <div id="small_info_top_box_2" className="small-info-top-box">
                  <div className="title">
                    人台变真人
                  </div>
                  <span id="small_info_top_line_2" className="title-line"></span>
                </div>
                {/*<span id="small_info_btn_2" className="go-product-btn" onClick={() => this.onProductClick(1)}>*/}
                {/*  <span className="go-product-btn-txt">去生成</span>*/}
                {/*  <img className="right-icon" src={require('../../assets/images/home/icon-right.svg')} />*/}
                {/*</span>*/}
              </span>

              <span id="big_info_box_2" className="big-info-box">
                <div className="big-info-top-box">
                  <div id="big_info_title_2" className="title">
                    人台变真人
                    <span id="big_info_top_line_2" className="title-line"></span>
                  </div>
                  <div id="big_info_desc_2" className="desc">假人模特秒变真人，仅需将服装穿上⼈台模特 ， 即可⽣成超写实模特展示图 ， 可与真⼈模特媲美</div>
                </div>
                <div className="big-info-bottom-box">
                  <span id="big_info_btn_2" className="go-product-btn" onClick={() => this.onProductClick(2)}>去生成<img className="right-icon" src={require('../../assets/images/home/icon-right.svg')} /></span>
                </div>
              </span>
            </span>
          </div>
        </div>

        {/*步骤*/}
        <div className="step-container">
          <div className="step-title"><span id="step_title_txt" className="step-title-txt">轻松4步，生成模特展示图</span></div>
          <div id="step_body" className="step-body">
            <span className="step-movie-box">
              <video id="step_video" width="804" height="494" autoPlay="autoplay" muted>
                <source src={currentStepItem.video} type="video/mp4" />
                您的浏览器不支持播放视频文件
              </video>
            </span>
            <span className="step-item-box">
              <div className="step-en-box">
                <span id="step_en" className="step-en">{currentStepItem.stepEn}</span>
              </div>
              <div id="step_num" className="step-num">{currentStepItem.step}</div>
              <span className="step-item-list">
                {
                  STEP_LIST.map(item => <div
                    className={`step-item ${item.step === currentStepItem.step && "step-item-selected"}`}
                    onClick={() => this.onStepItemClick(item)}
                    // onMouseOver={() => this.onStepItemOver()}
                    // onMouseOut={() => this.onStepItemOut()}
                  >
                    <span className="step-item-cn">
                      {item.name}<img className="step-item-icon" src={item.step === currentStepItem.step ? item.iconSelected : item.icon} />
                    </span>
                    <span className="step-item-en">{item.enName}</span>
                  </div>)
                }
              </span>
            </span>
          </div>
        </div>

        {/*优势*/}
        <div className="advantage-container">
          {/*<img id="advantage_bg_right" className="advantage-bg-right" src={require('../../assets/images/home/advantage-bg-right.png')} />*/}
          <div className="advantage-box">
            <div className="advantage-title"><span id="advantage_title_txt" className="advantage-title-txt">AI 技术赋予我们领先优势</span></div>
            <div id="advantage_body" className="advantage-body">
              <div className="advantage-bg"></div>
              <div className="advantage-bg-white"></div>
              {/*<img className="advantage-bg-left" src={require('../../assets/images/home/advantage-bg-left.png')} />*/}

              <div className="advantage-selected-box">
                <span id="advantage_selected_desc" className="advantage-selected-desc">
                  <div className="advantage-desc-title">
                    {currentAdvantage.name}
                    <span id="advantage_desc_title_line" className="advantage-desc-title-line"></span>
                  </div>
                  <div className="advantage-desc-info">{currentAdvantage.desc}</div>
                </span>
              </div>

              <div id="advantage_item_box" className="advantage-item-box">
                {
                  ADVANTAGE_LIST.map((item, index) => <span id={`advantage_item_${item.id}`} className={`advantage-item advantage-item-${item.id}`}>
                    <img id={`advantage_img_${item.id}`} className="advantage-img" src={item.img} />
                    <div id={`advantage_name_${item.id}`} className="advantage-name">{item.name}</div>
                  </span>)
                }
              </div>

              <div className="advantage-btn-box">
                <span className="page-btn left-btn" onClick={() => this.advantagePageChange(true)}></span>
                <span className="page-btn right-btn" onClick={() => this.advantagePageChange(false)}></span>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  }
};

// 将action注入到页面组件
const matDispatchToProps = {

};

// 将store注入到页面组件
function mapStateToProps(state) {
  return {
  }
}
const HomePage = Form.create({name: 'home_page'})(Home);
export default withRouter(connect(mapStateToProps, matDispatchToProps)(HomePage));
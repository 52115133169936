import Immutable from 'immutable';
import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import promiseMiddleware from 'redux-promise'

import rootReducer from './reducers';

const middlewares = [];

middlewares.push(thunkMiddleware, promiseMiddleware);

if (process.env.NODE_ENV === `development`) {
    const { createLogger } = require(`redux-logger`);
    const immutableTransfer = (state) => {
        if (Immutable.isImmutable(state)) {
            return state.toJS();
        } else {
            return state;
        }
    };
    const logger = createLogger({
        stateTransformer: immutableTransfer,
        actionTransformer: immutableTransfer,
        immutableTransfer: immutableTransfer,
    });

    middlewares.push(logger);
}

const store = compose(applyMiddleware(...middlewares), window.devToolsExtension ? window.devToolsExtension() : (f) => f)(createStore)(rootReducer);

export default store;

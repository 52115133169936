import React, {useState, useEffect} from "react";
import {withRouter} from "react-router-dom";
import './index.less';
import API from "../../apis";
import Payment from "./payment";
import {useSelector, useDispatch} from 'react-redux';
import LoginRegister from "../../components/login-register";

/**
 * 充值购买
 */
const Deposit = ({}) => {
  document.title = '定价 -  MixModel.cn | AI模特，服装拍摄全新生产力';
  // 问题列表
  const PROBLEM_LIST = [
    {
      problem: '什么是M豆',
      explain: 'M豆是 MixModel 网站内的虚拟货币，使用 M豆可以购买网站内的各项服务，比如用于生成 AI 模特图。'
    },
    {
      problem: '我可以免费试用吗？',
      explain: `目前注册即送{1}M豆，且新用户会有￥{2}的超值套餐。`
    },
    {
      problem: '我可以重复购买吗？',
      explain: '可以重复购买，目前每次购买的M豆都会有指定的有效期，可在个人中心查看M豆的使用情况。'
    },
    {
      problem: '同时购买了多个M豆套餐，扣除M豆的顺序是？',
      explain: '系统会优先扣除距离有效期结束最近的M豆，在该M豆消耗完之后，再扣除距离有效期结束较远的M豆。'
    },
  ];

  const dispatch = useDispatch();
  // 选中的充值套餐
  const [depositIndex, setDepositIndex] = useState(0)
  // 选中的问题index
  const [problemIndexs, setProblemIndexs] = useState([]);
  // 套餐列表
  const [coinPackageList, setCoinPackageList] = useState(0);
  // 弹出支付
  const [paymentOpen, setPaymentOpen] = useState(false)
  // 登录弹窗
  const [isLoginRegisterOpen, setIsLoginRegisterOpen] = useState(false);
  // 公共配置
  const [config, setConfig] = useState(null);
  // 用户信息
  const { memberInfo } = useSelector(state => {
    return {
      memberInfo: state.getIn(['member', 'memberInfo']),
    }
  });

  useEffect(() => {
    // getCoinPackage();
    getConfig();
    getAccess();
  },[])

  useEffect(() => {
    getCoinPackage();
  },[memberInfo])

  // 记录页面访问接口
  const getAccess = async () => {
    const params = {
      page: 2 // 定价页面
    };
    const res = await API.access(params);
    if (res.code === 0) {

    }
  }

  // 公共配置
  const getConfig = async () => {
    const params = {};
    const res = await API.getConfig(params);
    if (res.code === 0) {
      setConfig(res.data);
    }
  }

  // 获取套餐列表
  const getCoinPackage = async () => {
    const params = {};
    const res = await API.getCoinPackage(params);
    if (res.code === 0) {
      setCoinPackageList(res.data);
    }
  }

  // 购买点击
  const onBuyClick = (index) => {
    if (!memberInfo) {
      setIsLoginRegisterOpen(true);
      return;
    }
    setPaymentOpen(true)
  }

  const onProblemItemClick = (index) => {
    let i = null;
    let indexs = [...problemIndexs];
    indexs.map((item, num) => {
      if (item === index) {
        i = num;
      }
    })
    if (i !== null) {
      indexs.splice(i, 1);
    } else {
      indexs.push(index);
    }
    setProblemIndexs(indexs);

  }

  const depositMouseOver = (index) => {
    setDepositIndex(index);
    // const itemElm = document.getElementById(`hover_border_${index}`);
    // itemElm.style.setProperty('--borderAnimName', `borderAnimationOver`);

  }

  const depositMouseOut = (index) => {
    // const itemElm = document.getElementById(`hover_border_${index}`);
    // itemElm.style.setProperty('--borderAnimName', `borderAnimationOut`);
  }

  return (<div className="deposit-page">
    <div className={`deposit-top-body`}>
      <img className="left-bg" src={require('../../assets/images/deposit/deposit-bg-left.png')}/>
      <img className="right-bg" src={require('../../assets/images/deposit/deposit-bg-right.png')}/>
      <div className="deposit-top-title">
        体验服装拍摄全新生产力&nbsp;
        <img className="flower-icon" src={require('../../assets/images/deposit/icon-flower.svg')}/>
      </div>
      <div className="deposit-top-desc">无须高成本拍摄即可轻松制作高品质的模特展示图</div>
      <div className="deposit-item-box">
        {
          (coinPackageList || []).map((item, index) => <span
            className={`deposit-item ${item.sub_type===1 && "new-member"}`}
            // onClick={() => setDepositIndex(index)}
            onMouseOver={() => depositMouseOver(index)}
            onMouseOut={() => depositMouseOut(index)}
          >
            {/*<div id={`hover_border_${index}`} className={`hover-border ${item.sub_type===1 && "new-member"}`}></div>*/}
            <div className="deposit-item-top">
              {
                // 新用户专享
                item.sub_type===1 && <div className="new-tag">{item.tag}</div>
              }
              {
                // 最受欢迎
                item.sub_type===0 && item.tag && <div className="hot-tag">{item.tag}</div>
              }
              <div className="data-box">
                <span className="value-box">
                  <img className="money-icon" src={require('../../assets/images/common/icon-money-32x4.png')}/>
                  <span className="value">{item.coin}</span>
                </span>
                <span className="money-box"><span className="unit">￥</span>{item.money}</span>
              </div>
              <div className="desc-box"><span className="dot"></span>预计可生成 {item.pre_times} 次，约 {item.pre_images} 张图片</div>
            </div>
            <div className="deposit-item-bottom">
              <div className="buy-btn" onClick={() => onBuyClick(index)}>立即购买</div>
              <div className="row-box">
                <span className="row-name">M豆</span>
                <span className="row-value-box">
                  <img className="money-icon" src={require('../../assets/images/common/icon-money-20x4.png')}/>
                  <span>{item.coin}</span>
                </span>
              </div>
              <div className="row-box">
                <span className="row-name">有效期</span>
                <span className="row-value-box">
                  <span>{item.expire_date} 天</span>
                </span>
              </div>
              <div className="row-box">
                <span className="row-name">M豆单价</span>
                <span className="row-value-box">
                  <span>￥{Math.floor(item.money*100 / item.coin) / 100}/M豆</span>
                </span>
              </div>
            </div>
          </span>)
        }
      </div>


    </div>
    <div className="common-problem-box">
      <div className="title">常见问题</div>
      <div className="problem-list-box">
        {
          PROBLEM_LIST.map((item, index) => <div className="problem-item" onClick={() => onProblemItemClick(index)}>
            <div className="problem">
              {
                problemIndexs.includes(index)
                  ? <img className="money-icon" src={require('../../assets/images/deposit/icon-problem-selected.svg')}/>
                  : <img className="money-icon" src={require('../../assets/images/deposit/icon-problem.svg')}/>
              }
              {item.problem}
              {/*箭头*/}
              <span className={`arrow-icon ${problemIndexs.includes(index) ? 'open' : 'close'}`}></span>
            </div>
            <div className={`explain-box ${problemIndexs.includes(index) ? 'show' : 'hide'}`}>
              <div className={`explain `}>{item.explain.replace('{1}', config?.give_coin).replace('{2}', config?.reg_package_money)}</div>
            </div>
          </div>)
        }
      </div>
    </div>

    {
      paymentOpen && <Payment isOpen={paymentOpen} onClose={() => setPaymentOpen(false)} payInfo={coinPackageList[depositIndex]}/>
    }
    {/*登录注册*/}
    {
      isLoginRegisterOpen && <LoginRegister type={"login"} isOpen={isLoginRegisterOpen} onClose={() => setIsLoginRegisterOpen(false)}/>
    }
  </div>)
};

export default withRouter(Deposit);

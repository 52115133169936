// actions.js
import API from "../../apis";

// 获取账号信息
export const getMemberInfo = async () => {
  const params = {};
  const res = await API.getMemberInfo(params);
  if (res.code === 0) {
    return {
      type: 'MEMBER_INFO',
      payload: res.data
    };
  } else {
    return {error: res};
  }
};

// 公共配置
export const getConfigInfo = async () => {
  const params = {};
  const res = await API.getConfig(params);
  if (res.code === 0) {
    return {
      type: 'COMMON_CONFIG',
      payload: res.data
    };
  } else {
    return {error: res};
  }
};

// 退出登录
export const logout = async () => {
  const params = {};
  const res = await API.logout(params);
  if (res.code === 0) {
    window.localStorage.removeItem('token');
    return {
      type: 'MEMBER_INFO',
      payload: null
    };
  }
}

// 退出登录
export const showToast = (data) => {
  // console.log('action>>>>>>>>>>>>', data);
  return {
    type: 'SHOW_TOAST',
    payload: {...data, key: Date.now()}
  };
}


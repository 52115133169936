import {message} from 'antd'
import axios from 'axios';
import configs from '../configs.js';
import {logout, showToast} from "../store/actions/ai";
import store from '../store/index.js'

const apiInstance = axios.create({
  baseURL: configs.baseUrl,
  timeout: 60000,
  withCredentials:true,
  headers: {
    'Content-Type': 'application/json'
  }
});

/**
 * 异步请求的封装
 * @param url
 * @param options
 * @return {Promise<*>}
 */
export default async function (url, options) {
  // const dispatch = useDispatch();
  let token = token =localStorage.getItem('token');;
  const settings = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'token': token,
      // 推广渠道
      'channel': localStorage.getItem('channel') || ''
    },
    params: {},
    data: {},
    // 是否默认显示失败后的提示
    showErrorTip: true,
    ...(options || {}),
  };

  // let token = '';
  try {
    // token =localStorage.getItem('token');

    if (token) {
      if (settings.method === 'GET') {
        Object.assign(settings.params, {token})
      } else if (settings.method === 'POST') {
        Object.assign(settings.data, {token})
      }
    }
    // else if (!/.*login.*/.test(url)) {
    //    /*window.location.href = '/login';
    //    return;*/
    // }
  } catch (e) {
  }

  const response = await apiInstance.request({
    url,
    ...settings,
  });
  switch (response.status) {
    case 200:
      if (response.data.code === 1101) {
        // Token无效或已过期
        window.localStorage.removeItem('token');
        // try {
        //   store.dispatch(showToast({type: 'error', msg: response.data.msg}));
        // } catch (e) {
        //
        // }
        // return;
      } else if (response.data.code === 1301) {
        // 您的账号异常，已被限制登录，如有疑问请联系客服
        window.localStorage.removeItem('token');
        store.dispatch(showToast({type: 'error', msg: response.data.msg}));
        // return;
      } else if (response.data.code === 99999) {
        // 普通错误码信息
        store.dispatch(showToast({type: 'error', msg: response.data.msg}));
        return;
      }
      return response.data;
    case 500:
      console.error("[请求错误]:", response.statusText);
      // dispatch(showToast({type: 'error', msg: response.statusText}));
      return null;
    default:
      return null;
  }
}

import React, {useState, useEffect} from "react";
import {Link, withRouter} from "react-router-dom";
import './index.less';

const Privacy = () => {

  useEffect(() => {
    document.title = '隐私政策 - MixModel.cn | AI模特，服装拍摄全新生产力';
  }, [])

  return (<div className={`privacy-page`}>
    <div className="title">MixModel 隐私政策</div>
    <div className="time" style={{marginTop: '32px'}}>更新时间：2023-06-14</div>
    <div className="time">生效时间：2023-06-14</div>

    <div className="block-container">
      <div className="paragraph">
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;广州茉米智能科技有限公司及其关联方（以下简称“我们”或“MixModel”）作为MixModel产品及服务的运营者，深知个人信息对你的重要性，我们将按照法律法规的规定，保护你的个人信息及隐私安全。我们制定本隐私政策并希望提示你了解下述内容，以便作出适当选择。</div>
      </div>
    </div>
    <div className="block-container">
      <div className="paragraph">
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;您在使用MixModel的过程中，我们会请求获取您的<span className="bold">手机号码、相机、相册、麦克风及设备信息（设备信息包括设备相关信息及设备位置信息），具体搜集的信息及目的详见本政策正文。</span></div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;希望通过本政策帮助您了解以下内容：</div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">一、我们如何收集和使用您的个人信息</span></div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">二、我们如何使用Cookie和同类技术</span></div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">三、我们如何共享、转让、公开披露您的个人信息</span></div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">四、我们如何保护您的个人信息</span></div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">五、您如何管理您的个人信息</span></div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">六、我们如何处理未成年人的个人信息</span></div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">七、您的个人信息如何在全球范围转移</span></div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">八、隐私政策的修订和通知</span></div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">九、您如何联系我们</span></div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">十、其他</span></div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们深知个人信息对您的重要性，并会尽全力保护您的个人信息安全。<span className="bold">我们在本隐私政策中所提及的收集、使用、管理、共享及转让的个人信息包括加粗及带有下划线标识的个人敏感信息以及其他的个人信息。</span>我们致力于维持您对我们的信任，并恪守以下原则：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。我们承诺将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。<span className="bold">希望您在使用我们的服务前仔细阅读并确认您已经充分理解本政策所写明的内容，您点击同意后即视为您接受本政策的内容，并且同意授权我们按照本隐私政策收集、使用、保存和共享您的相关信息。</span></div>
      </div>
    </div>

    <div className="block-container">
      <div className="sub-title">一、我们如何收集和使用您的个人信息</div>
      <div className="paragraph">
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们会出于如下目的收集和使用您的个人信息：</div>
        <div className="sub-title-2">（一）用户注册和登录功能</div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;您使用MixModel平台和服务时，我们建议您注册一个MixModel的账号，在此过程中，您需要向我们提供您本人的<span className="bold">手机号码</span>并创建账户名、密码，以更加充分地使用MixModel的全部功能。如果您仅需浏览、搜索等基本服务，您无需注册MixModel账号并提供上述信息。</div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;如果您提供以下额外资料补全账号信息，将有助于我们给您提供更个性化的产品或服务：生日、行业类目、从事职业。上述信息的提供仅会影响您使用个性化的产品或服务，不会影响您使用MixModel基本浏览、搜索、作图、订购套餐等功能。</div>
        <div className="sub-title-2">（二）生成图片和使用</div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1、<span className="bold">上传内容：</span>我们会存储您上传的图片、视频以及您输入的文字信息，以便您再次使用，除此之外，我们不会用于其他任何途径。</div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2、<span className="bold">生成图片：</span>我们会存储您制作的最终生图成品，以便您查看，除此之外，我们不会用于其他任何用途。</div>
        <div className="sub-title-2">（三）购买产品及服务</div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;您购买MixModel套餐或MixModel提供的产品与服务时，我们会通过系统为您生成购买订单，订单中载明您所购买的<span className="bold">订单号、产品或服务信息、收付款账号信息（支付宝账号信息、微信账号信息或银行卡账号信息）、您应支付的金额及购买时间</span>，我们收集这些必要信息是为了帮助您顺利完成交易、保障您的交易安全、方便您查询订单信息。</div>
        <div className="sub-title-2">（四）图片生成记录</div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;为了向您提供账号的生成图片记录，我们会搜集您的<span className="bold">上传图片记录、生成图片点击记录、下载图片记录。</span></div>
        <div className="sub-title-2">（五）安全运行</div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;为提高您使用MixModel平台提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规和MixModel相关协议的情况，我们可能使用或整合您的<span className="bold">设备信息、日志信息</span>等，来综合判断您账号及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。</div>
        <div>1、<span className="bold">设备信息</span>：我们会根据您在平台安装及使用中授予的具体权限，接收并记录您所使用的<span className="bold">设备相关信息（例如设备型号、设备设置、唯一设备标识符等软硬件特征信息）、设备所在位置相关信息（例如IP地址）。</span></div>
        <div>2、<span className="bold">日志信息：</span>当您使用MixModel提供的产品或服务时，我们会自动收集您对我们服务的详细使用情况，作为有关网络日志保存，<span className="bold">例如您的搜索查询内容、您对模板的编辑时间、下载时间、生成的结果图等。</span></div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">请注意，单独的设备信息、日志信息是无法识别特定自然人身份的信息。</span>如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息。</div>
        <div className="sub-title-2">（六）改进我们的平台与/或服务所必须的功能</div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;为了使我们提供的服务更能符合您的预期和要求，改进我们的服务质量，我们会记录<span className="bold">您与客服联系时您提供的相关信息</span>，您参与问卷调查时向我们发送的<span className="bold">问卷答复</span>，以便向您反馈。</div>
        <div className="sub-title-2">（七）使用规则</div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们收集您的个人信息后，将严格按照以下规则使用：</div>
        <div>1、我们会严格遵照法律法规及本隐私政策使用您的个人信息。</div>
        <div>2、我们将依法用技术手段对您的个人信息加密、去标识化或匿名化处理。</div>
        <div>3、在您注销账号时，我们将对您的个人信息进行删除或匿名化处理。</div>
        <div>4、当我们需要将您的个人信息用于本政策未载明的其它用途时，我们会再次征求您的同意。</div>
        <div className="sub-title-2">（八）征得授权同意的例外</div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">根据相关法律法规规定，以下情形中收集您的个人信息无需征得您的授权同意：</span></div>
        <div>1、与国家安全、国防安全有关的；</div>
        <div>2、与公共安全、公共卫生、重大公共利益有关的；</div>
        <div>3、与犯罪侦查、起诉、审判和判决执行等有关的；</div>
        <div>4、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；</div>
        <div>5、所收集的个人信息是您自行向社会公众公开的；</div>
        <div>6、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</div>
        <div>7、根据您的要求签订合同所必需的；</div>
        <div>8、用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；</div>
        <div>9、为合法的新闻报道所必需的；</div>
        <div>10、学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</div>
        <div>11、法律法规规定的其他情形。</div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;请您理解，我们向您提供的功能和服务是不断更新和发展的，<span className="bold">如果某一功能或服务未在前述说明中且收集了您的信息，我们会通过页面提示、交互流程、公告等方式另行向您说明信息收集的内容、范围和目的，以征得您的同意。</span>如我们停止运营MixModel平台或服务，我们将及时停止继续收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，对所持有的个人信息进行删除或匿名化处理。</div>
      </div>
    </div>

    <div className="block-container">
      <div className="sub-title">二、我们如何使用Cookie和同类技术</div>
      <div className="paragraph">
        <div className="sub-title-2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;为保证平台正常运行，我们会在您的计算机或移动设备上存储名为Cookie的小数据文件。Cookie通常包含标识符、站点名称以及一些号码及字符。借助于Cookie，我们能够存储您的偏好等数据，以此帮助您免去重复填写个人信息、输入历史搜索内容等繁琐动作；同时我们还可能利用上述技术为您推荐、展示、推送您感兴趣的内容。您可根据自己的偏好管理或删除Cookie，如通过浏览器设置选项清除计算机上保存的所有Cookie，但如果您这么做，则需要在每一次访问MixModel时亲自更改用户设置，而且您之前所记录的相应信息也均会被删除，并且可能会对您所使用服务的安全性有一定影响。</div>
      </div>
    </div>
    <div className="block-container">

      <div className="sub-title">三、我们如何共享、转让、公开披露您的个人信息</div>
      <div className="paragraph">
        <div className="sub-title-2">（一）共享</div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们不会向MixModel平台服务提供者以外的公司、组织和个人共享您的个人信息，但以下情况除外：</div>
        <div>1、在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。</div>
        <div>2、在法定情形下的共享：我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。</div>
        <div>3、MixModel移动端、网页端、PAD端账号互通，因此当您使用MixModel移动端账号登录网页端、小程序端等我们与我们关联公司运营的其他平台，为方便您使用，我们会将<span className="bold">本政策第一条中搜集的所有信息与前述产品或服务共享，如您的昵称、头像、图片生成记录、收藏记录等。</span></div>
        <div>4、与授权合作伙伴共享：</div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们可能委托下述类型的授权合作伙伴为您提供相关服务或代表我们履行职能，我们仅会出于本隐私权政策声明的合法、正当、必要、特定、明确的目的共享您的信息，授权合作伙伴只能接触到其履行职责所需信息，且不得将此信息用于其他任何目的。</div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;目前，我们的授权合作伙伴包括以下类型及授权目的主要如下：</div>
        <div>（1）特定功能。当软件服务提供商、智能设备提供商或系统服务提供商与我们联合为你提供服务时，例如认证服务、短信服务等，为实现这一功能，我们可能会收集你的<span className="bold">相关设备信息（例如硬件型号、操作系统版本号、国际移动设备身份识别码（IMEI）、网络设备硬件地址（MAC））经过去标识化后并提供给前述提供商。</span></div>
        <div>（2）<span className="bold">广告推送</span>。我们可能与委托我们进行推广和广告投放的合作伙伴共享信息，但我们不会分享用于识别你个人身份的信息，例如你的姓名、手机号等，仅会向这些合作伙伴提供<span className="bold">难以识别你个人身份的用户画像标签及去标识化或匿名化后的统计类信息</span>，以帮助其在不识别你个人身份的前提下提升广告有效触达率。</div>
        <div>（3）<span className="bold">产品分析</span>。为分析我们服务的使用情况，提升用户使用的体验，可能会与第三方共享产品使用情况（崩溃、闪退）的<span className="bold">统计性数据</span>，这些数据难以与其他信息结合识别您的个人身份。</div>
        <div className="sub-title-2">（二）转让</div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们将不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</div>
        <div>1、在明确获得您的同意的前提下，我们会向其他方转让您的个人信息；</div>
        <div>2、在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续接受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。</div>
        <div className="sub-title-2">（三）公开披露</div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们仅会在以下情况下，公开披露您的个人信息：</div>
        <div>1、获得您明确的同意后；</div>
        <div>2、基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。</div>
        <div className="sub-title-2">（四）共享、转让、公开披露个人信息时事先征得授权同意的例外</div>
        <div className="sub-title-2"><span className="bold">以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：</span></div>
        <div> 1、与国家安全、国防安全有关的；</div>
        <div> 2、与公共安全、公共卫生、重大公共利益有关的；</div>
        <div> 3、与犯罪侦查、起诉、审判和判决执行等司法或行政执法有关的；</div>
        <div> 4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</div>
        <div> 5、您自行向社会公众公开的个人信息；</div>
        <div> 6、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</div>
      </div>
    </div>

    <div className="block-container">
      <div className="sub-title">四、我们如何保护您的个人信息</div>
      <div className="paragraph">
        <div>1、我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。例如，我们对MixModel平台提供HTTPS协议安全浏览方式；我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。</div>
        <div>2、我们会采取合理可行的措施，尽力避免收集无关的个人信息，且仅会在达成本政策所述目的所需的期限或法定期限内内保留您的个人信息，除非需要延长保留期或受到法律的允许。</div>
        <div>3、我们仅允许有必要知晓这些信息的员工、合作伙伴访问个人信息，并为此设置了严格的访问权限控制、权限审批流程和监控机制。我们同时要求可能接触到您个人信息的所有人员履行相应的保密义务。如果第三方未履行保密义务，可能会被我们追究法律责任并被我们终止合作。</div>
        <div>4、互联网并非绝对安全的环境，而且电子邮件、即时通讯、及与其他用户的交流方式并未加密，我们强烈建议您不要通过此类方式发送个人信息。请使用复杂密码，协助我们保证您的账号安全。</div>
        <div>5、在不幸发生个人信息安全事件后，我们将按照法律法规的要求向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况我们将以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。</div>
      </div>
    </div>

    <div className="block-container">
      <div className="sub-title">五、您如何管理您的个人信息</div>
      <div className="paragraph">
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们非常重视您对您个人信息的管理，保护您对于您个人信息访问、更正以及撤回同意、删除的权利。您的权利包括：</div>
        <div className="sub-title-2">（一）访问、更正您的个人信息</div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;除法律法规另有规定除外，您可以在使用MixModel平台及其服务的过程中，访问、更正您提供的个人信息，具体路径为：网站首页——“头像”——“账号设置”。如果您无法实现或有任何疑问，可与我们联系以获得解决方案。</div>
        <div className="sub-title-2">（二）删除您的个人信息</div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;在以下情形中，您可以向我们提出删除个人信息的请求：</div>
        <div>1、如果我们处理个人信息的行为违反法律法规；</div>
        <div>2、如果我们收集、使用您的个人信息，却未征得您的同意；</div>
        <div>3、如果我们处理个人信息的行为违反了与您的约定；</div>
        <div>4、如果您注销了MixModel账户；</div>
        <div>5、如果我们终止服务及运营。</div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的第三方，要求其及时删除，除非法律法规另有规定，或这些第三方获得了您的授权。当您从我们的服务中删除信息后，我们可能不会立即在备份系统中删除相应的信息，但会在被备份更新时删除这些信息。</div>
        <div className="sub-title-2">（三）改变、撤销您授权同意的范围</div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;每个业务功能需要一些基本的个人信息才能得以完成，除此之外，如果您希望撤销我们额外收集、使用和披露的其他个人信息，您可以通过联系方式与我们联系以收回您的授权同意。</div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;当您收回同意后，我们将不再处理相应的个人信息。<span className="bold">但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。</span></div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;如您不想接受我们给您发送的商业广告，您可通过短信提示回复退订或我们提供的其他方式进行退订。</div>
        <div className="sub-title-2">（四）注销账户</div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;您可以根据自己的选择随时注销您的MixModel账户，您可以联系我们协助您注销账户。您注销账户后，我们将停止为您提供产品或服务，并依据您的要求，我们将删除您的个人信息，法律法规另有规定外。</div>
        <div className="sub-title-2">（五）响应您的上述请求</div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。</div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。</span></div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;在以下情形中，按照法律法规要求，我们将无法响应您的请求：</div>
        <div>1、与个人信息控制者履行法律法规规定的义务相关的;</div>
        <div>2、与国家安全、国防安全直接相关的;</div>
        <div>3、与公共安全、公共卫生、重大公共利益直接相关的;</div>
        <div>4、与刑事侦查、起诉、审判和执行判决等直接相关的;</div>
        <div>5、个人信息控制者有充分证据表明个人信息主体存在主观恶意或滥用权利的;</div>
        <div>6、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的;</div>
        <div>7、响应个人信息主体的请求将导致个人信息主体或其他个人、组织的合法权益受到严重损害的;</div>
        <div>8、涉及商业秘密的。</div>

      </div>
    </div>

    <div className="block-container">
      <div className="sub-title">六、我们如何处理未成年人的个人信息</div>
      <div className="paragraph">
        <div>1、我们的产品、网站和服务主要面向成人，如果没有父母或监护人的同意，未成年人不得创建自己的用户账号。<span className="bold">如您为未成年人的，建议您请您的父母或监护人仔细阅读本隐私政策，并在征得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息。</span></div>
        <div>2、对于经父母或监护人同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。</div>
        <div>3、如果我们发现自己在未事先获得可证实的服务同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关数据。</div>
      </div>
    </div>

    <div className="block-container">
      <div className="sub-title">七、您的个人信息如何在全球范围转移</div>
      <div className="paragraph">
        <div>1、原则上，我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。</div>
        <div>2、由于我们通过遍布全球的资源和服务器供产品或服务，这意味着，在获得您的授权同意后，您的个人信息可能会被转移到您使用产品或服务所在国家/地区的境外管辖区，或者受到来自这些管辖区的访问。</div>
        <div>3、此类管辖区可能设有不同的数据保护法，甚至未设立相关法律。在此类情况下，我们会确保您的个人信息得到在中华人民共和国境内足够同等的保护。例如，我们会请求您对跨境转移个人信息的同意，或者在跨境数据转移之前实施数据去标识化等安全举措。</div>
      </div>
    </div>

    <div className="block-container">
      <div className="sub-title">八、隐私政策的修订和通知</div>
      <div className="paragraph">
        <div><span className="bold">1、为给您提供更好的服务，MixModel及先关服务将不时更新与变化，我们会适时对本隐私政策进行修订，该等修订构成本隐私政策的一部分并具有等同于本隐私政策的效力。但未经你明确同意，我们不会削减你依据当前生效的本隐私政策所应享受的权利。</span></div>
        <div><span className="bold">2、本隐私政策更新后，我们会在MixModel移动客户端发出更新版本，并在更新后的条款生效前以通知、公告等适当方式提醒你更新的内容，以便你及时了解本隐私政策的最新版本。如你继续使用我们的服务，表示同意接受修订后的本政策的内容，但是如果更新的内容需要采集新的个人敏感信息，仍会再次以显著方式征求你的同意。</span>
        </div><div>3、对于重大变更，我们还会提供更为显著的通知（包括对于某些服务，我们会通过电子邮件发送通知，说明隐私政策的具体变更内容）：</div>
        <li>我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等;</li>
        <li>我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等;</li>
        <li>个人信息共享、转让或公开披露的主要对象发生变化;</li>
        <li>您参与个人信息处理方面的权利及其行使方式发生重大变化;</li>
        <li>我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时;</li>
        <li>个人信息安全影响评估报告表明存在高风险时。我们还会将本政策的旧版本存档，供您查阅。</li>
      </div>
    </div>

    <div className="block-container">
      <div className="sub-title">九、您如何联系我们</div>
      <div className="paragraph">
        <div>1、如果您对本政策或您在使用我们的服务过程中有任何疑问、意见或建议，您均可通过以下方式与我们取得联系：</div>
        <li>公司名称：广州茉米智能科技有限公司；</li>
        <li>注册地址：广州市天河区荷光路137号103房Z1171号；</li>
        <li>联系方式：MixModel隐私保护邮箱hi@mixmodel.ai。</li>
        <div>2、我们将尽快审核所涉问题，并在验证身份后及时处理，最长在不超过十五天或在法律法规规定期限内予以回复。</div>
      </div>
    </div>

    <div className="block-container">
      <div className="sub-title">十、其他</div>
      <div className="paragraph">
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;本隐私政策中的标题仅为方便及阅读而设，并不影响本隐私政策中任何规定的含义或解释。</div>
      </div>
    </div>
  </div>)
};

export default withRouter(Privacy);

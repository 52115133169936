import React, {useState, useEffect} from "react";
import {useSelector,useDispatch} from 'react-redux';
import { useHistory } from 'react-router-dom';
import {withRouter} from "react-router-dom";
import ModalCloseBtn from "../../../components/modal-close-btn";

import API from "../../../apis";

import './index.less';

/**
 * 图片生成方式
 */
const BuildTypeModel = ({isOpen, imageType, onClose}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // 生成图片条件
  const [config, setConfig] = useState(null);

  useEffect(() => {
    getBuildConfig();
  },[])

  // 生成图片条件
  const getBuildConfig = async () => {
    const params = {
      image_type: imageType
    };
    const res = await API.getBuildConfig(params);
    if (res.code === 0) {
      setConfig(res.data);
    }
  }

  const onTypeClick = (buildType) => {
    onModelClose(buildType);
  }

  const onModelClose = (buildType) => {
    // 快速生成按钮，不可用状态
    if (buildType === 1 && config?.quick_build.status === 0) {
      return;
    }
    if (onClose) {
      onClose(buildType);
    }
  }

  return (<div className={`build-type-model ${isOpen ? "show" : "hide"}`}>
    <div className={`build-type-container`}>
      <ModalCloseBtn className="close-btn" onClose={onModelClose} />
      <div className="body-box">
        <div className="title">选择您的生成方式</div>
        <div className="item-box">
          {/*预约生成*/}
          <span className="ai-btn time-btn" onClick={() => onTypeClick(2)}>
            <div className="ai-btn-name-box">
              <span className="ai-icon time-icon"></span>
              预约生成
            </div>
            <div className="ai-btn-time-box">{config?.order_build.wait_desc}</div>

            <span className="money-tag">
              <img className="money-icon" src={require('../../../assets/images/common/icon-money-gold.svg')} />
              {config?.order_build.cost}
            </span>

            <span className="discount-box">
              {config?.order_build.discount}折
            </span>
          </span>

          {/*快速生成*/}
          <span className={`ai-btn quick-btn ${config?.quick_build.status === 0 && "disabled"}`} onClick={() => onTypeClick(1)}>
            <div className="ai-btn-name-box">
              <span className="ai-icon quick-icon"></span>
              快速生成
            </div>
            <div className="ai-btn-time-box">{config?.quick_build.wait_desc}</div>

            <span className="money-tag">
              <img className="money-icon" src={require('../../../assets/images/common/icon-money-gold.svg')} />
              {config?.quick_build.cost}
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>)
};

export default withRouter(BuildTypeModel);

import React from "react";
import {withRouter} from "react-router-dom";

import './index.less';

const ModalCloseBtn = ({className, onClose}) => {

  const onCloseClick = () => {
    if (onClose) {
      onClose()
    }
  }

  return (<div className={`${className} modal-close-btn-component`}>
    <span className="btn-box" onClick={onCloseClick}></span>
  </div>)
};

export default withRouter(ModalCloseBtn);

import React, {useState, useEffect} from "react";
import {withRouter} from "react-router-dom";
import './index.less';
import API from "../../apis";
import {useSelector, useDispatch} from 'react-redux';
import {getQuery} from "../../utils/helpers";
import ImgPreview from "../../components/img-preview";

/**
 * AI模特库
 */
const ModelStash = ({}) => {

  const dispatch = useDispatch();
  // 图片id
  const [modelId, setModelId] = useState(null);
  // 模特详情
  const [modelInfo, setModelInfo] = useState(null);
  // // 显示的模特详情，翻页
  // const [showModelInfo, setShowModelInfo] = useState(null);
  // 预览图片信息
  const [previewData, setPreviewData] = useState(null);
  // 翻页记录
  const [typePage, setTypePage] = useState({
    1: 1,
    2: 1,
    3: 1
  });

  useEffect(() => {
    const query = getQuery();
    const mId = query.id ? query.id : null;
    setModelId(mId);

    if (mId) {
      getModelDetail(mId);
    }

  },[])

  useEffect(() => {
    if (modelInfo) {
      document.title = `${modelInfo.name} | AI 模特库 -  MixModel.cn | AI模特，服装拍摄全新生产力`;
    }
  },[modelInfo])

  // 模特库详情
  const getModelDetail = async (mId) => {
    const params = {
      model_id	: mId
    }
    const res = await API.getModelDetail(params);
    if (res.code === 0) {
      setModelInfo(res.data);

    }
  }

  // type: 图片类型；index：当前类型图片下标
  const onModelClick = (type, index) => {
    setPreviewData({
      show: true,
      ...getAllImageData(type, index)
    })
  }

  // 获取所有类型图片
  const getAllImageData = (type, index) => {
    let res = {
      initImgIndex: 0,
      imgList: []
    }
    let i = 0;
    modelInfo.images.map(imageInfo => {
      (imageInfo.list || []).map((info, z) => {
        res.imgList.push({
          ...info,
          type: imageInfo.name
        });
        if (type===imageInfo.name && z===index) {
          res.initImgIndex = i;
        }
        i ++;
      })
    });
    return res;
  }

  // 去生成
  const goAIModel = () => {
    window.open(`/home?model=1`);
  }

  // 上一页
  const onPagePreClick = (type) => {
    const page = typePage[type]-1;
    setTypePage({
      ...typePage,
      [type]: page
    });
    onPageChangeAnim(type, page);
  }

  // 下一页
  const onPageNextClick = (type) => {
    const page = typePage[type]+1;
    setTypePage({
      ...typePage,
      [type]: page
    });
    onPageChangeAnim(type, page);
  }

  // 翻页动效
  const onPageChangeAnim = (type, page) => {
    const elm = document.getElementById(`model_item_list_${type}`);
    const left = elm.offsetLeft;
    const endLeft = (page - 1) * -1204;
    elm.style.setProperty('--modelBoxAnimName', `modelBoxMoveAnim${page%2}`);
    elm.style.setProperty('--modelBoxAnimLeftStart', `${left}px`);
    elm.style.setProperty('--modelBoxAnimLeftEnd', `${endLeft}px`);
  }

  return (<div className="model-stash-detail-page">
    <div className={`model-stash-detail-banner`}>
      <img className="bg-img" src={require('../../assets/images/model-stash/bg-gray.png')} />
      <img className="texture-img" src={require('../../assets/images/model-stash/texture.svg')} />
      <div className="bg-box">
        <img className="bg-left-img" src={require('../../assets/images/model-stash/bg-lefit.png')} />
        <img className="bg-right-img" src={require('../../assets/images/model-stash/bg-right.png')} />
      </div>
      <div className="model-info-box">
        <img className="model-img" src={modelInfo?.banner_avatar} />
        <span className="info-box">
          <div className="name-box">
            <div className="name">{modelInfo?.name}</div>
            <div className="name-line"></div>
          </div>

          <div className="info-bottom-box">
            <span className="info-tag">
              {
                (modelInfo?.tags || []).map((tag, index) => <span className="info-tag-item" key={index}>#{tag}</span>)
              }
            </span>
            <span className="go-btn" onClick={goAIModel}>
              <span>去生成</span>
              <img className="btn-right-arrow" src={require('../../assets/images/model-stash/icon-arrow-right.svg')} />
            </span>
          </div>
        </span>
      </div>
    </div>
    <div className={`model-stash-detail-body`}>
      {
        modelInfo?.images.map((images, index) => <div className="type-box">
          <img className="type-img" src={require(`../../assets/images/model-stash/type-${index + 1}.png`)} />
          <span className="model-list">
            {
              // 左翻页
              (typePage[index+1] > 1) && <span className="left-btn" onClick={() => onPagePreClick(index+1)}/>
            }
            {
              // 右翻页
              (typePage[index+1]*4 < images.list.length) && <span className="right-btn" onClick={() => onPageNextClick(index+1)}/>
            }

            <span id={`model_item_list_${index+1}`} className="model-item-list">
              {
                images.list.map((item, i) => <span id={`model_box_${index + 1}_${i}`} className="model-box" onClick={() => onModelClick(images.name, i)}>
                  <img className="model-img" src={item.thumbnail} />
                  <span className="type-name">{item.tag}</span>
                </span>)
              }
            </span>
          </span>
        </div>)
      }
    </div>
    {
      previewData && <ImgPreview data={previewData} onClose={() => setPreviewData(null)}/>
    }
  </div>)
};

export default withRouter(ModelStash);

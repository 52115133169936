import React, {useState, useEffect} from "react";
import {useSelector,useDispatch} from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {withRouter} from "react-router-dom";
import { Pagination } from "antd";


import './index.less';
import API from "../../../apis";

/**
 * 用户中心-生成记录
 */
const UserCenterRecord = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const PAGE_SIZE = 20;

  // 数据列表
  const [dataList, setDataList] = useState(null);
  // 当前第几页
  const [page, setPage] = useState(1);
  // 总页数
  const [totalSize, setTotalSize] = useState(0);
  // 显示tips
  const [showBuildTips, setShowBuildTips] = useState(false);

  useEffect(() => {
    getTaskHistory(1);
  }, [])

  // 图片生成记录
  const getTaskHistory = async (currentPage) => {
    const params = {
      page: currentPage || page,
      page_size: PAGE_SIZE
    };
    const res = await API.getTaskHistory(params);
    if (res.code === 0) {
      setPage(currentPage);
      setTotalSize(res.data.total);
      setDataList(res.data.list);
    }
  }

  // 翻页
  const onPageChange = (current, pageSize) => {
    getTaskHistory(current);
  };

  // 获取状态编号
  const getStatusCode = (data) => {
    // 快速生成且排队中
    if (data.status === 1 && data.build_type === 1) {
      return 0;
    }
    if (data.status === -1) {
      return 10;
    }
    return data.status;
  }

  // 查看详情
  const onGoDetail = (data) => {
    window.open(`/aimodel?task_id=${data.id}`);
  }

  // 模特库
  const goModelStash = (modelId) => {
    if (modelId === 200 || modelId === 300) {
      return;
    }
    window.open(`/modeldetail?id=${modelId}`);
  }

  return (<div className="user-center-record-page">
    <div className="top-bar">
      <span className="left-box">图片生成记录</span>
    </div>
    <div className="item-list-container">
      {/*空数据*/}
      {
        dataList && dataList.length===0 && <div className="empty-box">
          <img className="empty-icon" src={require('../../../assets/images/common/icon-empty.svg')}/>
          <span>暂无记录</span>
        </div>
      }

      <div className={`table-title-box ${!dataList || dataList.length===0 && "hide"}`}>
        <span className="table-title-item" style={{width: "30%"}}>图片设定</span>
        <span className="table-title-item" style={{width: "15%"}}>方式</span>
        <span className="table-title-item" style={{width: "11%"}}>消耗M豆</span>
        <span className="table-title-item" style={{width: "12%"}}>状态</span>
        <span className="table-title-item" style={{width: "20%"}}>进度</span>
        <span className="table-title-item" style={{width: "12%"}}>操作</span>
      </div>
      <div className={`table-body-box ${!dataList || dataList.length===0 && "hide"}`}>
        {
          (dataList || []).map((item, index) => <div className="table-body-item" key={index}>
            <div className="top-box">
              <span className="time-box">{item.create_time}</span>
              <span className="id-box">ID: {item.id}</span>
            </div>

            <div className="bottom-box">
              <span className="condition-box" style={{width: "30%"}}>
                <span className="original-img-box">
                  <span className="original-img" style={{backgroundImage: `url(${item.image})`}}/>
                  <span className="type-box">{ item.image_type === 1 ? '真人图' : '人台图' }</span>
                </span>
                <span className="condition-info">
                  <div className="condition-info-item">
                    {item.gender_name} |&nbsp;<span className={`model-name ${item.model_id!==200 && item.model_id!==300 && "show-hover"}`} onClick={() => goModelStash(item.model_id)}>{item.model_name}</span>
                  </div>
                  <div className="condition-info-item">背景 | {item.background_name}</div>
                </span>
              </span>

              <span style={{width: "15%"}}>{item.build_type === 1 ? '快速生成' : '预约生成'}</span>

              <span className="use-money-box" style={{width: "11%"}}>
                <img className="money-icon" src={require('../../../assets/images/common/icon-money-16x4.png')}/>
                {item.cost_coin}
              </span>

              <span className={`status-box status-${getStatusCode(item)}`} style={{width: "12%"}}>{item.status_name}</span>

              <span className="progress-box" style={{width: "20%"}}>
                {
                  getStatusCode(item) === 0 && <span className="progress">
                    预计 {item.quick_build_wait_minute} 分钟完成
                    <span className="tips-icon-box">
                      <span
                        className="tips-icon"
                        onMouseOver={() => setShowBuildTips(item.id)}
                        onMouseOut={() => setShowBuildTips(false)}
                      />
                    {
                      showBuildTips && showBuildTips===item.id && <span className="tips">
                        <img className="warn-icon" src={require('../../../assets/images/common/icon-tips-warn.svg')} />
                        <span className="tip-txt">该时间供您参考，实际生成时间受任务数量和 AI 服务器资源消耗情况等综合影响</span>
                      </span>
                    }
                    </span>
                  </span>
                }
                {
                  getStatusCode(item) === 1 && <span className="progress">
                    预计 24 小时内完成
                    <span className="tips-icon-box">
                      <span
                        className="tips-icon"
                        onMouseOver={() => setShowBuildTips(item.id)}
                        onMouseOut={() => setShowBuildTips(false)}
                      />
                      {
                        showBuildTips && showBuildTips===item.id && <span className="tips">
                          <img className="warn-icon" src={require('../../../assets/images/common/icon-tips-warn.svg')} />
                          <span className="tip-txt">此图片将在 AI 服务器资源充裕时进行生成，保证在24小时内完成</span>
                        </span>
                      }
                    </span>
                  </span>
                }
                {
                  getStatusCode(item) === 2 && <span className="progress">图片正在生成中...</span>
                }
                {
                  getStatusCode(item) === 3 && <span className="progress-success">
                    <span className="build-img-box">
                      <span className="build-img" style={{backgroundImage: `url(${item.outputs[0]})`}}/>
                    </span>

                    <span className="build-count-box" onClick={() => onGoDetail(item)}>+3</span>
                  </span>
                }
                {
                  getStatusCode(item) === 10 && <span className="progress error">
                    已退回M豆
                    <img className="money-icon" src={require('../../../assets/images/common/icon-money-16x4.png')}/>
                    {item.cost_coin}
                  </span>
                }

              </span>

              <span className="operate-box" style={{width: "12%"}} onClick={() => onGoDetail(item)}>
                {
                  // 1排队中，2处理中，3已完成，-1失败
                  item.status === 3 ? <span className="operate-btn download-btn">去下载</span> : <span className="operate-btn detail-btn">查看详情</span>
                }
              </span>
            </div>
          </div>)
        }
      </div>
      {
        totalSize > PAGE_SIZE && <div className="table-pagination-box">
          <Pagination
            onChange={onPageChange}
            pageSize={PAGE_SIZE}
            current={page}
            total={totalSize}
          />
        </div>
      }
    </div>
  </div>)
};

export default withRouter(UserCenterRecord);

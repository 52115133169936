import React, {useState, useEffect} from "react";
import { useHistory } from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {withRouter} from "react-router-dom";
import LoginRegister from '../../components/login-register'
import ContactUs from '../../components/contact-us'

import './index.less';
import {getMemberInfo, logout} from "../../store/actions/ai";

const Header = ({}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const token = window.localStorage.getItem('token');
  const [isContactOpen, setIsContactOpen] = useState(null);
  const [isLoginRegisterOpen, setIsLoginRegisterOpen] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showProductMenu, setShowProductMenu] = useState(false);
  const [showImageTypeAnim, setShowImageTypeAnim] = useState(null);
  const [timer, setTimer] = useState(null);
  // 用户信息
  const { memberInfo } = useSelector(state => {
    return {
      memberInfo: state.getIn(['member', 'memberInfo']),
    }
  });

  useEffect(() => {
    // const token = window.localStorage.getItem('token');
    // if (token) {
    //   dispatch(getMemberInfo())
    // }

    createInterval();
  }, [])

  // const getConfig = () => {
  //   dispatch(getConfig())
  // }

  // 10秒取一次用户信息
  const createInterval = () => {
    const token = window.localStorage.getItem('token');
    if (token) {
      dispatch(getMemberInfo())
    }

    const time = setTimeout(() => {
      createInterval();
    }, 10000);
    setInterval(time);
  }

  const onHomeClick = () => {
    // history.push('/');
    window.open("/");
  }

  const onProductClick = (imageType) => {
    // history.push(`/aimodel?type=${imageType}`);
    // window.open(`/aimodel?type=${imageType}`);
    window.location.href = `/aimodel?type=${imageType}`;
    setShowImageTypeAnim(null);
    setShowProductMenu(false);
  }

  const onModelStashClick = () => {
    history.push('/modelstash');
  }

  const onDepositClick = () => {
    history.push('/deposit');
    // window.open("/deposit");
    setShowMenu(false);
  }

  const onUserCenterClick = (tab) => {
    // history.push({pathname: '/center', state: {tab}});
    window.open(`/center?tab=${tab}`);
    setShowMenu(false);
  }

  const onLogoutClick = async () => {
    dispatch(logout());
    setShowMenu(false);
  }

  const onContactClick = () => {
    setIsContactOpen(true);
  }

  const onMessageClick = () => {
    onUserCenterClick(4);
  }

  const onContactClose = () => {
    setIsContactOpen(false);
  }

  const onLoginRegisterClick = () => {
    setIsLoginRegisterOpen(true)
  }

  const onLoginRegisterClose = () => {
    setIsLoginRegisterOpen(false)
  }

  return (<div className="header-component">
    <div className="main-container">
      <span className="left-box">
        <img className="logo" src={require('../../assets/images/logo.png')} onClick={onHomeClick}/>
        <span
          className="select-item product"
          onMouseEnter={() => setShowProductMenu(true)}
          onMouseLeave={() => setShowProductMenu(false)}
        >
          <span className="product-name-box">
            AI 模特图
            <img className={`arrow-icon ${showProductMenu ? "open" : ''}`} src={require('../../assets/images/head/icon-arrow-down.svg')}/>
          </span>
          <div className={`menu-box ${showProductMenu ? "show" : "hide"}`} onMouseOver={() => setShowProductMenu(true)}>
            <div
              className="menu-item"
              onClick={() => onProductClick(1)}
              onMouseEnter={() => setShowImageTypeAnim(1)}
              onMouseLeave={() => setShowImageTypeAnim(null)}
            >
              真人图美化
            </div>
            <div
              className="menu-item"
              onClick={() => onProductClick(2)}
              onMouseEnter={() => setShowImageTypeAnim(2)}
              onMouseLeave={() => setShowImageTypeAnim(null)}
            >
              人台变真人
            </div>
          </div>
          {/*真人图美化*/}
          <div className={`image-type-anim-box ${showImageTypeAnim === 1 ? "show" : "hide"}`}>
            <div className="image-type-body-box">
              <img className="image-box image-type-1" src={require('../../assets/images/head/image-type-1-1.jpg')} />
              <span className="image-box image-type-2" style={{backgroundImage: `url(${require('../../assets/images/head/image-type-1-2.jpg')})`}} />
              <span className="image-box image-type-3" style={{backgroundImage: `url(${require('../../assets/images/head/image-type-1-3.jpg')})`}}/>
            </div>
          </div>
          {/*人台变真人*/}
          <div className={`image-type-anim-box box-2 ${showImageTypeAnim === 2 ? "show" : "hide"}`}>
            <div className="image-type-body-box">
              <img className="image-box image-type-1" src={require('../../assets/images/head/image-type-2-1.jpg')} />
              <span className="image-box image-type-2" style={{backgroundImage: `url(${require('../../assets/images/head/image-type-2-2.jpg')})`}} />
              <span className="image-box image-type-3" style={{backgroundImage: `url(${require('../../assets/images/head/image-type-2-3.jpg')})`}}/>
            </div>
          </div>
        </span>
        <span className="select-item" onClick={onModelStashClick}>AI 模特库</span>
        <span className="select-item" onClick={onDepositClick}>定价</span>
        <span className="select-item contact-us" onClick={onContactClick}>联系我们</span>
      </span>
      <span className="right-box">
        {
          !token && <span className="login-btn" onClick={onLoginRegisterClick}>登录</span>
        }
        {
          token && <span className="message-box" onClick={onMessageClick}>
            <img className="message-icon" src={require('../../assets/images/icon-message.svg')} />
            {
              memberInfo?.message_num > 0 && <span className={`num-box ${parseInt(memberInfo.message_num)>9 && "more"}`}>{memberInfo && (parseInt(memberInfo.message_num) > 9 ? '9+' : memberInfo.message_num)}</span>
            }
          </span>
        }
        {
          token &&
          <div
            className={`member-logo-box`}
            onMouseOver={() => setShowMenu(true)}
            onMouseOut={() => setShowMenu(false)}
          >
            <img className="logo" src={require('../../assets/images/common/logo-white.svg')}/>
            <div className={`menu-box  ${showMenu ? "show" : "hide"}`} onMouseOver={() => setShowMenu(true)}>
              <div className="menu-item" onClick={() => onUserCenterClick(1)}>
                M豆
                <img className="icon-money" src={require('../../assets/images/common/icon-money-18x4.png')}/>
                <span className="money-value">{memberInfo && (parseInt(memberInfo.coin) > 9999 ? '9999+' : memberInfo.coin)}</span>
              </div>
              <div className="menu-item" onClick={() => onUserCenterClick(2)}>图片生成记录</div>
              <div className="menu-item" onClick={() => onUserCenterClick(3)}>账号设置</div>
              <div className="menu-item" onClick={onLogoutClick}>退出登录</div>
            </div>
          </div>
        }
      </span>
      {/*联系我们*/}
      <ContactUs isOpen={isContactOpen} onClose={onContactClose}/>
      {/*登录注册*/}
      {
        isLoginRegisterOpen && <LoginRegister type={"login"} isOpen={isLoginRegisterOpen} onClose={onLoginRegisterClose}/>
      }
    </div>
  </div>)
};

export default withRouter(Header);
import React, {Component} from 'react';
import {connect} from "react-redux";
import './index.less';
import {Form, message, Upload} from "antd";
import {withRouter} from "react-router-dom";
import TryProductH5 from "../../components/try-product-h5";
import API from "../../apis";

class HomeH5 extends Component {

  state = {
    SCREEN_WIDTH: document.body.clientWidth,

    CONFIG_BG_LIST: [
      { bgId: 101, name: '城市', bgImg: require('../../assets/images/home-h5/bg-101.png') },
      { bgId: 102, name: '灰色', bgImg: require('../../assets/images/home-h5/bg-102.png') },
      { bgId: 103, name: '客厅', bgImg: require('../../assets/images/home-h5/bg-103.png') },
      { bgId: 104, name: '教室', bgImg: require('../../assets/images/home-h5/bg-104.png') },
      { bgId: 105, name: '游乐园', bgImg: require('../../assets/images/home-h5/bg-105.png') },
      { bgId: 106, name: '海边', bgImg: require('../../assets/images/home-h5/bg-106.png') },
      { bgId: 107, name: '咖啡厅', bgImg: require('../../assets/images/home-h5/bg-107.png') },
      { bgId: 108, name: '公园', bgImg: require('../../assets/images/home-h5/bg-108.png') },
    ],

    CONFIG_MAP: [
      {
        iconImg: require('../../assets/images/home-h5/config-head-1.png'),
        list: [
          { bgId: 101, img: require('../../assets/images/home-h5/config-img-1-1.jpg') },
          { bgId: 102, img: require('../../assets/images/home-h5/config-img-1-2.jpg') },
          { bgId: 103, img: require('../../assets/images/home-h5/config-img-1-3.jpg') },
          { bgId: 104, img: require('../../assets/images/home-h5/config-img-1-4.jpg') },
          { bgId: 105, img: require('../../assets/images/home-h5/config-img-1-5.jpg') },
          { bgId: 106, img: require('../../assets/images/home-h5/config-img-1-6.jpg') },
          { bgId: 107, img: require('../../assets/images/home-h5/config-img-1-7.jpg') },
          { bgId: 108, img: require('../../assets/images/home-h5/config-img-1-8.jpg') },
        ]
      },
      {
        iconImg: require('../../assets/images/home-h5/config-head-2.png'),
        list: [
          { bgId: 101, img: require('../../assets/images/home-h5/config-img-2-1.jpg') },
          { bgId: 102, img: require('../../assets/images/home-h5/config-img-2-2.jpg') },
          { bgId: 103, img: require('../../assets/images/home-h5/config-img-2-3.jpg') },
          { bgId: 104, img: require('../../assets/images/home-h5/config-img-2-4.jpg') },
          { bgId: 105, img: require('../../assets/images/home-h5/config-img-2-5.jpg') },
          { bgId: 106, img: require('../../assets/images/home-h5/config-img-2-6.jpg') },
          { bgId: 107, img: require('../../assets/images/home-h5/config-img-2-7.jpg') },
          { bgId: 108, img: require('../../assets/images/home-h5/config-img-2-8.jpg') },
        ]
      },
      {
        iconImg: require('../../assets/images/home-h5/config-head-3.png'),
        list: [
          { bgId: 101, img: require('../../assets/images/home-h5/config-img-3-1.jpg') },
          { bgId: 102, img: require('../../assets/images/home-h5/config-img-3-2.jpg') },
          { bgId: 103, img: require('../../assets/images/home-h5/config-img-3-3.jpg') },
          { bgId: 104, img: require('../../assets/images/home-h5/config-img-3-4.jpg') },
          { bgId: 105, img: require('../../assets/images/home-h5/config-img-3-5.jpg') },
          { bgId: 106, img: require('../../assets/images/home-h5/config-img-3-6.jpg') },
          { bgId: 107, img: require('../../assets/images/home-h5/config-img-3-7.jpg') },
          { bgId: 108, img: require('../../assets/images/home-h5/config-img-3-8.jpg') },
        ]
      }
    ],

    STEP_LIST: [
      {
        step: 1,
        stepEn: 'ONE',
        name: '选择图片',
        enName: 'SELECT IMAGE',
        videoTime: 4300,
        video: require('../../assets/video/video-config-1.mp4'),
        icon: require('../../assets/images/home/icon-step-1.svg'),
        iconSelected: require('../../assets/images/home/icon-step-1-selected.svg')
      },
      {
        step: 2,
        stepEn: 'TWO',
        name: '图片检测',
        enName: 'IMAGE DETECTION',
        videoTime: 3000,
        video: require('../../assets/video/video-config-2.mp4'),
        icon: require('../../assets/images/home/icon-step-2.svg'),
        iconSelected: require('../../assets/images/home/icon-step-2-selected.svg')
      },
      {
        step: 3,
        stepEn: 'THREE',
        name: '图片设定',
        enName: 'IMAGE SETTING',
        videoTime: 2500,
        video: require('../../assets/video/video-config-3.mp4'),
        icon: require('../../assets/images/home/icon-step-3.svg'),
        iconSelected: require('../../assets/images/home/icon-step-3-selected.svg')
      },
      {
        step: 4,
        stepEn: 'FOUR',
        name: '图片生成',
        enName: 'AI GENERATION',
        videoTime: 9500,
        video: require('../../assets/video/video-config-4.mp4'),
        icon: require('../../assets/images/home/icon-step-4.svg'),
        iconSelected: require('../../assets/images/home/icon-step-4-selected.svg')
      },
    ],

    ADVANTAGE_LIST: [
      {
        id: 1,
        name: '写实模特效果图',
        img: require('../../assets/images/home-h5/advantage-img-1.png'),
        desc: '通过 AI 算法和计算机视觉技术，MixModel 根据您的服装设计与场景需求，生成超写实的模特展示图'
      },
      {
        id: 2,
        name: '海量 AI 模特可选',
        img: require('../../assets/images/home-h5/advantage-img-2.png'),
        desc: '在 MixModel 的模特库中，您可以选择不同性别、不同形象、不同身材、不同年龄的各类 AI 模特'
      },
      {
        id: 3,
        name: '定制化场景设置',
        img: require('../../assets/images/home-h5/advantage-img-3.png'),
        desc: '从街头潮流到正式晚礼服，从室内拍摄到户外风景，MixModel 为您创造适应不同场景和风格的模特图'
      },
      {
        id: 4,
        name: '高效率高精度出图',
        img: require('../../assets/images/home-h5/advantage-img-4.png'),
        desc: '高效算法可在几秒内生成高品质模特图，大大降低制作时间和成本，生成的图片可与真人模特媲美'
      }
    ],

    configHeadInit: false,
    configImgIndex: 0,
    configBgId: 101,
    configImgIndexOld: 0,
    configBgIdOld: 101,
    configInit: false,
    stepItemList: [],
    currentStepItem: {},
    currentStepItemOld: {},
    isStepAutoChange: true,
    advantageItemList: [],
    advantageInit: false,
    currentAdvantage: {},
    stepInit: false,
    stepVideoInit: false,
    stepAutoTimer: null,
    dataInterval: null,
    configImgUpdateTime: 0,
    configAutoTimer: null,
    isTryOpen: false,
    mousePosData: {
      // 1: 步数滑动翻页；2：优势滑动翻页
      type: -1,
      pos: null
    },

    dataList: [
      {max: 75, current: 0},
      {max: 5, current: 0},
      {max: 80, current: 0},
      {max: 20, current: 0},
    ]
  };

  componentDidMount() {
    document.title = 'MixModel.cn | AI模特，服装拍摄全新生产力';

    this.initData();
    this.initEvent();
    this.getAccess();
  }

  componentWillUnmount() {
    this.removeEvent();

    if (this.state.stepAutoTimer) {
      clearTimeout(this.state.stepAutoTimer);
      this.setState({
        stepAutoTimer: null
      })
    }
    if (this.state.dataInterval) {
      clearInterval(this.state.dataInterval)
      this.setState({
        dataInterval: null
      })
    }
    if (this.state.configAutoTimer) {
      clearTimeout(this.state.configAutoTimer);
      this.setState({
        configAutoTimer: null
      })
    }
  }

  initData = () => {
    // this.onStepItemClick(this.state.STEP_LIST[0]);
    this.setState({
      advantageItemList: this.state.ADVANTAGE_LIST,
      currentAdvantage: this.state.ADVANTAGE_LIST[0],
      stepItemList: this.state.STEP_LIST,
      currentStepItem: this.state.STEP_LIST[0],
      currentStepItemOld: this.state.STEP_LIST[0],
    })

    // 计算步骤视频显示高度，根据浏览器宽度适配
    const screenWidth = this.state.SCREEN_WIDTH;
    const stepMovieHeight = (screenWidth - 40) / 804 * 494;
    const stepMovieBox = document.getElementById('step_movie_item_box');
    stepMovieBox.style.setProperty('--stepMovieItemBoxWidth', `${stepMovieHeight}px`);
  }

  initEvent = () => {
    this.checkScrollTop();
    // 滚动显示动画事件
    window.addEventListener('scroll', this.onScrollEvent, true);

    // 必须在微信Weixin JSAPI的WeixinjsBridgeReady才能生效
    // document.addEventListener("WeixinjsBridgeReady", () => {
    //   const stepVideoElm = document.getElementById(`step_video_1`);
    //   stepVideoElm.play();
    // }, false)

    this.state.STEP_LIST.map(item => {
      const video = document.getElementById(`step_video_${item.step}`);
      video.addEventListener('canplay', () => {
        video.play();
      });
    })

  }

  removeEvent = () => {
    // 滚动显示动画事件
    window.removeEventListener('scroll', this.onScrollEvent, true);
  }

  // 首次访问接口
  getAccess = async () => {
    const res = await API.access(null);
    if (res.code === 0) {

    }
  }

  onScrollEvent = () => {
    this.checkScrollTop();
  }

  // 滚动条位置
  checkScrollTop = () => {
    // 浏览器宽高
    // const clientWidth = document.body.clientWidth;
    const clientHeight = document.body.clientHeight;
    // 滚动条高度
    const scrollTop = document.scrollingElement.scrollTop;
    const posY = clientHeight + scrollTop;
    console.log('clientHeight......', clientHeight, scrollTop, posY);

    // banner动效
    if (posY > 0) {
      const elm1 = document.getElementById('banner_logo');
      const elm2 = document.getElementById('banner_title');
      const elm3 = document.getElementById('banner_desc');
      const elm4 = document.getElementById('banner_try_btn');
      elm1.style.setProperty('--titleAnimName', `titleAnim`);
      elm2.style.setProperty('--titleAnimName', `titleAnim`);
      elm3.style.setProperty('--titleAnimName', `titleAnim`);
      elm4.style.setProperty('--bannerTryBtnAnimName', `bannerTryBtnAnim`);
    }

    // 选择配置标题动效
    if (posY > 860) {
      const lineElm = document.getElementById('config_title_line');
      const elm1 = document.getElementById('config_title_txt');
      const elm2 = document.getElementById('config_desc_txt');
      lineElm.style.setProperty('--titleLineAnimName', `titleLineAnim`);
      elm1.style.setProperty('--titleAnimName', `titleAnim`);
      elm2.style.setProperty('--titleAnimName', `titleAnim`);
    }

    // 数字标题动效
    if (posY > 1750) {
      const lineElm = document.getElementById('data_title_line');
      const elm1 = document.getElementById('data_title_txt');
      const elm2 = document.getElementById('data_desc_txt');
      lineElm.style.setProperty('--titleLineAnimName', `titleLineAnim`);
      elm1.style.setProperty('--titleAnimName', `titleAnim`);
      elm2.style.setProperty('--titleAnimName', `titleAnim`);
    }

    // 图片生成类型
    if (posY > 2300) {
      const lineElm = document.getElementById('product_title_line');
      const elm = document.getElementById('product_title_txt');

      lineElm.style.setProperty('--titleLineAnimName', `titleLineAnim`);
      elm.style.setProperty('--titleAnimName', `titleAnim`);
    }
    if (posY > 2400) {
      const elm1 = document.getElementById('product_type_box_1');
      elm1.style.setProperty('display', `inline-block`);
    }
    if (posY > 2600) {
      const elm1 = document.getElementById('product_type_box_2');
      elm1.style.setProperty('display', `inline-block`);
    }

    // 步骤标题动效
    if (!this.state.stepInit && posY > 3038) {
      this.setState({
        stepInit: true
      });
      const lineElm = document.getElementById('step_title_line');
      const elm = document.getElementById('step_title_txt');
      const bodyElm = document.getElementById('step_body');
      lineElm.style.setProperty('--titleLineAnimName', `titleLineAnim`);
      elm.style.setProperty('--titleAnimName', `titleAnim`);
      bodyElm.style.setProperty('--stepBodyAnimName', `stepBodyAnim`);
      // this.onStepItemClick(this.state.STEP_LIST[0]);
      this.stepPageChange(false);
    }

    // 优势标题动效
    if (!this.state.advantageInit && posY > 3668) {

      this.setState({
        advantageInit: true
      })

      const lineElm = document.getElementById('advantage_title_line');
      const elm = document.getElementById('advantage_title_txt');
      const elm2 = document.getElementById('advantage_item_box');
      const elm3 = document.getElementById('advantage_selected_desc_box');
      const elm4 = document.getElementById('advantage_page_box');

      lineElm.style.setProperty('--titleLineAnimName', `titleLineAnim`);
      elm.style.setProperty('--titleAnimName', `titleAnim`);
      elm2.style.setProperty('--titleAnimName', `titleAnim`);
      elm3.style.setProperty('--titleAnimName', `titleAnim`);
      elm4.style.setProperty('--titleAnimName', `titleAnim`);

      setTimeout(() => {
        this.advantagePageChange();
      }, 1000)
    }

    // 选择配置动效
    if (posY > 1000) {
      if (!this.state.configHeadInit) {
        this.setState({
          configHeadInit: true
        })

        const elm = document.getElementById('config_img_item_box');
        elm.style.setProperty('--configImgFlyAnimName', `configImgFlyAnim`);
        this.configFlyAnim();

        this.onConfigHeadChange(0, true);
      }
    }

    // 选择配置背景栏动效
    if (!this.state.configInit && posY > 1400) {
      this.setState({
        configInit: true
      })

      const elm = document.getElementById('config_bg_box');
      elm.style.setProperty('--configBgBoxFlyAnimName', `configBgBoxAnimFly`);
    }

    // 数字滚动动效
    if (posY > 1860) {
      this.dataRoll();
    }
  }

  getConfigData = (imgIndex, bgId) => {
    const data = this.state.CONFIG_MAP[imgIndex].list.find(item => {
      return item.bgId === bgId;
    })
    return data;
  }

  getConfigBgData = (bgId) => {
    const data = this.state.CONFIG_BG_LIST.find(item => {
      return item.bgId === bgId;
    })
    return data.bgImg;
  }

  // 数字滚动
  dataRoll = () => {
    const elm1 = document.getElementById('data_item_1');
    const elm2 = document.getElementById('data_item_2');
    const elm3 = document.getElementById('data_item_3');
    const elm4 = document.getElementById('data_item_4');
    elm1.style.setProperty('--dataItemAnimName', `dataItemMoveAnim1`);
    elm1.style.setProperty('--dataItemAnimDelay', '0.2s');
    elm2.style.setProperty('--dataItemAnimName', `dataItemMoveAnim2`);
    elm2.style.setProperty('--dataItemAnimDelay', '1s');
    elm4.style.setProperty('--dataItemAnimName', `dataItemMoveAnim3`);
    elm4.style.setProperty('--dataItemAnimDelay', '1.8s');
    elm3.style.setProperty('--dataItemAnimName', `dataItemMoveAnim4`);
    elm3.style.setProperty('--dataItemAnimDelay', '2.6s');

    const arrowElm1 = document.getElementById('arrow_icon_1');
    const arrowElm2 = document.getElementById('arrow_icon_2');
    const arrowElm3 = document.getElementById('arrow_icon_3');
    const arrowElm4 = document.getElementById('arrow_icon_4');
    arrowElm1.style.setProperty('--arrowIconAnimName', `arrowIconAnimDown`);
    arrowElm1.style.setProperty('--arrowIconAnimDelay', '0.2s');
    arrowElm2.style.setProperty('--arrowIconAnimName', `arrowIconAnimUp`);
    arrowElm2.style.setProperty('--arrowIconAnimDelay', '1s');
    arrowElm4.style.setProperty('--arrowIconAnimName', `arrowIconAnimUp`);
    arrowElm4.style.setProperty('--arrowIconAnimDelay', '1.8s');
    arrowElm3.style.setProperty('--arrowIconAnimName', `arrowIconAnimUp`);
    arrowElm3.style.setProperty('--arrowIconAnimDelay', '2.6s');

    if (this.state.dataInterval) {
      return;
    }

    let list = [...this.state.dataList];
    // 计时器
    let time = 0;
    let dataInterval = setInterval(() => {
      // 删除计时器
      if (list[0].current>=list[0].max
        && list[1].current>=list[1].max
        && list[2].current>=list[2].max
        && list[3].current>=list[3].max) {
        clearInterval(this.state.dataInterval)
      }

      // 延时200毫秒
      if (time > 200 && list[0].current < list[0].max) {
        list[0].current += 6;
        if (list[0].current > list[0].max) {
          list[0].current = list[0].max
        }
      }
      // 延时1000毫秒
      if (time > 1000 && list[1].current < list[1].max) {
        list[1].current += 1;
        if (list[1].current > list[1].max) {
          list[1].current = list[1].max
        }
      }
      // 延时2600毫秒
      if (time > 2600 && list[2].current < list[2].max) {
        list[2].current += 6;
        if (list[2].current > list[2].max) {
          list[2].current = list[2].max
        }
      }
      // 延时1800毫秒
      if (time > 1800 && list[3].current < list[3].max) {
        list[3].current += 2;
        if (list[3].current > list[3].max) {
          list[3].current = list[3].max
        }
      }
      this.setState({
        dataList: list,
      })

      time += 80;
    }, 80);

    this.setState({
      dataInterval: dataInterval
    })
  }

  // 配置栏自动轮换
  configAutoChange = (isAuto) => {
    const timer = setTimeout(() => {
      if (this.state.configBgId === this.state.CONFIG_BG_LIST.slice(-1)[0].bgId) {
        // 当前角色背景已经轮播完，播放下一个角色第一张背景图
        const configIndex = this.state.configImgIndex + 1 >= this.state.CONFIG_MAP.length ? 0 : this.state.configImgIndex + 1;
        this.onConfigHeadChange(configIndex);
        this.onConfigBgChange(this.state.CONFIG_BG_LIST[0].bgId, true);
      } else {
        // 当前角色背景未轮播完，播放下一张背景图
        let i = 0;
        this.state.CONFIG_BG_LIST.map((item, index) => {
          if (item.bgId === this.state.configBgId) {
            i = index + 1;
          }
        });
        this.onConfigBgChange(this.state.CONFIG_BG_LIST[i].bgId, true);
      }
    }, isAuto ? 3000 : 4000);
    this.setState({
      configAutoTimer: timer
    });
  }

  // 重新开始配置栏自动轮换
  restartConfigAutoChange = (isAuto) => {
    if (this.state.configAutoTimer) {
      clearTimeout(this.state.configAutoTimer);
      this.setState({
        configAutoTimer: null
      }, () => {
        // 开始新的计时器
        this.configAutoChange(isAuto);
      })
    } else {
      // 开始新的计时器
      this.configAutoChange(isAuto);
    }
  }

  onConfigHeadChange = (index, isInit) => {
    const oldIndex = this.state.configImgIndex;
    if (!isInit && oldIndex===index) {
      return;
    }

    this.setState({
      configImgIndex: index,
      configImgIndexOld: oldIndex
    })

    // 切换角色后背景图保持不变
    if (!isInit) {
      this.onConfigBgChange(this.state.configBgId);
    }

    // 老的绿色圆环隐藏
    const oldElm = document.getElementById(`head_img_item_selected_img_${oldIndex}`);
    oldElm.style.setProperty('--headSelectedAnimName', `headSelectedAnimationOut`);
    // 打钩图标
    const oldTickElm = document.getElementById(`head_img_item_tick_${oldIndex}`);
    oldTickElm.style.setProperty('--headSelectedIconAnimName', `headSelectedIconAnimOut`);

    setTimeout(() => {
      // 新的绿色圆环出现
      const newElm = document.getElementById(`head_img_item_selected_img_${index}`);
      newElm.style.setProperty('--headSelectedAnimName', `headSelectedAnimationIn`);
      // 打钩图标
      const tickElm = document.getElementById(`head_img_item_tick_${index}`);
      tickElm.style.setProperty('--headSelectedIconAnimName', `headSelectedIconAnimIn`);
    }, 500)
  }

  onConfigBgChange = (id, isAuto) => {
    const oldId = this.state.configBgId;

    this.setState({
      configBgId: id,
      configBgIdOld: oldId
    })

    this.restartConfigAutoChange(isAuto);
    this.configImgAnim();
  }

  configImgAnim = () => {
    const imgElm1 = document.getElementById('config_img_1');
    const imgElm2 = document.getElementById('config_img_2');
    imgElm1.style.setProperty('--configImgChangeAnimName', `configImgChangeAnimIn${this.state.configImgUpdateTime%2}`);
    imgElm2.style.setProperty('--configImgChangeAnimName', `configImgChangeAnimOut${this.state.configImgUpdateTime%2}`);

    this.setState({
      configImgUpdateTime: this.state.configImgUpdateTime + 1
    })
  }

  onStepItemOver = () => {
    this.setState({
      isStepAutoChange: false
    })
  }

  onStepItemOut = () => {
    this.setState({
      isStepAutoChange: true
    })
  }

  configFlyAnim = () => {
    for (let index=0; index<4; index++) {
      setTimeout(() => {
        const headImgItemElm = document.getElementById(`head_img_item_box_${index}`);
        if (headImgItemElm) {
          headImgItemElm.style.setProperty('--headImgFlyAnimName', `headImgFlyH5`);
        }
      }, 100 * index)
    }
  }

  // configBgItemFlyAnim = () => {
  //   for (let index=0; index<8; index++) {
  //     setTimeout(() => {
  //       const itemElm1 = document.getElementById(`config_bg_item_${index}`);
  //       if (itemElm1) {
  //         itemElm1.style.setProperty('--configBgItemFlyAnimName', `configBgItemFly`);
  //       }
  //     }, 100 * index)
  //   }
  // }

  stepAutoChange = () => {
    if (this.state.stepAutoTimer) {
      clearTimeout(this.state.stepAutoTimer);
      this.setState({
        stepAutoTimer: null
      })
    }

    const stepData = this.state.currentStepItem;
    const timer = setTimeout(() => {
      if (!this.state.isStepAutoChange) {
        return;
      }
      this.stepPageChange(true);
    }, stepData.videoTime);

    this.setState({
      stepAutoTimer: timer
    })
  }

  // 步骤栏目左右切换
  stepPageChange = (isLeft) => {
    let list = [...this.state.stepItemList];
    let currentItem = null;
    list.map((item, index) => {
      const itemElm = document.getElementById(`step_video_${item.step}`);
      const itemElm2 = document.getElementById(`step_tag_${item.step}`);

      const type = isLeft ? 'Left' : 'Right';
      itemElm.style.setProperty('--stepAnimName', `stepAnim${index+1}${type}`);
      itemElm2.style.setProperty('--stepTagAnimName', `stepTagAnim${index+1}${type}`);


      if (isLeft && index === 2) {
        currentItem = item;
      } else if (!isLeft && index === 0) {
        currentItem = item;
      }
    })

    const itemElmNewOld = document.getElementById(`step_item_box_old`);
    const itemElmNew = document.getElementById(`step_item_box_new`);
    const itemElmLine = document.getElementById(`step_item_box_line`);
    itemElmNewOld.style.setProperty('--stepItemBoxAnimName', `stepItemBoxAnim${currentItem?.step%2}`);
    itemElmNew.style.setProperty('--stepItemBoxAnimName', `stepItemBoxAnim${currentItem?.step%2}`);
    itemElmLine.style.setProperty('--stepItemBoxLineAnimName', `stepItemBoxLineAnim${currentItem?.step%2}`);

    if (isLeft) {
      const tempItem = list[0];
      list.splice(0, 1);
      list.push(tempItem);
    } else {
      const tempItem = list[list.length - 1];
      list.pop();
      list.unshift(tempItem);
    }

    // setTimeout(() => {
      this.setState({
        stepItemList: list,
        currentStepItemOld: {...this.state.currentStepItem},
        currentStepItem: currentItem,
      }, () => {
        this.stepAutoChange();
        const stepVideoElm = document.getElementById(`step_video_${currentItem.step}`);
        stepVideoElm.load();


      })
    // }, 0)
  }

  // 优势栏目左右切换
  advantagePageChange = (isLeft) => {
    let list = [...this.state.advantageItemList];
    let currentItem = null;
    list.map((item, index) => {
      const itemElm = document.getElementById(`advantage_item_${item.id}`);
      const itemElm2 = document.getElementById(`advantage_selected_desc_${item.id}`);
      const itemElm3 = document.getElementById(`advantage_desc_title_${item.id}`);
      const itemElm4 = document.getElementById(`advantage_desc_info_${item.id}`);

      const type = isLeft ? 'Left' : 'Right';
      itemElm.style.setProperty('--advantageAnimName', `advantageAnim${index+1}${type}H5`);
      itemElm2.style.setProperty('--advantageDescAnimName', `advantageDescAnim${index+1}${type}H5`);
      itemElm3.style.setProperty('--advantageDescTitleAnimName', `advantageDescTitleAnim${type}H5${index%2}`);
      itemElm4.style.setProperty('--advantageDescInfoAnimName', `advantageDescInfoAnim${type}H5${index%2}`);

      if (isLeft && index === 2) {
        currentItem = item;
      } else if (!isLeft && index === 0) {
        currentItem = item;
      }
    })

    if (isLeft) {
      const tempItem = list[0];
      list.splice(0, 1);
      list.push(tempItem);
    } else {
      const tempItem = list[list.length - 1];
      list.pop();
      list.unshift(tempItem);
    }

    setTimeout(() => {
      this.setState({
        advantageItemList: list,
        currentAdvantage: currentItem
      })
    }, 200)
  }

  onDown = (type) => {
    this.setState({
      mousePosData: {
        type,
        pos: null
      }
    })
  }

  onUp = () => {
    this.setState({
      mousePosData: {
        type: -1,
        pos: null
      }
    })
  }

  onMove = (e) => {
    if (this.state.mousePosData.type === -1) {
      return;
    }
    const oldPos = this.state.mousePosData.pos;
    const target = e.changedTouches[0];

    if (oldPos) {
      this.setState({
        mousePosData: {
          ...this.state.mousePosData,
          pos: {
            x: target.clientX,
            y: target.clientY,
          }
        }
      }, () => {
        // y轴移动距离比x轴移动距离大则不处理
        if (Math.abs(oldPos.y - target.clientY) > Math.abs(oldPos.x - target.clientX)) {
          return;
        }
        // 步骤栏滑动
        if (this.state.mousePosData.type === 1) {
          if (oldPos.x > target.clientX) {
            this.stepPageChange(true);
          } else if (oldPos.x < target.clientX) {
            this.stepPageChange(false);
          }
        }
        // 优势栏滑动
        if (this.state.mousePosData.type === 2) {
          if (oldPos.x > target.clientX) {
            this.advantagePageChange(true);
          } else if (oldPos.x < target.clientX) {
            this.advantagePageChange(false);
          }
        }
        this.setState({
          mousePosData: {
            type: -1,
            pos: null
          }
        })
      })
      return;
    }

    this.setState({
      mousePosData: {
        ...this.state.mousePosData,
        pos: {
          x: target.clientX,
          y: target.clientY,
        }
      }
    })
  }

  onProductClick = () => {
    // const { history } = this.props;
    // history.push('/aimodel', null);
    this.onTryClick();
  }

  onTryClick = () => {
    this.setState({
      isTryOpen: true
    })
  }

  onTryClose = () => {
    this.setState({
      isTryOpen: false
    })
  }

  onPageClick = () => {
    if (this.state.stepVideoInit) {
      return;
    }

    // this.setState({
    //   stepVideoInit: true
    // });
    // 微信浏览器需要用户主动点击后才能播放视频，此处通过onTouchStart事件触发
    this.state.STEP_LIST.map(item => {
      const stepMovie = document.getElementById(`step_video_${item.step}`);
      if (stepMovie) {
        stepMovie.play();
      }
    })

    const advantage = document.getElementById('advantage_bg');
    if (advantage) {
      advantage.play();
    }
  }

  render() {
    const {
      CONFIG_MAP,
      configImgIndex,
      configImgIndexOld,
      configBgId,
      configBgIdOld,
      CONFIG_BG_LIST,
      STEP_LIST,
      ADVANTAGE_LIST,
      dataList,
      currentStepItem,
      currentStepItemOld,
      currentAdvantage,
      isTryOpen
    } = this.state;

    return <div id="home_page_h5" className="home-page-h5" onTouchStart={this.onPageClick}>
      <div className="main-container">
        {/*banner*/}
        <div className="banner-container">
          {/*<img className="banner-img" src={require('../../assets/images/home-h5/banner-img.png')} />*/}
          <div className="banner-img"></div>
          <div className="banner-body">
            <img id="banner_logo" className="logo" src={require('../../assets/images/home-h5/logo-x4.png')} />
            <div id="banner_title" className="title-box">
              <span className="title-ai">AI模特 <span className="cursor"></span></span>
              <span>服装拍摄全新生产力</span>
            </div>
            <div id="banner_desc" className='desc-box'>
              <div>MixModel 使用全新的 AIGC 技术，为服装品牌和零售商家赋能，无需高成本拍摄即可轻松制作高品质模特展示图</div>
            </div>
            <div className="btn-box">
              <span id="banner_try_btn" className="try-btn" onClick={this.onProductClick}>
                开始尝试<img className="right-icon" src={require('../../assets/images/home/icon-right.svg')} />
              </span>
            </div>
          </div>
        </div>

        {/*配置*/}
        <div className="config-container">
          <img className="config-bg-right" src={require('../../assets/images/home-h5/config-bg-right.png')} />

          <div id="config_main_box" className="config-main-box">
            <div className="config-title">
              <span id="config_title_line" className="title-line config-title-line"></span>
              <span id="config_title_txt" className="config-title-txt">来，我们先看看效果</span>
            </div>
            <div className="config-desc"><span id="config_desc_txt" className="config-desc-txt">上传服饰图片，MixModel立即为您生成不同模特、多种场景下的展示效果图</span></div>
            <div className="config-body">
              <span id="config_img_box"  className="config-img-box">
                <span id="config_img_item_box" className="config-img-item-box">
                  <img className="config-img-hidden" src={this.getConfigData(configImgIndex, configBgId).img} />
                  <img id="config_img_1" className="config-img config-img-1" src={this.getConfigData(configImgIndex, configBgId).img} />
                  <img id="config_img_2" className="config-img config-img-2" src={this.getConfigData(configImgIndex, configBgId).img} />
                </span>
                <span className="head-img-list-box">
                {
                  (CONFIG_MAP || []).map((item, index) => (
                    <span key={index} id={`head_img_item_box_${index}`} className={`head-img-item-box ${index===configImgIndex && "head-img-item-selected-box"}`} onClick={() => this.onConfigHeadChange(index)}>
                      <img id={`head_img_${index}`} className="head-img" src={item.iconImg} />
                      <span id={`head_img_item_selected_img_${index}`} className={`head-img-item-selected-img`}></span>
                      <img id={`head_img_item_tick_${index}`} className="tick-icon" src={require('../../assets/images/home-h5/config-head-selected-icon.svg')} />
                    </span>
                  ))
                }
              </span>
              </span>

              <span id="config_bg_box" className="config-bg-box">
                {
                  (CONFIG_BG_LIST || []).map((item, index) => <div
                    className={`config-bg-item ${configBgId === item.bgId && "config-bg-item-selected"}`}
                    onClick={() => this.onConfigBgChange(item.bgId)}
                  >
                      <span id={`config_bg_item_${index}`} className={`config-bg-item-in-box ${item.bgId === configBgId && "selected"}`}>
                        <img className="img" src={this.getConfigBgData(item.bgId)} />
                        <span className="name">{item.name}</span>
                      </span>
                  </div>)
                }
              </span>
            </div>
          </div>
        </div>

        {/*数据*/}
        <div className="data-container">
          <div className="data-body">
            <div className="data-title">
              <span id="data_title_line" className="title-line data-title-line"></span>
              <span id="data_title_txt" className="data-title-txt">服装拍摄全新生产力</span>
            </div>
            <div className="data-desc"><span id="data_desc_txt" className="data-desc-txt">从摄影师、模特、后期人工修图，进化为 AI 一键生成</span></div>
            <div className="data-box">
              <span id="data_item_1" className="data-item">
                <div className="data-item-value">
                  {dataList[0].current}<span className="unit">%</span>
                  <img id="arrow_icon_1" className="arrow-icon" src={require('../../assets/images/home-h5/icon-down.svg')} />
                </div>
                <div className="data-item-desc">降低拍摄成本</div>
              </span>
              <span id="data_item_2" className="data-item">
                <div className="data-item-value">
                  {dataList[1].current}<span className="unit">倍</span>
                  <img id="arrow_icon_2" className="arrow-icon" src={require('../../assets/images/home-h5/icon-up.svg')} />
                </div>
                <div className="data-item-desc">提升出图效率</div>
              </span>
              <span id="data_item_3" className="data-item">
                <div className="data-item-value">
                  {dataList[2].current}<span className="unit">%</span>
                  <img id="arrow_icon_3" className="arrow-icon" src={require('../../assets/images/home-h5/icon-up.svg')} />
                </div>
                <div className="data-item-desc">提升图片质量</div>
              </span>
              <span id="data_item_4" className="data-item">
                <div className="data-item-value">
                  {dataList[3].current}<span className="unit">%</span>
                  <img id="arrow_icon_4" className="arrow-icon" src={require('../../assets/images/home-h5/icon-up.svg')} />
                </div>
                <div className="data-item-desc">提升产品浏览</div>
              </span>
            </div>
          </div>
        </div>

        {/*图片生成类型*/}
        <div className="product-type-container">
          <div className="product-title">
            <span id="product_title_line" className="title-line product-title-line"></span>
            <span id="product_title_txt" className="product-title-txt">人台、真人都能支持</span>
          </div>
          <div id="product_type_body" className="product-type-body">
            <div id="product_type_box_1" className={`type-box type-box-1`}>
              {/*背景图*/}
              <img className="type-item-bg" src={require('../../assets/images/home-h5/product-type-1-big-bg.png')} />

              {/*模特图*/}
              <img className="init-modal" src={require('../../assets/images/home-h5/product-type-1-init-modal.png')} />
              <img className="new-modal" src={require('../../assets/images/home-h5/product-type-1-new-modal.png')} />
              <span className="marker-line-box marker-line-box-1">
                <img className="marker-line" src={require('../../assets/images/home-h5/product-type-line-1.svg')} />
              </span>

              {/*文案*/}
              <span id="big_info_box_1" className="big-info-box">
                <div className="big-info-top-box">
                  <div id="big_info_title_1" className="title">
                    真人图美化
                    <span id="big_info_top_line_1" className="title-line"></span>
                  </div>
                  <div id="big_info_desc_1" className="desc">不再受职业模特的时间档期、⾼昂费⽤约束 , 有了 MixModel，大众脸也能试⾐, ⼀键切换模特类型和拍摄场景</div>
                </div>
              </span>

              {/*按钮*/}
              <span id="big_info_btn_1" className="go-product-btn" onClick={() => this.onProductClick()}>去生成<img className="right-icon" src={require('../../assets/images/home-h5/icon-right.svg')} /></span>
            </div>

            <div id="product_type_box_2" className={`type-box type-box-2`}>
              {/*背景图*/}
              <img className="type-item-bg" src={require('../../assets/images/home-h5/product-type-2-big-bg.png')} />

              {/*模特图*/}
              <img className="init-modal" src={require('../../assets/images/home-h5/product-type-2-init-modal.png')} />
              <img className="new-modal" src={require('../../assets/images/home-h5/product-type-2-new-modal.png')} />
              <span className="marker-line-box">
                <img className="marker-line" src={require('../../assets/images/home-h5/product-type-line-2.svg')} />
              </span>

              {/*文案*/}
              <span id="big_info_box_2" className="big-info-box">
                <div className="big-info-top-box">
                  <div id="big_info_title_1" className="title">
                    人台变真人
                    <span id="big_info_top_line_2" className="title-line"></span>
                  </div>
                  <div id="big_info_desc_2" className="desc">假人模特秒变真人仅需将服装穿上人台模特，即可生成超写实模特展示图可与真人模特媲美</div>
                </div>
              </span>

              {/*按钮*/}
              <span id="big_info_btn_2" className="go-product-btn" onClick={() => this.onProductClick()}>去生成<img className="right-icon" src={require('../../assets/images/home-h5/icon-right.svg')} /></span>
            </div>
          </div>
        </div>

        {/*步骤*/}
        <div className="step-container">
          <div className="step-title">
            <span id="step_title_line" className="title-line step-title-line"></span>
            <span id="step_title_txt" className="step-title-txt">轻松4步，生成模特展示图</span>
          </div>
          <div id="step_body" className="step-body">
            <span className="step-box">
              <span id="step_item_box_old" className="step-item-box step-item-box-1">
                <div className="step-item-top">
                  <span className="step-name">{currentStepItemOld?.name}</span>
                  <span id="step_num" className="step-num">0{currentStepItemOld?.step}</span>
                </div>
                <div className="step-en">{currentStepItemOld?.enName}</div>
                <div className="step-item-box-line"></div>
              </span>
              <span id="step_item_box_new" className="step-item-box step-item-box-2">
                <div className="step-item-top">
                  <span className="step-name">{currentStepItem?.name}</span>
                  <span id="step_num" className="step-num">0{currentStepItem?.step}</span>
                </div>
                <div className="step-en">{currentStepItem?.enName}</div>
                <div id="step_item_box_line" className="step-item-box-line"></div>
              </span>
            </span>
            <span className="step-movie-box">
              <span id="step_movie_item_box" className="step-movie-item-box">
                {
                  STEP_LIST.map((item, index) => <video
                    id={`step_video_${item.step}`}
                    className="step-video"
                    width="100%"
                    height="100%"
                    autoPlay={item.step === currentStepItem?.step ? "autoplay" : false}
                    muted
                    // controls="false"
                    // controls="true"
                    // controlsList='nofullscreen nodownload noremote footbar'

                    x5-video-player-type="h5"
                    preload="Metadata"
                    playsinline="true"
                    webkit-playsinline="true"
                    x-webkit-airplay="true"
                    x5-video-orientation="portraint"
                    x5-video-player-fullscreen="true"
                    controls360="no"  // 关闭360内置播放器,防止出现视频只显示一半另一半黑屏的bug
                    preload="none" // 页面加载后不加载视频,设置后可显示poster,猜测360手机浏览器在做适配时设置的默认参数不是none

                    onTouchStart={() => this.onDown(1)}
                    onTouchEnd={() => this.onUp(1)}
                    onTouchMove={(e) => this.onMove(e)}
                  >
                    <source src={currentStepItem?.video} type="video/mp4"/>
                    您的浏览器不支持播放视频文件
                  </video>)
                }
                {/*<div className="step-movie-item-box-mask"></div>*/}
              </span>
              <span className="step-tag-item-box">
              {
                STEP_LIST.map((item, index) => <div id={`step_tag_${item.step}`} className="step-tag">{currentStepItem?.enName}</div>)
              }
              </span>
            </span>
            <div className="step-tag-box"></div>
            <div className="step-page-box page-box">
              {
                (STEP_LIST || []).map((item, index) => <span className={`page-item ${item.step === currentStepItem?.step && "page-item-selected"}`}>

                </span>)
              }
            </div>
          </div>
        </div>

        {/*优势*/}
        <div className="advantage-container">
          <video
            id="advantage_bg"
            className="advantage-bg"
            width="604"
            height="1044"
            autoPlay="autoplay"
            loop={true}
            muted
            // controls="false"

            x5-video-player-type="h5"
            preload="Metadata"
            playsinline="true"
            webkit-playsinline="true"
            x-webkit-airplay="true"
            x5-video-orientation="portraint"
            controls360="no"  // 关闭360内置播放器,防止出现视频只显示一半另一半黑屏的bug
            preload="none" // 页面加载后不加载视频,设置后可显示poster,猜测360手机浏览器在做适配时设置的默认参数不是none
          >
            <source src={require('../../assets/images/home-h5/advantage-bg.mp4')} type="video/mp4"/>
            您的浏览器不支持播放视频文件
          </video>
          <div className="advantage-title">
            <span id="advantage_title_line" className="title-line advantage-title-line"></span>
            <span id="advantage_title_txt" className="advantage-title-txt">AI 技术赋予我们领先优势</span>
          </div>
          <div className="advantage-body">
            <div
              id="advantage_item_box"
              className="advantage-item-box"
              onTouchStart={() => this.onDown(2)}
              onTouchEnd={() => this.onUp(2)}
              onTouchMove={(e) => this.onMove(e)}
            >
              {/*<img className="advantage-img" src={currentAdvantage.img} />*/}
              {
                ADVANTAGE_LIST.map((item, index) => <span id={`advantage_item_${item.id}`} className={`advantage-item advantage-item-${item.id}`}>
                  <img id={`advantage_img_${item.id}`} className="advantage-img" src={item.img} />
                </span>)
              }
            </div>

            <div id="advantage_selected_desc_box" className="advantage-selected-desc-box">
            {
              ADVANTAGE_LIST.map((item, index) => <span id={`advantage_selected_desc_${item.id}`} className={`advantage-selected-desc advantage-selected-desc-${item.id}`}>
                <div id={`advantage_desc_title_${item.id}`} className="advantage-desc-title">{currentAdvantage?.name}</div>
                <div id={`advantage_desc_info_${item.id}`}  className="advantage-desc-info">{currentAdvantage?.desc}</div>
              </span>)
            }
            </div>


            <div id="advantage_page_box" className="advantage-page-box page-box">
              {
                ADVANTAGE_LIST.map((item, index) => <span className={`page-item ${item.id === currentAdvantage?.id && "page-item-selected"}`}>

                </span>)
              }
            </div>
          </div>
        </div>
      </div>
      <TryProductH5 isOpen={isTryOpen} onClose={this.onTryClose}/>
    </div>
  }
};

// 将action注入到页面组件
const matDispatchToProps = {

};

// 将store注入到页面组件
function mapStateToProps(state) {
  return {
  }
}
const HomePage = Form.create({name: 'home_page_h5'})(HomeH5);
export default withRouter(connect(mapStateToProps, matDispatchToProps)(HomePage));
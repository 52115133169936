import React, {useState, useEffect} from "react";
import {useSelector,useDispatch} from 'react-redux';
import {Link, withRouter} from "react-router-dom";
import './index.less';
import API from "../../apis";
import {getMemberInfo, showToast} from "../../store/actions/ai";
import {message} from "antd";
import { useHistory } from 'react-router-dom';
import ModalCloseBtn from "../modal-close-btn";

const LoginRegister = ({type, isOpen, memberInfo, onClose}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  // let codeInterval = null;
  // 弹窗类型 登录：login；注册：register；重置密码：reset；注册成功：regSuccess
  const [openType, setOpenType] = useState(null);
  const [showPwd, setShowPwd] = useState(false);
  const [loginForm, setLoginForm] = useState({mobile: '', password: ''});
  const [registerForm, setRegisterForm] = useState({mobile: '', password: '', code: ''});
  const [resetForm, setResetForm] = useState({mobile: '', password: '', code: ''});
  const [registerInfo, setRegisterInfo] = useState(null);
  const [regCheck, setRegCheck] = useState(false);
  const [codeCountdown, setCodeCountdown] = useState(60);
  const [codeInterval, setCodeInterval] = useState(null);

  useEffect(() => {
    setOpenType(type)
  },[]);

  useEffect(() => {
    if (openType === 'register') {
      getAccess();
    }
  },[openType]);

  useEffect(() => {
    if (memberInfo) {
      setResetForm({
        ...resetForm,
        mobile: memberInfo.mobile
      })
    }
  },[memberInfo])

  useEffect(() => {
    if (codeCountdown <= 0 && codeInterval) {
      clearInterval(codeInterval);
      setCodeInterval(null);
      setCodeCountdown(60);
      return;
    }
  }, [codeCountdown]);

  // 记录页面访问接口
  const getAccess = async () => {
    const params = {
      page: 1 // 注册页
    };
    const res = await API.access(params);
    if (res.code === 0) {

    }
  }

  // login
  const login = async () => {
    const params = {...loginForm};
    const res = await API.login(params);
    if (res.code === 0) {
      window.localStorage.setItem('token', res.data.token);
      dispatch(getMemberInfo());
      // message.success('登录成功');
      try {
        dispatch(showToast({type: 'success', msg: '登录成功'}));
      } catch (e) {
        console.log('toast error: ', e);
      }

      onCloseClick();
    }
  }

  // 注册
  const register = async () => {
    const params = {
      ...registerForm,
      conversion_url: localStorage.conversion_url || '',
      conversion_code: localStorage.conversion_code || '',
      conversion_type: localStorage.conversion_type || '',
    };
    const res = await API.register(params);
    if (res.code === 0) {
      window.localStorage.setItem('token', res.data.token);
      setRegisterInfo(res.data);
      dispatch(getMemberInfo());
      // message.success('注册成功');
      setOpenType('regSuccess');
      // onCloseClick();
    }
  }

  // 重置密码
  const resetPassword = async () => {
    const params = {...resetForm};
    const res = await API.resetPassword(params);
    if (res.code === 0) {
      // window.localStorage.setItem('token', res.data.token);
      dispatch(getMemberInfo());
      // message.success('重置成功');
      dispatch(showToast({type: 'success', msg: '重置成功'}));
      onCloseClick();
    }
  }

  // 获取验证码
  const getSms = async () => {
    const params = {
      // 类型，1注册验证码，2重置密码验证码
      type: openType === 'register' ? 1 : 2,
      mobile: openType === 'register' ? registerForm.mobile : resetForm.mobile
    }
    const res = await API.getSms(params);
    if (res.code === 0) {
      const interval = setInterval(() => {
        setCodeCountdown((codeCountdown) => {
          return codeCountdown - 1;
        })
      }, 1000);
      setCodeInterval(interval);
    }
  }

  const onCloseClick = () => {
    onClose()
  };

  const onTypeChange = (openType) => {
    setOpenType(openType);
  }

  // 输入框数值变化
  const handleValueChange = (param, value) => {
    let form = null;
    let setForm = null;
    if (openType === 'login') {
      form = loginForm;
      setForm = setLoginForm;
    } else if (openType === 'register') {
      form = registerForm;
      setForm = setRegisterForm;
    } else if (openType === 'reset') {
      form = resetForm;
      setForm = setResetForm;
    }

    if (form && setForm) {
      setForm({
        ...form,
        [param]: value
      });
    }
  }

  const onLoginClick = () => {
    login();
  }

  const onRegisterClick = () => {
    if (!regCheck) {
      // message.warn('请先阅读并同意《服务条款》和《隐私条款》');
      dispatch(showToast({type: 'warn', msg: '请先阅读并同意《服务条款》和《隐私条款》'}));
      return;
    }
    register();
  }

  const onResetClick = () => {
    resetPassword();
  }

  // 获取验证码
  const getCode = () => {
    getSms();
  }

  // 开始尝试
  const onTryBtn = () => {
    // history.push('/aimodel');
    window.open("/aimodel");
    onCloseClick();
  }

  // 协议勾选框点击
  const onRegCheckClick = () => {
    setRegCheck(!regCheck)
  }

  const onServiceClick = () => {
    window.open('/service')
  }

  const onPrivacyClick = () => {
    window.open('/privacy')
  }

  return (<div className={`login-register-component ${isOpen ? "show" : "hide"}`}>
    {/*登录*/}
    <div className={`login-container ${openType === 'login' ? "show-login" : "hide-login"} `}>
      <ModalCloseBtn className="close-btn" onClose={onCloseClick} />
      <span className="left-box"></span>
      <span className="right-box">
        <div className="title-box">
          <img className="logo-icon" src={require('../../assets/images/login/logo.svg')} />
          <span className="title-name">密码登录</span>
        </div>
        <div className="input-box id-box">
          <input placeholder="请输入手机号码"
            value={loginForm.mobile}
            onChange={(e) => handleValueChange('mobile', e.target.value)}/>
        </div>
        <div className="input-box pwd-box">
          <input
            placeholder="请输入8-20位含数字和字母的密码"
            type={showPwd ? 'text' : 'password'}
            maxLength={20}
            value={loginForm.password}
            onChange={(e) => handleValueChange('password', e.target.value)}
          />
          {
            showPwd ? <img className="eye" src={require('../../assets/images/login/eye-open.svg')}
                           onClick={() => setShowPwd(false)}/>
              : <img className="eye" src={require('../../assets/images/login/eye-close.svg')}
                     onClick={() => setShowPwd(true)}/>
          }
        </div>
        <div className="forget-box" onClick={() => {onTypeChange('reset')}}>忘记密码</div>
        <div className="login-btn" onClick={onLoginClick}>登录</div>
        <div className="other-box">
          没有账号？<span className="go-register" onClick={() => {onTypeChange('register')}}>去注册</span>
        </div>
      </span>
    </div>

    {/*注册*/}
    <div className={`register-container ${openType === 'register' ? "show-register" : "hide-register"} `}>
      <ModalCloseBtn className="close-btn" onClose={onCloseClick} />
      <span className="left-box"></span>
      <span className="right-box">
        <div className="title-box">
          <img className="logo-icon" src={require('../../assets/images/login/logo.svg')} />
          <span className="title-name">注册</span>
        </div>
        <div className="input-box id-box">
          <input
            placeholder="请输入手机号码"
            value={registerForm.mobile}
            onChange={(e) => handleValueChange('mobile', e.target.value)}/>
        </div>
        <div className="input-box code-box">
          <input
            placeholder="请输入验证码"
            maxLength={6}
            value={registerForm.code}
            onChange={(e) => handleValueChange('code', e.target.value)}
          />
          <span className="code-btn" onClick={getCode}>{codeCountdown < 60 ? `${codeCountdown}秒` : '获取验证码'}</span>
        </div>
        <div className="input-box pwd-box">
          <input
            placeholder="请输入8-20位含数字和字母的密码"
            type={showPwd ? 'text' : 'password'}
            maxLength={20}
            value={registerForm.password}
            onChange={(e) => handleValueChange('password', e.target.value)}
          />
          {
            showPwd ? <img className="eye" src={require('../../assets/images/login/eye-open.svg')} onClick={() => setShowPwd(false)}/>
              : <img className="eye" src={require('../../assets/images/login/eye-close.svg')} onClick={() => setShowPwd(true)}/>
          }
        </div>
        <div className="privacy-box">
          {
            regCheck
              ? <span className="check-box selected" onClick={onRegCheckClick}></span>
              : <span className="check-box normal" onClick={onRegCheckClick}></span>
          }
          <span style={{cursor: "pointer"}} onClick={onRegCheckClick}>我已阅读并同意</span><span className="privacy" onClick={onServiceClick}>《服务条款》</span><span style={{cursor: "pointer"}} onClick={onRegCheckClick}>和</span><span className="privacy" onClick={onPrivacyClick}>《隐私条款》</span>
        </div>
        <div className="reg-btn" onClick={onRegisterClick}>开启免费体验</div>
        <div className="other-box">
          没有账号？<span className="go-register" onClick={() => {onTypeChange('login')}}>去登录</span>
        </div>
      </span>
    </div>

    {/*重置密码*/}
    <div className={`reset-container ${openType === 'reset' ? "show-reset" : "hide-reset"} `}>
      <ModalCloseBtn className="close-btn" onClose={onCloseClick} />
      <span className="left-box"></span>
      <span className="right-box">
        <div className="title-box">
          <img className="logo-icon" src={require('../../assets/images/login/logo.svg')} />
          <span className="title-name">重置密码</span>
        </div>
        <div className="input-box id-box">
          <input
            style={{color: `${memberInfo ? 'rgba(0, 0, 0, 0.5)' : '#000000'}`}}
            placeholder="请输入手机号码"
            value={resetForm.mobile}
            disabled={memberInfo}
            onChange={(e) => handleValueChange('mobile', e.target.value)}/>
        </div>
        <div className="input-box code-box">
          <input
            placeholder="请输入验证码"
            maxLength={6}
            value={resetForm.code}
            onChange={(e) => handleValueChange('code', e.target.value)}
          />
          <span className="code-btn" onClick={getCode}>{codeCountdown < 60 ? `${codeCountdown}秒` : '获取验证码'}</span>
        </div>
        <div className="input-box pwd-box">
          <input
            placeholder="请输入8-20位含数字和字母的密码"
            type={showPwd ? 'text' : 'password'}
            maxLength={20}
            value={resetForm.password}
            onChange={(e) => handleValueChange('password', e.target.value)}
          />
          {
            showPwd ? <img className="eye" src={require('../../assets/images/login/eye-open.svg')}
                           onClick={() => setShowPwd(false)}/>
              : <img className="eye" src={require('../../assets/images/login/eye-close.svg')}
                     onClick={() => setShowPwd(true)}/>
          }
        </div>
        <div className="reset-btn" onClick={onResetClick}>提交</div>
        {
          !memberInfo && <div className="go-login-btn" onClick={() => onTypeChange('login')}>返回至登录</div>
        }
      </span>
    </div>

    {/*注册成功*/}
    <div className={`register-success-container ${openType === 'regSuccess' ? "show" : "hide"} `}>
      <ModalCloseBtn className="close-btn" onClose={onCloseClick} />
      <div className="money-box">
        <img className="money-icon" src={require('../../assets/images/common/icon-money.svg')} />
        <span>{registerInfo?.give_coin}</span>
      </div>
      <div className="success-txt">注册成功</div>
      <div className="desc">恭喜你获得 {registerInfo?.give_coin} M豆，快去体验吧</div>
      <div className="try-btn" onClick={onTryBtn}>开始尝试</div>
    </div>
    {/*<div className={`register-success-flower ${openType === 'regSuccess' ? "show" : "hide"}`}></div>*/}
  </div>)
};

export default withRouter(LoginRegister);

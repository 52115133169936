import React, {useState} from "react";
import {withRouter} from "react-router-dom";
import {useDispatch} from 'react-redux';
import './index.less';
import ModalCloseBtn from "../modal-close-btn";
import {CopyToClipboard} from "react-copy-to-clipboard"
import {showToast} from "../../store/actions/ai";

const TryProductH5 = ({isOpen, onClose}) => {
  const dispatch = useDispatch();
  // const [open, setOpen] = useState(false);

  const onCloseClick = () => {
    onClose()
  };

  const onCopy = () => {
    dispatch(showToast({type: 'success', msg: '复制成功'}));
  }

  return (<div className={`try-product-component-h5 ${isOpen ? "show" : "hide"}`}>
    {
      isOpen && <div className="try-product-container">
        <ModalCloseBtn className="close-btn" onClose={onCloseClick} />
        <div className="try-product-body">
          <div className="title">
            <img className="logo" src={require('../../assets/images/try-product-h5/icon-logo.svg')}/>
          </div>
          <div className="desc">尊敬的MixModel用户，为了给您提供更好的使用体验，请您使用电脑浏览器访问：</div>
          <div className="email-box">
            https://mixmodel.cn
          </div>
          <div className="btn-box">
            <CopyToClipboard text='https://mixmodel.cn' onCopy={onCopy}>
              <span className="copy-btn" >复制链接</span>
            </CopyToClipboard>
          </div>
        </div>
      </div>
    }
  </div>)
};

export default withRouter(TryProductH5);

import React, {useState, useEffect} from "react";
import {useSelector,useDispatch} from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {withRouter} from "react-router-dom";


import './index.less';
import API from "../../../apis";
import { Pagination } from 'antd';
import {getMemberInfo} from "../../../store/actions/ai";

/**
 * 用户中心-消息中心
 */
const UserCenterMessage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const PAGE_SIZE = 20;

  // 数据列表
  const [dataList, setDataList] = useState(null);
  // 当前第几页
  const [page, setPage] = useState(1);
  // 总页数
  const [totalSize, setTotalSize] = useState(0);

  // 用户信息
  const { memberInfo } = useSelector(state => {
    return {
      memberInfo: state.getIn(['member', 'memberInfo']),
    }
  });

  useEffect(() => {
    getMessageList(1);
  }, [])

  // 获取消息列表
  const getMessageList = async (currentPage) => {
    const params = {
      page: currentPage || page,
      page_size: PAGE_SIZE
    };
    const res = await API.getMessageList(params);
    if (res.code === 0) {
      setPage(currentPage);
      setTotalSize(res.data.total);
      setDataList(res.data.list);
    }
  }

  // 消息设置已读
  const messageRead = async (messageId) => {
    const params = {
      message_id: messageId || null,
    };
    const res = await API.messageRead(params);
    if (res.code === 0) {
      getMessageList(page);
      dispatch(getMemberInfo());
    }
  }

  // 查看详情
  const onGoDetail = (data) => {
    messageRead(data.id);
    window.open(`/aimodel?task_id=${data.bind_id}`);
  }

  // 全部已读按钮点击
  const onReadBtnClick = () => {
    messageRead();
  }

  // 翻页
  const onPageChange = (current, pageSize) => {
    getMessageList(current);
  };

  return (<div className="user-center-message-page">
    <div className="top-bar">
      <span className="left-box">消息中心</span>
      <span className="right-box">
        <span className={`read-btn ${memberInfo?.message_num === 0 && "disabled"}`} onClick={onReadBtnClick}>全部已读</span>
      </span>
    </div>
    <div className="item-list-container">
      {/*空数据*/}
      {
        dataList && dataList.length===0 && <div className="empty-box">
          <img className="empty-icon" src={require('../../../assets/images/common/icon-empty.svg')}/>
          <span>暂无消息</span>
        </div>
      }
      <div className={`table-body-box ${!dataList || dataList.length===0 && "hide"}`}>
        {
          (dataList || []).map((item, index) => <div className="table-body-item" key={index}>
            <div className="item-box">
              <span className="left-box">
                <span className="original-img-box">
                  <span className="original-img" style={{backgroundImage: `url(${item.image})`}}/>
                </span>
                <span className="info-box">
                  <div className={`status-box ${item.status === 0 && "unread"}`}>
                    {
                      item.status === 0 && <span className="dot"></span>
                    }
                    {item.title}
                  </div>
                  <div className="desc-box">
                    {item.message}，
                    {
                      item.bind_type === 1 && <span className="detail-btn" onClick={() => onGoDetail(item)}>点击去下载 </span>
                    }
                    {
                      item.bind_type === 2 && <span className="detail-btn" onClick={() => onGoDetail(item)}>查看详情 </span>
                    }
                  </div>
                </span>
              </span>
              <span className="right-box">{item.create_time}</span>
            </div>
          </div>)
        }
      </div>
      {
        totalSize > PAGE_SIZE && <div className="table-pagination-box">
          <Pagination
            onChange={onPageChange}
            pageSize={PAGE_SIZE}
            current={page}
            total={totalSize}
          />
        </div>
      }
    </div>
  </div>)
};

export default withRouter(UserCenterMessage);

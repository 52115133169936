
export function formatNum  (num) {
     return String(num).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
}

// 加
export function floatAdd(num1, num2){
     let r1, r2, m;
     try {
          r1 = `${num1}`.split('.')[1].length;
     } catch (e) {
          r1 = 0;
     }
     try {
          r2 = `${num2}`.split('.')[1].length;
     } catch (e) {
          r2 = 0;
     }
     m = Math.pow(10, Math.max(r1, r2));
     return Math.round(num1 * m + num2 * m) / m;
}

// 减
export function floatSub(num1, num2){
     let r1, r2, m, n;
     try {
          r1 = `${num1}`.split('.')[1].length;
     } catch (e) {
          r1 = 0;
     }
     try {
          r2 = `${num2}`.split('.')[1].length;
     } catch (e) {
          r2 = 0;
     }
     m = Math.pow(10, Math.max(r1, r2));
     n = r1 >= r2 ? r1 : r2;
     return (Math.round(num1 * m - num2 * m) / m).toFixed(n);
}

// 乘
export function floatMul(num1, num2) {
     let m = 0,
         s1 = `${num1}`,
         s2 = `${num2}`;
     try {
          m += s1.split('.')[1].length;
     } catch (e) {}
     try {
          m += s2.split('.')[1].length;
     } catch (e) {}
     return (
         (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) /
         Math.pow(10, m)
     );
}


// 除
export function floatDiv(num1, num2){
     let t1, t2, r1, r2;
     try {
          t1 = `${num1}`.split('.')[1].length;
     } catch (e) {
          t1 = 0;
     }
     try {
          t2 = `${num2}`.toString().split('.')[1].length;
     } catch (e) {
          t2 = 0;
     }
     r1 = Number(`${num1}`.replace('.', ''));
     r2 = Number(`${num2}`.toString().replace('.', ''));
     return (r1 / r2) * Math.pow(10, t2 - t1);
}

export const getQuery = (search = window.location.search) => {
     const queryArray = decodeURIComponent(search).match(/[^&\?]+/g)
     if (!queryArray) return {}
     const queryString = JSON.stringify(queryArray)
     const queryJson = queryString
       .replace(/","/g, '","')
       .replace(/=/g, '":"')
       .replace(/\[/, '{')
       .replace(/]$/, '}')

     try {
          return JSON.parse(queryJson)
     } catch (err) {
          return {}
     }
}

/**
 * 定义延时函数
 * delaytime 延时时长，单位毫秒
 */
export const sleep = (delaytime = 1000) => {
     return new Promise(resolve => setTimeout(resolve, delaytime))
}
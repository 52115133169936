import React, {useState, useEffect} from "react";
import { useHistory } from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {withRouter} from "react-router-dom";

import './index.less';

const AboutUs = ({}) => {
  const dispatch = useDispatch();
  const history = useHistory();


  useEffect(() => {

  }, [])


  return (<div className="about-us-page">
    <div className="body-container">
      <div className="left-box">
        <div className="title">关于我们</div>
        <div className="desc">MixModel 是广州茉米智能科技有限公司旗下的 AI 模特产品。MixModel 使用全新的 AIGC 技术，为服装品牌和零售商家赋能，无需高成本拍摄即可轻松制作高品质模特展示图。</div>
        <div className="info-box">
          <div className="sub-title">基本信息：</div>
          <div className="info-item">• 公司名称：广州茉米智能科技有限公司</div>
          <div className="info-item">• 公司地址：广州市天河区荷光路137号103房Z1171号</div>
          <div className="info-item">• 联系电话：+86 18588112020</div>
          <div className="info-item">• 邮箱：hi@mixmodel.ai</div>
        </div>
      </div>
      <div className="right-box">
        <img className="logo" src={require('../../assets/images/logo.png')}/>
      </div>
    </div>
  </div>)
};

export default withRouter(AboutUs);
import './polyfills.js';

import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import '@csstools/normalize.css';
import './index.less';
import { Provider } from 'react-redux';
import store from './store'
import { BrowserRouter as Router, Route, Redirect, Prompt } from "react-router-dom";
import { ConfigProvider  } from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';

import HomePage from './pages/home';
import HomePageH5 from './pages/home-h5';
import Product from "./pages/product";
import Header from './pages/header';
import HeaderH5 from "./pages/header-h5";
import Footer from './pages/footer';
import FooterH5 from "./pages/footer-h5";
import UserCenter from "./pages/user-center";
import Deposit from "./pages/deposit";
import Service from "./pages/privacy/service";
import Privacy from "./pages/privacy";
import AboutUs from "./pages/about-us";
import ModelStash from "./pages/model-stash";
import ModelStashDetail from "./pages/model-stash-detail";
import Toast from "./components/toast";
import runtime from "./utils/runtime";
import AutoScrollTop from "./auto-scroll-top";
import ConfirmModal from "./components/confirm-modal";
import {getQuery} from "./utils/helpers";
import API from "./apis";
import {getMemberInfo} from "./store/actions/ai";

moment.locale('zh-cn');

// 私有路由，如果登录信息不存在，就跳转到登录页
function PrivateRoute({ component: Component, ...rest }) {
  const dispatch = useDispatch();

  return (
    <Route
      {...rest}
      render={props =>{
        // console.log('props...........', isImgMaking, props.location);
        const { pathname } = props.location;
        // if (pathname === '/deposit' && !isImgMaking) {
        //   console.log('图片生成中！！！！！！！！');
        //   // return <ConfirmModal data={{
        //   //   show: true,
        //   //   type: 1,
        //   //   title: '离开此页面',
        //   //   msg: '离开页面生成的图片可能会丢失',
        //   //   showCancelBtn: true,
        //   //   cancelBtnTxt: '取消',
        //   //   okBtnTxt: '离开',
        //   //   okFunc: () => {
        //   //     return <Component {...props} />
        //   //   }
        //   // }}/>;
        //   return;
        // }

        return <Component {...props} />
      }}
    />
  );
}

  const App = () => {

    // 推广渠道
    const checkChannel = () => {
      const href = window.location.href;
      const query = getQuery();
      // 记录渠道
      if (query && query['s']) {
        localStorage.channel = query['s'];
      }
      // 百度
      if(query && query['bd_vid']){
        localStorage.conversion_url = href;
        localStorage.conversion_code = query['bd_vid'];
        localStorage.conversion_type = 1;
      }
      // 360
      if(query && query['qhclickid']){
        localStorage.conversion_url = href;
        localStorage.conversion_code = query['qhclickid'];
        localStorage.conversion_type = 2;
      }
      // 腾讯广点通
      if (query && query['qz_gdt']) {
        localStorage.conversion_url = '';
        localStorage.conversion_code = '';
        localStorage.conversion_type = 3;
      }

      // getAccess();
    }

    // 首次访问接口
    const getAccess = async () => {
      const res = await API.access(null);
      if (res.code === 0) {

      }
    }

    return (
      <ConfigProvider autoInsertSpaceInButton={false} locale={zh_CN}>
        {
          checkChannel()
        }
        <Provider store={store}>
          <Router>
            <AutoScrollTop>
              {runtime.isMobile() ? <HeaderH5 /> : <Header />}
              {/*<Prompt when={true} message={(location) => handleConfirm(location)} />*/}
              <Route path='/' exact component={runtime.isMobile() ? HomePageH5 : HomePage} />
              <Route path='/home' exact component={runtime.isMobile() ? HomePageH5 : HomePage} />
              <Route path='/aimodel' exact component={Product} />
              <Route path='/center' exact component={UserCenter} />
              {/*<PrivateRoute path='/deposit' component={Deposit} />*/}
              <Route path='/deposit' exact component={Deposit} />
              <Route path='/service' exact component={Service} />
              <Route path='/privacy' exact component={Privacy} />
              <Route path='/about' exact component={AboutUs} />
              <Route path='/modelstash' exact component={ModelStash} />
              <Route path='/modeldetail' exact component={ModelStashDetail} />
              <Toast />
              {runtime.isMobile() ? <FooterH5 /> : <Footer />}
            </AutoScrollTop>
          </Router>
        </Provider>
      </ConfigProvider>
    );
  }

  ReactDOM.render(<App />, document.getElementById('root')
);

import React, {useState, useEffect} from "react";
import {useSelector,useDispatch} from 'react-redux';
import {Link, withRouter} from "react-router-dom";
import './index.less';

const ConfirmModal = ({data}) => {

  const CONFIRM_DATA = {
    // 是否显示
    show: false,
    // 类型：1 warn;2 error
    type: 1,
    // 标题
    title: '',
    // 描述
    msg: '',
    // 是否显示取消按钮
    showCancelBtn: false,
    // 取消按钮文案
    cancelBtnTxt: '取消',
    // 确定按钮文案
    okBtnTxt: '确定',
    // 取消按钮回调函数
    cancelFunc: null,
    // 确定按钮回调函数
    okFunc: null,
    // 复选框
    showCheckBox: false,
    // 复选框文本
    checkBoxTxt: ''
  }

  const [confirmInfo, setConfirmInfo] = useState(CONFIRM_DATA);
  const [check, setCheck] = useState(false);


  useEffect(() => {
    setConfirmInfo({
      ...CONFIRM_DATA,
      ...data
    });
  },[data])

  // 弹窗取消按钮点击
  const onConfirmCancelClick = () => {
    setConfirmInfo({
      ...confirmInfo,
      show: false
    })

    if (confirmInfo.cancelFunc) {
      confirmInfo.cancelFunc();
    }
  }

  // 确定按钮点击
  const onConfirmOkClick = () => {
    setConfirmInfo({
      ...confirmInfo,
      show: false
    })

    if (confirmInfo.okFunc) {
      if (confirmInfo.showCheckBox) {
        confirmInfo.okFunc(check);
      } else {
        confirmInfo.okFunc();
      }
    }
  }

  // 复选框
  const onCheckClick = () => {
    setCheck(!check);
  }

  return (<div className={`confirm-modal-component ${confirmInfo.show ? "show" : "hide"}`}>
    <div className="confirm-body">
      <div className="title-box">
        <img className="error-icon" src={`${confirmInfo.type===1 ? require('../../assets/images/confirm-icon-warn.svg') : require('../../assets/images/confirm-icon-error.svg') }`} />
        {confirmInfo.title}
      </div>

      <div className="msg-box">
        {confirmInfo.msg}
      </div>

      <div className="btn-box">
        <span className={`check-box-container ${confirmInfo.showCheckBox ? "show" : "hide"}`} onClick={onCheckClick}>
          {
            check
              ? <span className="check-box selected" onClick={onCheckClick}></span>
              : <span className="check-box normal" onClick={onCheckClick}></span>
          }
          {confirmInfo.checkBoxTxt}
        </span>
        <span className="right-box">
          {
            confirmInfo.showCancelBtn && <span className="cancel-btn" onClick={onConfirmCancelClick}>{confirmInfo.cancelBtnTxt}</span>
          }
          <span className="ok-btn" onClick={onConfirmOkClick}>{confirmInfo.okBtnTxt}</span>
        </span>
      </div>
    </div>
  </div>)
};

export default withRouter(ConfirmModal);

import React, {useState} from "react";
import {withRouter} from "react-router-dom";
import './index.less';
import ModalCloseBtn from "../modal-close-btn";

const ContactUs = ({isOpen, onClose}) => {

  // const [open, setOpen] = useState(false);

  const onCloseClick = () => {
    onClose()
  };

  return (<div className={`contact-us-component ${isOpen ? "show" : "hide"}`}>
    {
      isOpen && <div className="contact-us-container">
        {/*<img className="close-btn" src={require('../../assets/images/common/icon-close.svg')} onClick={onCloseClick} />*/}
        <ModalCloseBtn className="close-btn" onClose={onCloseClick} />
        <div className="contact-us-body">
          <div className="title">
            <img className="logo" src={require('../../assets/images/common/logo-color.svg')}/>
            <span className="name">联系我们</span>
          </div>
          <div className="desc">如有任何疑问，欢迎随时与我们联系</div>
          <div className="item-box">
            <img className="icon" src={require('../../assets/images/contact-us/icon-phone.svg')}/>
            +86 18588112020
          </div>
          <div className="item-box">
            <img className="icon" src={require('../../assets/images/contact-us/icon-email.svg')}/>
            <a className="email" href="mailto: hi@mixmodel.ai">hi@mixmodel.ai</a>
          </div>
        </div>
      </div>
    }
  </div>)
};

export default withRouter(ContactUs);

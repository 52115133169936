import request from '../utils/request';

export default {

  // 访问
  access: (info) => request('/api/tool/access', {
    method: 'GET',
    params: {
      ...info
    }
  }),

  // 注册
  register: (info) => request('/api/member/register', {
    method: 'POST',
    data: {
      ...info
    }
  }),

  // 登录
  login: (info) => request('/api/member/login', {
    method: 'POST',
    data: {
      ...info
    }
  }),

  // 重置密码
  resetPassword: (info) => request('/api/member/reset_password', {
    method: 'POST',
    data: {
      ...info
    }
  }),

  // 退出登录
  logout: (info) => request('/api/member/logout', {
    method: 'POST',
    data: {
      ...info
    }
  }),

  // 用户信息
  getMemberInfo: (info) => request('/api/member/info', {
    method: 'GET',
    params: {
      ...info
    }
  }),

  // 用户套餐列表
  getCoinList: (info) => request('/api/member/coin_list', {
    method: 'GET',
    params: {
      ...info
    }
  }),

  // 获取验证码短信
  getSms: (info) => request('/api/tool/sms', {
    method: 'POST',
    data: {
      ...info
    }
  }),

  // 获取预设图片列表
  getPresetImages: (info) => request('/api/tool/preset_image', {
    method: 'GET',
    params: {
      ...info
    }
  }),

  // 获取图片生成配置项目
  getBuildConfig: (info) => request('/api/tool/build_config', {
    method: 'GET',
    params: {
      ...info
    }
  }),

  // 添加图片任务（开始生成图片）
  taskBuild: (info) => request('/api/task/build', {
    method: 'POST',
    data: {
      ...info
    }
  }),

  // 任务详情
  getTaskInfo: (info) => request('/api/task/info', {
    method: 'GET',
    params: {
      ...info
    }
  }),

  // 重新生成
  taskRebuild: (info) => request('/api/task/rebuild', {
    method: 'POST',
    data: {
      ...info
    }
  }),

  // 下载图片记录
  download: (info) => request('/api/task/download', {
    method: 'POST',
    data: {
      ...info
    }
  }),

  // 套餐列表
  getCoinPackage: (info) => request('/api/order/coin_package', {
    method: 'GET',
    params: {
      ...info
    }
  }),

  // 创建订单
  createOrder: (info) => request('/api/order/create', {
    method: 'POST',
    data: {
      ...info
    }
  }),

  // 检查订单状态
  getOrderStatus: (info) => request('/api/order/status', {
    method: 'GET',
    params: {
      ...info
    }
  }),

  // 获取微信支付二维码字符串
  getPayCreate: (info) => request('/api/pay/create', {
    method: 'POST',
    data: {
      ...info
    }
  }),

  // 公共配置
  getConfig: (info) => request('/api/tool/config', {
    method: 'GET',
    params: {
      ...info
    }
  }),

  // 用户消息列表
  getMessageList: (info) => request('/api/member/message_list', {
    method: 'GET',
    params: {
      ...info
    }
  }),

  // 消息设置已读
  messageRead: (info) => request('/api/member/message_read', {
    method: 'POST',
    data: {
      ...info
    }
  }),

  // 用户图片生成记录
  getTaskHistory: (info) => request('/api/member/task_history', {
    method: 'GET',
    params: {
      ...info
    }
  }),

  // 模特库列表
  getModelList: (info) => request('/api/model_gallery/list', {
    method: 'GET',
    params: {
      ...info
    }
  }),

  // 模特库详情
  getModelDetail: (info) => request('/api/model_gallery/detail', {
    method: 'GET',
    params: {
      ...info
    }
  }),
}
import React, {useState, useEffect} from "react";
import {useSelector,useDispatch} from 'react-redux';
import {Link, withRouter} from "react-router-dom";
import './index.less';

const ImgPreviewComponent = ({data, onClose}) => {

  const IMG_DATA = {
    // 是否显示
    show: false,
    // 生成的图片Url [{image: '', type: '', tag: ''}]
    imgList: [],
    // 初始显示的图片index
    initImgIndex: 0
  }

  const [imgInfo, setImgInfo] = useState(IMG_DATA);
  const [imgIndex, setImgIndex] = useState(0);
  const [oldImgIndex, setOldImgIndex] = useState(0);

  useEffect(() => {
    setImgInfo({
      ...IMG_DATA,
      ...data
    });
    setImgIndex(data?.initImgIndex || 0);
    setOldImgIndex(data?.initImgIndex || 0);
  },[data]);

  // 弹窗取消按钮点击
  const onCancelClick = () => {
    setImgInfo({
      ...imgInfo,
      show: false
    })

    if (imgInfo.cancelFunc) {
      imgInfo.cancelFunc();
    }
  }

  const onImgPreClick = (e) => {
    //阻止事件冒泡即可
    e.stopPropagation();
    imgChangeEffect(imgIndex);
    const i = imgIndex - 1 < 0 ? imgInfo.imgList.length-1 : imgIndex - 1;
    setOldImgIndex(imgIndex);
    setImgIndex(i);
  }

  const onImgNextClick = (e) => {
    //阻止事件冒泡即可
    e.stopPropagation();
    imgChangeEffect(imgIndex);
    const i = imgIndex + 1 > imgInfo.imgList.length-1 ? 0 : imgIndex + 1;
    setOldImgIndex(imgIndex);
    setImgIndex(i);
  }

  // 切换图片效果
  const imgChangeEffect = (index) => {
    const elm1 = document.getElementById('new_img');
    const elm2 = document.getElementById('old_img');
    elm1.style.setProperty('--previewImgName', `previewImgAnim${index%2}`);
    elm2.style.setProperty('--previewOldImgName', `previewOldImgAnim${index%2}`);
  }

  const onCloseBtnClick = () => {
    if (onClose) {
      onClose();
    }
  }

  return (<div className={`img-preview-component`} onClick={onCloseBtnClick}>
    <div className="img-preview-body-container">
      <span className="close-btn" onClick={onCloseBtnClick}/>

      <div className={`img-info-box`}>
        <div className="img-box">
          <img id="new_img" className="img" src={ imgInfo.imgList[imgIndex]?.image } onClick={(e) => e.stopPropagation()}/>
          <img id="old_img" className="img old-img" src={ imgInfo.imgList[oldImgIndex]?.image } onClick={(e) => e.stopPropagation()}/>
        </div>
        <div className="img-name">{`${imgInfo.imgList[imgIndex]?.type} | ${imgInfo.imgList[imgIndex]?.tag}`}</div>
      </div>

      <div className="btn-box">
        <span className={`btn left-btn`} onClick={onImgPreClick}></span>
        <span className={`btn right-btn`} onClick={onImgNextClick}></span>
      </div>
    </div>
  </div>)
};

export default withRouter(ImgPreviewComponent);

import React, {useState, useEffect} from "react";
import {withRouter} from "react-router-dom";
import './index.less';
import API from "../../apis";
import {useSelector, useDispatch} from 'react-redux';

/**
 * AI模特库
 */
const ModelStash = ({}) => {
  document.title = 'AI 模特库 -  MixModel.cn | AI模特，服装拍摄全新生产力';

  const dispatch = useDispatch();
  // 选中的充值套餐
  // const [depositIndex, setDepositIndex] = useState(0)
  // 图片类型
  const [tab, setTab] = useState(0);
  // 模特列表
  const [list, setList] = useState([]);
  // 模特翻页记录
  const [modelIndexMap, setModelIndexMap] = useState({});
  // 是否翻页钟
  const [turning, setTurning] = useState(false);

  useEffect(() => {
    // getModelList();
  },[])

  useEffect(() => {
    getModelList();
  },[tab])

  // 模特库列表
  const getModelList = async () => {
    const params = {
      gender: tab
    }
    const res = await API.getModelList(params);
    if (res.code === 0) {
      setList(res.data.list);
    }
  }

  // tab切换
  const onTabClick = (type) => {
    if (type === tab) {
      return;
    }

    setTab(type);
  }

  // 点击模特
  const onModelClick = (modelId) => {
    window.open(`/modeldetail?id=${modelId}`);
  }

  // 上一页
  const onPagePreClick = (e, id) => {
    e.preventDefault();
    e.stopPropagation();

    if (turning) {
      return;
    }

    let index = modelIndexMap[id] ? modelIndexMap[id] - 1 : 3;
    if (index < 0) {
      index = 3;
    }
    setModelIndexMap({
      ...modelIndexMap,
      [id]: index
    });
    onPageChangeAnim(id, index, false);
  }

  // 下一页
  const onPageNextClick = (e, id) => {
    e.preventDefault();
    e.stopPropagation();

    if (turning) {
      return;
    }

    let index = modelIndexMap[id] ? modelIndexMap[id] + 1 : 1;
    if (index > 3) {
      index = 0;
    }
    setModelIndexMap({
      ...modelIndexMap,
      [id]: index
    });
    onPageChangeAnim(id, index, true);
  }

  // 翻页动效
  const onPageChangeAnim = (id, index, isNext) => {
    setTurning(true);
    const elm = document.getElementById(`model_img_list_${id}`);
    const left = elm.offsetLeft;
    const endLeft = isNext ? left - 344 : left + 344;
    elm.style.setProperty('--modelImgListAnimName', `modelImgListMoveAnim${index%2}`);
    elm.style.setProperty('--modelImgListAnimLeftStart', `${left}px`);
    elm.style.setProperty('--modelImgListAnimLeftEnd', `${endLeft}px`);

    setTimeout(() => {
      const imgElmBefore = document.getElementById(`model_img_${id}_${index===0 ? 4 : index}`);
      const imgElmCurrent = document.getElementById(`model_img_${id}_${index+1}`);
      const imgElmAfter = document.getElementById(`model_img_${id}_${index===3 ? 1 : index+2}`);

      const currentLeft = parseFloat(imgElmCurrent.style.left.replace('px', ''));
      imgElmCurrent.style.zIndex = 2;
      imgElmBefore.style.zIndex = 1;
      imgElmAfter.style.zIndex = 1;
      imgElmBefore.style.left = `${currentLeft - 344}px`;
      imgElmAfter.style.left = `${currentLeft + 344}px`;

      setTurning(false);
    }, 300)

  }

  return (<div className="model-stash-page">
    <div className={`model-stash-body`}>
      <div className="title-box">
        <span className="title">AI 模特库</span>
        <span className="title-line"></span>
      </div>

      <div className="tab-box">
        <span className={`tab-item ${tab===0 && "selected"}`} onClick={() => onTabClick(0)}>全部</span>
        <span className={`tab-item ${tab===2 && "selected"}`} onClick={() => onTabClick(2)}>女模特</span>
        <span className={`tab-item ${tab===1 && "selected"}`} onClick={() => onTabClick(1)}>男模特</span>
      </div>

      <div className="model-list-box">
        {
          (list || []).map(item => <div key={item.id} className={`model-item-box`} style={{display: item.images.length===0 ? 'none' : 'inline-block'}} onClick={() => onModelClick(item.id)}>
            {/*左右翻页按钮*/}
            <span className="left-btn" onClick={(e) => onPagePreClick(e, item.id)}/>
            <span className="right-btn" onClick={(e) => onPageNextClick(e, item.id)}/>

            <span className="model-img-box">
              <span id={`model_img_list_${item.id}`} className="model-img-list">
                <img id={`model_img_${item.id}_4`} className="model-img" style={{top: 0, left: '-344px'}} src={item?.images[3]?.thumbnail} />
                <img id={`model_img_${item.id}_1`} className="model-img" style={{top: 0, left: '0px'}}  src={item?.images[0]?.thumbnail} />
                <img id={`model_img_${item.id}_2`} className="model-img" style={{top: 0, left: '344px'}}  src={item?.images[1]?.thumbnail} />
                <img id={`model_img_${item.id}_3`} className="model-img" style={{top: 0, left: '688px'}}  src={item?.images[2]?.thumbnail} />
              </span>

              <span className="page-box">
                {
                  item.images.map((pageItem, i) => <span className={`page-item ${modelIndexMap[item.id]===i && "selected"} ${!modelIndexMap[item.id] && i===0 && "selected"}`}></span>)
                }

              </span>
            </span>

            <div className="head-img-box">
              <img className="head-img" src={item.avatar} />
              <span className="name-box">
                <span className="name">{item.name}</span>
                {
                  item.gender === 1
                    ? <img className="gender-icon" src={require('../../assets/images/model-stash/icon-female.svg')} />
                    : <img className="gender-icon" src={require('../../assets/images/model-stash/icon-male.svg')} />
                }
              </span>
            </div>
            <div className="tag-box">
              {
                (item.tags.slice(0, 3) || []).map((tag, i) => <span className="tag-item" key={`${tag}_${i}`}>#{tag}</span>)
              }
            </div>
          </div>)
        }

      </div>

    </div>
  </div>)
};

export default withRouter(ModelStash);

import React, {useState, useEffect} from "react";
import {Link, withRouter} from "react-router-dom";
import './index.less';
import {useSelector} from 'react-redux';

const ToastComponent = () => {

  const TOAST_DATA = {
    // 唯一id
    key: '',
    // 类型：warn; error; success
    type: 'warn',
    // 描述
    msg: '',
  }

  const [toastInfo, setToastInfo] = useState(TOAST_DATA);
  const [count, setCount] = useState(0);

  // toast信息
  const { toast } = useSelector(state => {
    // console.log('toast>>>>>>>>>>>>', state);
    return {
      toast: state.getIn(['toast', 'toastInfo'])
    }
  });

  useEffect(() => {
    if (toast) {
      setToastInfo({...TOAST_DATA, ...toast});
      showToast();
    }
  }, [toast])

  const showToast = () => {
    setCount(count + 1);
    const elm = document.getElementById('toast_component');
    elm.style.setProperty('--toastAnimName', `toastAnim${count%2}`);
  }


  return (<div id="toast_component" className={`toast-component`}>
    <span className="line-box"></span>
    {
      toastInfo.type === 'warn' && <img className="status-icon" src={require('../../assets/images/common/icon-toast-warn.svg')} />
    }
    {
      toastInfo.type === 'error' && <img className="status-icon" src={require('../../assets/images/common/icon-toast-error.svg')} />
    }
    {
      toastInfo.type === 'success' && <img className="status-icon" src={require('../../assets/images/common/icon-toast-success.svg')} />
    }
    {toastInfo?.msg}
  </div>)
};

export default withRouter(ToastComponent);
